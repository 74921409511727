<template>
  <div class="h-100">
    <div v-if="false" class="swiper-logo">
      <img
        :src="logo"
        width="50px"
        height="50px"
        class="object-cover rounded-circle"
        alt="logo"
      />
    </div>

    <swiper-container ref="swiper" init="false" class="swiper-container">
      <swiper-slide
        v-for="product in products"
        :key="product._id"
        class="h-100"
      >
        <!-- 
         :style="{
            backgroundImage: `url(${product.item.images[0].url.replace(
                /\s/g,
                '%20'
              )})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }"
  
       -->
        <div
          class="swiper-slide-content preview-item"
          v-if="product.item.images.length"
        >
          <div class="layerBG"></div>

          <img
            :src="product.item.images[0].url"
            style="width: 100%; height: 100%; object-fit: cover"
          />
          <div class="wrapper-content">
            <div class="wrapper-content-banner mt-15">
              <p
                class="custom-banner"
                v-if="product.item.banner && product.item.banner.text"
              :style="{ backgroundColor: theme.banner.container.color }"
              id="banner-container"
              :key="restartAnimationKey + Math.random()"
              :class="[
                getAnimationClasses(
                  null,
                  theme.banner.container.delay,
                  'banner-container'
                ),
              ]"              >
                <v-btn class="black--text">
                  <span :style="{ color: theme.banner.text.color }"
                    id="banner-text"
                  :key="restartAnimationKey + Math.random()" :class="[
                    getAnimationClasses(
                      theme.banner.text.animation,
                      theme.banner.text.delay,
                      'banner-text'
                    ),
                  ]">{{product.item.banner.text }}</span></v-btn
                >
              </p>
            </div>

            <div
              class="wrapper-content-info px-15"
              v-if="hasSomeContent(product, false)"
            >
              <div class="slide-content">
                <div
                  class="swiper-slide-title"
                  ref="titles"
                  v-if="product.item.title"
                :style="{ color: theme.content.title.color }"
                  id="theme-title"
                  :key="restartAnimationKey + Math.random()"
                  :class="[
                    getAnimationClasses(
                      theme.content.title.animation,
                      theme.content.title.delay,
                      'theme-title'
                    ),
                  ]"                  v-html="wrapString(product.item.title, 30)"
                ></div>
                <div
                  ref="descriptions"
                  class="swiper-slide-text my-2"
                  v-if="product.item.description"
                :style="{ color: theme.content.description.color }"
                  id="theme-description"
                  :key="restartAnimationKey + Math.random()"

                  :class="[
                    getAnimationClasses(
                      theme.content.description.animation,
                      theme.content.description.delay,
                      'theme-description'
                    ),
                  ]"

                  v-html="wrapString(product.item.description, 50)"
                ></div>
              </div>
              <div
                class="prices black--text d-flex flex-column justify-space-around my-4 align-around"
                v-if="product.item.price || product.item.oldPrice"
              :style="{
                backgroundColor: theme.prices.container.color,
              }"
              >
                <span ref="prices" class="price ma-0" v-if="product.item.price"
                :style="{
                  color: theme.prices.price.color,
                }"
                id="price"
                :key="restartAnimationKey + Math.random()"
                :class="[
                  getAnimationClasses(
                    theme.prices.price.animation,
                    theme.prices.price.delay,
                    'price'
                  ),
                ]"
>
                  {{ product.item.price }}
                  <span ref="currency" class="currency">
                    {{ currency }}
                  </span>
                </span>
                <span
                  class="discount text-center"
                  v-if="product.item.oldPrice"
                :style="{ color: theme.prices.oldPrice.color }"
                id="old-price"
                :key="restartAnimationKey + Math.random()"
                :class="[
                  getAnimationClasses(
                    theme.prices.oldPrice.animation,
                    theme.prices.oldPrice.delay,
                    'old-price'
                  ),
                ]"

                  ref="discounds"
                >
                  {{ product.item.oldPrice }}
                  <span class="currency" ref="discount_currency">
                    {{ currency }}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper-container>
  </div>
</template>

<script>
export default {
  name: "Center Slide",
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    currency: {
      type: String,
      default: "",
      required: false,
    },
    logo: {
      type: String,
      default: "",
      required: false,
    },
    currentGroup: {
      type: Object,
      default: Object,
      required: false,
    },
        themeConfiguration: {
      type: Object,
      default: () => ({}),
    },
    theme: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    isLiveChangesPreview: {
      type: Boolean,
      default: false,
    },
    animationEffects: {
      type: Array,
      required: true,
    }
  },

  computed: {
    groupBgColor() {
      return this.currentGroup.groupId?.bg_color;
    },
    productsTimes() {
      return this.products.map((product) => Number(product.item.counter ?? 1));
    },
  },
  updated() {
    this.$emit("initializeSwiper", this.$refs.swiper, this.swiperParams);
  },
  mounted() {
    this.$emit("initializeSwiper", this.$refs.swiper, this.swiperParams);
  },
  methods: {
    handleNextGroup() {
      this.activeIndex = 1;
      setInterval(() => {
        if (this.activeIndex == this.products.length + 1) {
          this.activeIndex = 0;
          this.$emit("goNext");
        } else {
          this.activeIndex += 1;
        }
      }, this.totalTime);
    },
    calculateFont() {
      this.endCalculateFont = false;
      this.$store.commit("showLogoLayer", true);

      setTimeout(() => {
        if (this.$refs.titles.length > 1) {
          this.reSizeFont({
            content: this.$refs.titles,
            checkIfHasSub: true,
            minFont: 15,
            maxFont: 150,
            maxHeightByPercent: 70,
          });
        }

        if (this.$refs.descriptions.length > 1) {
          this.reSizeFont({
            content: this.$refs.descriptions,
            checkIfHasSub: true,
            minFont: 10,
            maxFont: 100,
            maxHeightByPercent: 30,
          });
        }

        if (this.$refs.prices.length > 1) {
          this.reSizeFont({
            content: this.$refs.prices,
            checkIfHasSub: true,
            minFont: 10,
            maxFont: 80,
            maxHeightByPercent: 70,
          });
        }

        if (this.$refs.discounds.length > 1) {
          this.reSizeFont({
            content: this.$refs.discounds,
            checkIfHasSub: true,
            minFont: 15,
            maxFont: 100,
            maxHeightByPercent: 30,
          });
        }

        if (this.$refs.currency.length > 1) {
          this.reSizeFont({
            content: this.$refs.currency,
            checkIfHasSub: false,
            minFont: 15,
            maxFont: 100,
            maxHeightByPercent: 30,
          });
        }

        if (this.$refs.discount_currency.length > 1) {
          this.reSizeFont({
            content: this.$refs.discount_currency,
            checkIfHasSub: false,
            minFont: 15,
            maxFont: 100,
            maxHeightByPercent: 20,
          });
        }
        if (this.$refs.banner.length > 1) {
          this.reSizeFont({
            content: this.$refs.banner,
            minFont: 10,
            maxFont: 100,
            maxHeightByPercent: 30,
          });
        }
        this.endCalculateFont = true;
        this.$store.commit("showLogoLayer", false);
      }, 100);
    },
    getAnimationClasses(animationName, animationDelay, id) {
      if (!animationName) {
        return "";
      }

      const animationDelayClass = animationDelay
        ? `animate__delay-${animationDelay}s`
        : "";

      const animationClass = this.animationEffects.find(
        (effect) => effect.name === animationName
      )?.class;

      this.$nextTick(() => {
        if (id) {
          let el = document.querySelector(`#${id}`);

          setTimeout(() => {
            el.classList.remove("opacity-0");
          }, Number(animationDelay) * 1000 - 100);
        }
      });

      return `animate__animated ${animationClass} ${animationDelayClass} opacity-0`;
    },
    initSwiper() {
      const swiperEl = this.$refs.swiper;
      // const swiperParams = {
      //   speed: this.totalTime / this.products.length,
      //   pagination: true,
      //   parallax: { enabled: true },
      //   navigation: true,
      //   autoplay: {
      //     delay: this.totalTime,
      //     disableOnInteraction: false,
      //     enabled: true,
      //   },
      //   spaceBetween: 10,
      // };

      const swiperParams = this.themeConfiguration.swiperParams({
        speed: this.totalTime / this.products.length,
        delay: this.calcTime(this.activeIndex, this.productsTimes, 1),
        handleNextGroup: this.handleNextGroup,
      });

      // now we need to assign all parameters to Swiper element
      Object.assign(swiperEl, swiperParams);

      this.$nextTick(async () => {
        await swiperEl.initialize();

        // stop slider autoplay when user switch to another tab or window
        document.addEventListener("visibilitychange", () => {
          if (document.visibilityState === "hidden") {
            this.$refs.swiper && this.$refs.swiper.swiper.autoplay.stop();
          } else {
            this.$refs.swiper && this.$refs.swiper.swiper.autoplay.start();
          }
        });
      });
    },
    hasSomeContent(product, checKBannerAlso) {
      if (checKBannerAlso) {
        return Boolean(
          product.item.banner || product.item.title || product.item.description
        );
      } else {
        return Boolean(
          product.item.title ||
            product.item.description ||
            product.item.price ||
            product.item.oldPrice
        );
      }
    },
  },
  data() {
    return {
      restartAnimationKey: 0,
      activeIndex: 0,
      totalTime: 4000,
      endCalculateFont: false,
      slidesPerView: 3,
    };
  },
};
</script>
<style scoped lang="scss">
swiper-container {
  user-select: none;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;

  height: 100%;

  padding-top: 0px;
  padding-bottom: 0px;
}

swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #fff;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;

  border-radius: 0px;
}

.swiper-slide-content {
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: space-between;
  text-align: start;
  color: #000;
}
.wrapper-content {
  // background-color: red;
  position: absolute;
  right: 0;
  height: 100%;
  width: 100%;

  box-shadow: none !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: space-between;
}

.wrapper-content-banner {
  // background: green;
  width: 100%;
  text-align: center;
}
.wrapper-content-info {
  // background: rgba(255, 255, 255, 0.94);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: end;

  width: 100%;
  align-items: center;
}
.slide-content {
  //background-color: yellow;
  display: flex;
  width: 100%;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  //box-shadow: 3px 4px 10px 2px #00000040;
  //background-color: yellow;
}
.prices {
  //  background-color: darkblue;
  align-items: center;
}
.swiper-slide-title {
  letter-spacing: 0.03em;
  text-align: center;
  color: #fff;
}

.swiper-slide-text {
  // height: calc(30% + 20px);
  color: #fff;
}
.price {
  font-size: 2.5rem;
  font-weight: bold;
  color: #fff;
}

.discount {
  font-size: 1.5rem;
  width: max-content;
  transform: translate(107%, -106%);
  margin-left: auto;
  color: #fff;
}

.custom-banner {
  min-width: 100%;
  border-radius: 15px;

  line-height: 19px;
  font-weight: bold;
}
.custom-banner button {
  background-color: black;
  color: white;
}
.swiper-logo {
  position: absolute;
  top: 50px;
  right: 10px;
  z-index: 100;
}
.removeBgColor {
  background-color: transparent;
}
.layerBG {
  background: linear-gradient(
    129.04deg,
    rgba(0, 0, 0, 0.8) 19.29%,
    rgba(217, 217, 217, 0) 41.37%
  );
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
