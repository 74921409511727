<template>
  <div
    class="h-100"
    :style="{ backgroundColor: theme.container.color || '#FFFFFF' }"
  >
    <div v-if="false" class="swiper-logo">
      <img
        :src="logo"
        width="50px"
        height="50px"
        class="object-cover rounded-circle"
        alt="logo"
      />
    </div>
    <swiper-container init="false" ref="swiper" class="swiper-container">
      <swiper-slide
        v-for="product in products"
        :key="product._id"
        class="h-100"
      >
        <p
          class="banner"
          v-if="product.item.banner && product.item.banner.text"
              :style="{ backgroundColor: theme.banner.container.color }"
              id="banner-container"
              :key="restartAnimationKey + Math.random()"
              :class="[
                getAnimationClasses(
                  null,
                  theme.banner.container.delay,
                  'banner-container'
                ),
              ]"        
>
          <span :style="{ color: theme.banner.text.color }"
                    id="banner-text"
                  :key="restartAnimationKey + Math.random()" :class="[
                    getAnimationClasses(
                      theme.banner.text.animation,
                      theme.banner.text.delay,
                      'banner-text'
                    ),
                  ]">{{product.item.banner.text }}</span>
        </p>
        <div class="swiper-slide-content preview-item">
          <img
            :src="product.item.images[0].url"
            class="swiper-slide-image"
            :style="{
              width:
                !product.item.title &&
                !product.item.description &&
                !product.item.price &&
                !product.item.oldPrice
                  ? '100%'
                  : '',
            }"
          />
          <div
            class="wrapper-content"
            v-if="
              product.item.title ||
              product.item.description ||
              product.item.price ||
              product.item.oldPrice
            "
          >
            <div :style="{ backgroundColor: theme.content.container.color }" class="card-info theme-content">
              <div
                class="swiper-slide-title"
                v-if="product.item.title"
                :style="{ color: theme.content.title.color }"
                  id="theme-title"
                  :key="restartAnimationKey + Math.random()"
                  :class="[
                    getAnimationClasses(
                      theme.content.title.animation,
                      theme.content.title.delay,
                      'theme-title'
                    ),
                  ]"
                v-textFit="{
                  minFont: 15,
                  maxFont: 512,
                  maxHeightByPercent: 70,
                  checkHasSibling: true,
                }"
              >
                {{ product.item.title }}
              </div>
              <div
                class="swiper-slide-text"
                v-textFit="{
                  minFont: 15,
                  maxFont: 512,
                  maxHeightByPercent: 30,
                }"
                v-if="product.item.description"
                :style="{ color: theme.content.description.color }"
                  id="theme-description"
                  :key="restartAnimationKey + Math.random()"

                  :class="[
                    getAnimationClasses(
                      theme.content.description.animation,
                      theme.content.description.delay,
                      'theme-description'
                    ),
                  ]"

                v-html="wrapString(product.item.description, 50)"
              ></div>
            </div>
            <div
              class="prices black--text d-flex flex-column w-100"
              v-if="product.item.price || product.item.oldPrice"
              :style="{
                backgroundColor: theme.prices.container.color,
              }"
            >
              <span
                class="price"
                v-if="product.item.price"
                :style="{
                  color: theme.prices.price.color,
                }"
                id="price"
                :key="restartAnimationKey + Math.random()"
                :class="[
                  getAnimationClasses(
                    theme.prices.price.animation,
                    theme.prices.price.delay,
                    'price'
                  ),
                ]"

                v-textFit="{
                  minFont: 15,
                  maxFont: 512,
                  maxHeightByPercent: 70,
                  quarterChild: true,
                }"
              >
                {{ product.item.price }}
                <span class="currency">
                  {{ currency }}
                </span>
              </span>
              <span
                class="discount"
                v-if="product.item.oldPrice"
                :style="{ color: theme.prices.oldPrice.color }"
                id="old-price"
                :key="restartAnimationKey + Math.random()"
                :class="[
                  getAnimationClasses(
                    theme.prices.oldPrice.animation,
                    theme.prices.oldPrice.delay,
                    'old-price'
                  ),
                ]"

                v-textFit="{
                  minFont: 15,
                  maxFont: 512,
                  maxHeightByPercent: 30,
                  quarterChild: true,
                }"
              >
                {{ product.item.oldPrice }}
                <span class="currency">
                  {{ currency }}
                </span>
              </span>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper-container>

    <div class="swiper-pagination"></div>
  </div>
</template>

<script>
export default {
  name: "ThreeSlidesGroup",
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    currency: {
      type: String,
      default: "",
      required: false,
    },
    logo: {
      type: String,
      default: "",
      required: false,
    },
    currentGroup: {
      type: Object,
      default: Object,
      required: false,
    },
        themeConfiguration: {
      type: Object,
      default: () => ({}),
    },
    theme: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    isLiveChangesPreview: {
      type: Boolean,
      default: false,
    },
    animationEffects: {
      type: Array,
      required: true,
    }
  },

  computed: {
    groupBgColor() {
      return this.currentGroup.groupId?.bg_color;
    },
    productsTimes() {
      return this.products.map((product) => Number(product.item.counter ?? 1));
    },
  },
  mounted() {
    // initialize swiper
    this.initSwiper();

    // when a slide contains products with a length that fits within a single slide, wait for the calculated time then go the next group
    // when the user switch to another tab or window, don't trigger the next group and wait until the user come back
    if (this.products.length <= this.slidesPerView) {
      let handleNextGroupTimeout = setTimeout(() => {
        this.handleNextGroup();
      }, this.calcTime(this.activeIndex, this.productsTimes, 3));

      document.addEventListener("visibilitychange", () => {
        if (document.visibilityState === "hidden") {
          clearTimeout(handleNextGroupTimeout);
        } else if (document.visibilityState === "visible") {
          handleNextGroupTimeout = setTimeout(() => {
            this.handleNextGroup();
          }, this.calcTime(this.activeIndex, this.productsTimes, 2));
        }
      });
    }
  },
  methods: {
    handleNextGroup() {
      this.activeIndex = 1;
      setInterval(() => {
        if (this.activeIndex == this.products.length + 1) {
          this.activeIndex = 0;
          this.$emit("goNext");
        } else {
          this.activeIndex += 1;
        }
      }, this.totalTime);
    },
    getAnimationClasses(animationName, animationDelay, id) {
      if (!animationName) {
        return "";
      }

      const animationDelayClass = animationDelay
        ? `animate__delay-${animationDelay}s`
        : "";

      const animationClass = this.animationEffects.find(
        (effect) => effect.name === animationName
      )?.class;

      this.$nextTick(() => {
        if (id) {
          let el = document.querySelector(`#${id}`);

          setTimeout(() => {
            el.classList.remove("opacity-0");
          }, Number(animationDelay) * 1000 - 100);
        }
      });

      return `animate__animated ${animationClass} ${animationDelayClass} opacity-0`;
    },
    initSwiper() {
      const swiperEl = this.$refs.swiper;
      // const swiperParams = {
      //   direction: "vertical",
      //   autoplay: {
      //     delay: this.$store.getters["getSliderGroupTotalTime"],
      //     enable: true,
      //   },
      //   pagination: {
      //     type: "progressbar",
      //     dynamicMainBullets: 3,
      //     dynamicBullets: true,
      //     el: ".swiper-pagination",
      //   },
      //   spaceBetween: 20,
      //   slidesPerView: 3,
      //   slidesPerGroup: 3,
      //   initialSlide: 0,
      //   speed:
      //     this.$store.getters["getSliderGroupTotalTime"] / this.products.length,
      //   virtual: false,
      // };

      const swiperParams = this.themeConfiguration.swiperParams({
        speed: 4000 / this.products.length,
        delay: this.calcTime(this.activeIndex, this.productsTimes, 1),
        handleNextGroup: this.handleNextGroup,
      });

      // now we need to assign all parameters to Swiper element
      Object.assign(swiperEl, swiperParams);
      this.$nextTick(() => {
        swiperEl.initialize();

        // stop slider autoplay when user switch to another tab or window
        document.addEventListener("visibilitychange", () => {
          if (document.visibilityState === "hidden") {
            this.$refs.swiper && this.$refs.swiper.swiper.autoplay.stop();
            console.log("autoplay stopped");
          } else {
            this.$refs.swiper && this.$refs.swiper.swiper.autoplay.start();
            console.log("autoplay started");
          }
        });
      });
    },
  },
  data() {
    return {
      restartAnimationKey: 0,
      activeIndex: 0,
      totalTime: 4000,
      endCalculateFont: false,
      slidesPerView: 3,
    };
  },
};
</script>

<style scoped>
swiper-container {
  user-select: none;
  box-sizing: border-box;
  overflow: hidden;
  width: 50%;

  height: 95%;

  padding-left: 0px;
  padding-right: 0px;
}

swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #fff;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  background-color: rgba(51, 51, 51, 1);
  border-radius: 6px;
}

.swiper-slide-content {
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  color: #000;
}

.wrapper-content {
  height: 100%;
  width: 30%;
  position: absolute;
  left: 0;
  background-color: rgba(255, 255, 255, 0.94);
  padding: 10px 15px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.card-info {
  height: 65%;
  width: 100%;
}

.swiper-slide-title {
  letter-spacing: 0.03em;
}
.swiper-slide-text {
  letter-spacing: 0.03em;
}

.prices {
  height: 30%;
}

.discount {
  width: max-content;
}
.swiper-logo {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 100;
}
.swiper-slide-image {
  object-fit: cover;
  width: 70%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}
</style>
