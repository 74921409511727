var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(false)?_c('div',{staticClass:"swiper-logo"},[_c('img',{staticClass:"object-cover rounded-circle",attrs:{"src":_vm.logo,"width":"50px","height":"50px","alt":"logo"}})]):_vm._e(),_c('swiper-container',{ref:"swiper",staticClass:"relative swiper-container",style:({ backgroundColor: _vm.theme.container.color || '#FFFFFF' }),attrs:{"init":"false"}},_vm._l((_vm.products),function(product){return _c('swiper-slide',{key:product._id},[_c('div',{staticClass:"swiper-slide-content preview-item"},[_c('div',{staticClass:"swiper-slide-image",style:({
            height:
              !product.item.title &&
              !product.item.description &&
              !product.item.price &&
              !product.item.oldPrice
                ? '100%'
                : '',
          })},[_c('img',{staticStyle:{"height":"100%","width":"100%","object-fit":"fill"},attrs:{"src":product.item.images[0].url}})]),(product.item.banner && product.item.banner.text)?_c('span',{key:_vm.restartAnimationKey + Math.random(),staticClass:"banner custom-banner",class:[
              _vm.getAnimationClasses(
                null,
                _vm.theme.banner.container.delay,
                'banner-container'
              ),
            ],style:({ backgroundColor: _vm.theme.banner.container.color }),attrs:{"id":"banner-container"}},[_c('span',{directives:[{name:"text-fit",rawName:"v-text-fit",value:({
              minFont: 10,
              maxFont: 512,
              maxHeightByPercent: 100,
            }),expression:"{\n              minFont: 10,\n              maxFont: 512,\n              maxHeightByPercent: 100,\n            }"}]},[_c('span',{key:_vm.restartAnimationKey + Math.random(),class:[
                  _vm.getAnimationClasses(
                    _vm.theme.banner.text.animation,
                    _vm.theme.banner.text.delay,
                    'banner-text'
                  ),
                ],style:({ color: _vm.theme.banner.text.color }),attrs:{"id":"banner-text"}},[_vm._v(_vm._s(product.item.banner.text))])])]):_vm._e(),(
            product.item.title ||
            product.item.description ||
            product.item.price ||
            product.item.oldPrice
          )?_c('div',{staticClass:"wrapper-content"},[_c('div',{staticClass:"card-info theme-content h-100",style:({ backgroundColor: _vm.theme.content.container.color })},[(product.item.title)?_c('div',{directives:[{name:"text-fit",rawName:"v-text-fit",value:({
                minFont: 10,
                maxFont: 512,
                maxHeightByPercent: 70,
                checkHasSibling: true,
              }),expression:"{\n                minFont: 10,\n                maxFont: 512,\n                maxHeightByPercent: 70,\n                checkHasSibling: true,\n              }"}],key:_vm.restartAnimationKey + Math.random(),staticClass:"swiper-slide-title",class:[
                  _vm.getAnimationClasses(
                    _vm.theme.content.title.animation,
                    _vm.theme.content.title.delay,
                    'theme-title'
                  ),
                ],style:({ color: _vm.theme.content.title.color }),attrs:{"id":"theme-title"},domProps:{"innerHTML":_vm._s(_vm.wrapString(product.item.title, 30))}}):_vm._e(),(product.item.description)?_c('div',{directives:[{name:"text-fit",rawName:"v-text-fit",value:({
                minFont: 10,
                maxFont: 512,
                maxHeightByPercent: 30,
              }),expression:"{\n                minFont: 10,\n                maxFont: 512,\n                maxHeightByPercent: 30,\n              }"}],key:_vm.restartAnimationKey + Math.random(),staticClass:"swiper-slide-text",class:[
                  _vm.getAnimationClasses(
                    _vm.theme.content.description.animation,
                    _vm.theme.content.description.delay,
                    'theme-description'
                  ),
                ],style:({ color: _vm.theme.content.description.color }),attrs:{"id":"theme-description"},domProps:{"innerHTML":_vm._s(_vm.wrapString(product.item.description, 50))}}):_vm._e()]),(product.item.price || product.item.oldPrice)?_c('div',{staticClass:"prices black--text",style:({
              backgroundColor: _vm.theme.prices.container.color,
            })},[(product.item.price)?_c('span',{directives:[{name:"text-fit",rawName:"v-text-fit",value:({
                minFont: 10,
                maxFont: 512,
                maxHeightByPercent: 70,
                checkHasSibling: true,
                quarterChild: true,
              }),expression:"{\n                minFont: 10,\n                maxFont: 512,\n                maxHeightByPercent: 70,\n                checkHasSibling: true,\n                quarterChild: true,\n              }"}],key:_vm.restartAnimationKey + Math.random(),staticClass:"price px-0 mx-0",class:[
                _vm.getAnimationClasses(
                  _vm.theme.prices.price.animation,
                  _vm.theme.prices.price.delay,
                  'price'
                ),
              ],style:({
                color: _vm.theme.prices.price.color,
              }),attrs:{"id":"price"}},[_vm._v(" "+_vm._s(product.item.price)+" "),_c('span',{staticClass:"currency"},[_vm._v(" "+_vm._s(_vm.currency)+" ")])]):_vm._e(),(product.item.oldPrice)?_c('span',{directives:[{name:"text-fit",rawName:"v-text-fit",value:({
                minFont: 10,
                maxFont: 512,
                maxHeightByPercent: 30,
                quarterChild: true,
              }),expression:"{\n                minFont: 10,\n                maxFont: 512,\n                maxHeightByPercent: 30,\n                quarterChild: true,\n              }"}],key:_vm.restartAnimationKey + Math.random(),staticClass:"discount ma-0",class:[
                _vm.getAnimationClasses(
                  _vm.theme.prices.oldPrice.animation,
                  _vm.theme.prices.oldPrice.delay,
                  'old-price'
                ),
              ],style:({ color: _vm.theme.prices.oldPrice.color }),attrs:{"id":"old-price"}},[_vm._v(" "+_vm._s(product.item.oldPrice)+" "),_c('span',{staticClass:"currency"},[_vm._v(" "+_vm._s(_vm.currency)+" ")])]):_vm._e()]):_vm._e()]):_vm._e()])])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }