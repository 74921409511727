<template>
  <div
    v-if="Object.keys(theme).length && activeProduct.item"
    class="theme h-100"
  >
    <div v-if="logo" class="swiper-logo bg-danger">
      <img
        :src="logo"
        width="50px"
        height="50px"
        class="object-cover rounded-circle"
        alt="logo"
      />
    </div>

    <div class="swiper-container">
      <div class="h-100 swiper-slide">
        <div class="swiper-slide-content preview-item">
          <div
            v-if="activeProduct.item.show === 'video'"
            class="swiper-slide-content video-container"
          >
            <video
              width="100%"
              height="100%"
              :style="`object-fit: ${activeProduct.item.video.videoShowType} !important;`"
              :poster="activeProduct.item.video.thumbnail"
              autoplay
              muted
              loop
            >
              <source :src="activeProduct.item.video.url" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>

          <div
            v-if="activeProduct.item.show === 'youtube'"
            class="swiper-slide-content video-container"
          >
            <iframe
              :src="extractedYoutubeEmbedUrl(activeProduct.item.links[0].url)"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
            <div class="video-overlay"></div>
          </div>

          <img
            v-if="activeProduct.item.show === 'image'"
            :style="`object-fit: ${activeProduct.item.imageShowType} !important;`"
            :src="activeProduct.item.images[0].url"
            style="width: 100%; height: 100%"
          />
          <div
            class="prices black--text d-flex flex-column justify-space-around cricle-30 pa-6"
            v-if="activeProduct.item.price || activeProduct.item.oldPrice"
            :style="{
              backgroundColor: theme.prices.container.color,
            }"
          >
            <div class="wrapper-price">
              <span
                v-if="activeProduct.item.price"
                :style="{
                  color: theme.prices.price.color,
                }"
                id="price"
                :data-product-id="activeProduct.item._id"
                class="price ma-0 theme-price"
              >
                {{ activeProduct.item.price }}
                <span class="currency">
                  {{ currency }}
                </span>
              </span>
              <span
                v-if="activeProduct.item.oldPrice"
                :data-product-id="activeProduct.item._id"
                :style="{ color: theme.prices.oldPrice.color }"
                id="old-price"
                class="discount ma-0 theme-old-price"
              >
                {{ activeProduct.item.oldPrice }}
                <span class="currency">
                  {{ currency }}
                </span>
              </span>
            </div>
          </div>
          <div
            class="wrapper-content"
            :class="{ 'w-0': !hasSomeContent(activeProduct, true) }"
          >
            <div
              class="wrapper-content-body pa-3"
              v-if="hasSomeContent(activeProduct, true)"
              :class="{
                'bg-transparent': !hasSomeContent(activeProduct, false),
              }"
              :style="{ backgroundColor: theme.container.color }"
            >
              <!-- :style="{ backgroundColor: theme.content.container.color }" -->
              <div class="card-info theme-content">
                <span
                  id="banner-container"
                  class="custom-banner"
                  v-if="
                    activeProduct.item.banner && activeProduct.item.banner.text
                  "
                  :style="{ backgroundColor: theme.banner.container.color }"
                >
                  <span
                    class="banner-font-size"
                    :data-product-id="activeProduct.item._id"
                  >
                    <span
                      :style="{ color: theme.banner.text.color }"
                      id="banner-text"
                      class="banner-text"
                      >{{ activeProduct.item.banner.text }}</span
                    >
                  </span>
                </span>
                <div class="content-wrapper">
                  <div
                    v-if="activeProduct.item.title"
                    :data-product-id="activeProduct.item._id"
                    class="swiper-slide-title theme-title"
                    :style="{
                      color: theme.content.title.color,
                    }"
                  >
                    {{ activeProduct.item.title }}
                  </div>
                  <div
                    v-if="activeProduct.item.description"
                    :data-product-id="activeProduct.item._id"
                    class="swiper-slide-text my-2 theme-description"
                    :style="{
                      color: theme.content.description.color,
                    }"
                  >
                    {{ activeProduct.item.description }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Bottom_Circle_Slide",
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    currency: {
      type: String,
      default: "",
      required: false,
    },
    logo: {
      type: String,
      default: "",
      required: false,
    },
    currentGroup: {
      type: Object,
      default: Object,
      required: false,
    },
    themeConfiguration: {
      type: Object,
      default: () => ({}),
    },
    theme: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    isLiveChangesPreview: {
      type: Boolean,
      default: false,
    },
    animationEffects: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      restartAnimationKey: 0,
    };
  },
  computed: {
    groupBgColor() {
      return this.currentGroup.groupId?.bg_color;
    },
    productsTimes() {
      return this.products.map((product) => Number(product.item.counter ?? 1));
    },
    activeProduct() {
      return this.products[0] || {};
    },
  },
};
</script>
<style scoped lang="scss">
.swiper-container {
  user-select: none;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;

  height: 100%;

  padding-top: 0px;
  padding-bottom: 0px;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  height: 100%;
  color: #fff;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;

  border-radius: 0px;
}
.slide-content {
  width: 100%;
  height: 100%;
}
.swiper-slide-content {
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: space-between;
  text-align: start;
  color: #000;
  position: relative;
}
.wrapper-content {
  position: absolute;
  height: 55.5%;
  width: 36%;
  box-shadow: none !important;
  position: absolute;
  bottom: 0%;
  right: 0;
}

.wrapper-content-body {
  background: #d9d9d9;
  height: 100%;
  width: 100%;
  clip-path: circle(75% at 72% 89.2%);
}
.custom-banner {
  display: inline-block;
  padding: 10px 60px;
  background: #fff;
  color: #000;
  text-align: center;
  width: 100%;
  height: 15%;
  font-weight: 500;
  word-break: break-word;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    line-height: 1.2 !important;
  }
}

.swiper-slide-title {
  text-align: left;
}
.swiper-slide-text {
  text-align: left;
  width: 100%;
}

.card-info {
  margin-top: 20%;
  width: 100%;
  height: calc(100% - 25%);
  padding-left: 28%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.swiper-logo {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 100;
}
.content-wrapper {
  height: 85%;
  width: 100%;
}
.cricle-30 {
  position: absolute;
  width: 16.875%;
  height: 30%;
  border-radius: 50%;
  background: #fdd039;
  color: #000;
  z-index: 100;
}
.prices {
  right: 3%;
  bottom: 45%;
}
.price {
  overflow: hidden;
}
.discount {
  overflow: hidden;
  display: block;
}
.wrapper-price {
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
}
</style>
