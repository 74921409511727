<template>
  <component
    :class="[isLiveChangesPreview ? 'live-changes-preview' : '']"
    :is="
      themeComponents[
        Number(
          isLiveChangesPreview
            ? Number(liveThemeChanges.numericId) - 1
            : Number(currentGroup.theme.numericId) - 1
        )
      ]
    "
    :theme-configuration="activeThemeConfiguration"
    :theme="isLiveChangesPreview ? liveThemeChanges : currentGroup.theme"
    :is-live-changes-preview="isLiveChangesPreview"
    :animation-effects="animationEffects"
    :groupBgColor="groupBgColor"
    :products="activeProducts"
    :currency="currency"
    :logo="logo"
    @goNext="$emit('goNext')"
  />
</template>

<script>
// import the twenty theme components
import themeConfigurations from "./themeConfigurations.js";
import MenuSlides from "./MenuSlides.vue";
import FourSlides from "./FourSlides.vue";
import TwoSlides from "./TwoSlides.vue";
import ThreeSlidesGroup from "./ThreeSlidesGroup.vue";
import Carousel from "./Carousel.vue";
import CardSlides from "./CardSlides.vue";
import CoverFlow from "./CoverFlow.vue";
import FlipCard from "./FlipCard.vue";
import VerticalFade from "./VerticalFade.vue";
import ThemeTen from "./ThemeTen.vue";
import FullScreen from "./FullScreen.vue";
import FullScreenSlide from "./FullScreenSlide.vue";
import HalfScreenSlide from "./HalfScreenSlide.vue";
import SideCircleSlide from "./SideCircleSlide.vue";
import BottomCircleSlide from "./BottomCircleSlide.vue";
import SlideWithGradient from "./SlideWithGradient.vue";
import CenterSlide from "./CenterSlide.vue";
import ThreeSlides from "./ThreeSlides.vue";
import MenuSlideOne from "./MenuSlideOne.vue";
import MenuSlideTwo from "./MenuSlideTwo.vue";
import CreativeTheme from "./CreativeTheme.vue";
import CardDesign from "./CardDesign.vue";
import animationEffects from "../../helpers/animationEffects.js";
import "animate.css";
// import equal from "fast-deep-equal";

export default {
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    currency: {
      type: String,
      default: "",
      required: false,
    },
    logo: {
      type: String,
      default: "",
      required: false,
    },
    currentGroup: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    activeTheme: {
      type: Object,
      default: Object,
      required: true,
    },
    isLiveChangesPreview: {
      type: Boolean,
      default: false,
    },
    liveThemeChanges: {
      type: Object,
      default: () => ({}),
    },
    nextGroupTrigger: {
      type: Number,
      default: Math.random(),
    },
  },
  data() {
    return {
      restartAnimationKey: 0,
      activeIndex: 0,
      animationEffects,
      themeComponents: [
        TwoSlides,
        ThreeSlides,
        HalfScreenSlide,
        FullScreen,
        SideCircleSlide,
        MenuSlideOne,
        MenuSlideTwo,
        CreativeTheme,
        CardDesign,
      ],
      themeConfigurations,
      activeProducts: [],
      numberOfSlides: 1,
      timeouts: [],
    };
  },
  computed: {
    groupBgColor() {
      return this.currentGroup.bg_color;
    },
    totalTime() {
      return this.$store.getters["getSliderGroupTotalTime"];
    },
    activeThemeConfiguration() {
      return this.themeConfigurations.find(
        (config) =>
          Number(config._id) ===
          Number(
            this.isLiveChangesPreview
              ? this.liveThemeChanges.numericId
              : this.currentGroup.theme.numericId
          )
      );
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.controlSlider(true);
    });
  },
  methods: {
    controlSlider(isInitialization = false) {
      if (!this.activeThemeConfiguration) return;

      let filteredProducts = this.filterProducts(this.currentGroup.products);

      const updateProducts = () => {
        // slice number of products from products array based on slidesPerView and activeIndex
        this.activeProducts = filteredProducts.slice(
          this.activeIndex * this.activeThemeConfiguration.productsPerSlide,
          (this.activeIndex + 1) *
            this.activeThemeConfiguration.productsPerSlide
        );

        this.$nextTick(() => {
          this.configureFontSizesAndAnimations(
            this.activeThemeConfiguration.themeParts(
              this.isLiveChangesPreview
                ? this.liveThemeChanges
                : this.currentGroup.theme
            ),
            this.activeProducts,
            this.isLiveChangesPreview
              ? this.liveThemeChanges.numericId
              : this.currentGroup.theme.numericId
          )
            .then(() => {
              // wait for configuration then resume the slider
              if (!this.isLiveChangesPreview) {
                this.timeouts.push(
                  setTimeout(
                    () => this.controlSlider(),
                    this.activeProducts.reduce(
                      (a, b) => a + Number(b.item.counter),
                      0
                    ) * 1000
                  )
                );
              }
            })
            .catch(() => {
              // console.log("token mismatch: from ThemeLayout");
            });
        });
      };

      if (isInitialization) {
        this.numberOfSlides = Math.ceil(
          filteredProducts.length /
            this.activeThemeConfiguration?.productsPerSlide
        );
        this.activeIndex = 0;
        updateProducts();
      } else {
        if (
          this.activeIndex >=
          filteredProducts.length /
            this.activeThemeConfiguration?.productsPerSlide -
            1
        ) {
          this.activeIndex = 0;
          this.$emit("goNext");
          return; /* from here it will wait for 'nextGroupTrigger' to trigger the next slide */
        } else {
          this.activeIndex += 1;

          updateProducts();
        }
      }
    },
    filterProducts(products) {
      return products.filter((product) => {
        return !product.item?.deleted && product.item?.active;
      });
    },
  },
  watch: {
    currentGroup: {
      deep: true,
      handler() {
        this.activeIndex = 0;

        let vm = this;
        function clearTimeouts() {
          return new Promise((resolve) => {
            vm.timeouts.forEach((timeout, i, arr) => {
              clearTimeout(timeout);
              if (i === arr.length - 1) {
                resolve();
              }
            });
          });
        }
        clearTimeouts().then(() => {
          // this.initSlider();
        });
      },
    },
    nextGroupTrigger() {
      this.controlSlider(true);
    },
  },
  beforeDestroy() {
    this.timeouts.forEach((timeout) => {
      clearTimeout(timeout);
    });
    this.$emit("clear-timeouts", this.timeouts);
  },
};
</script>
