<template>
  <div
    v-if="Object.keys(theme).length && activeProduct.item"
    class="theme h-100"
  >
    <div v-if="false" class="swiper-logo">
      <img
        :src="logo"
        width="50px"
        height="50px"
        class="object-cover rounded-circle"
        alt="logo"
      />
    </div>

    <div class="swiper-container">
      <div class="h-100 swiper-slide">
        <div class="swiper-slide-content preview-item">
          <div
            v-if="activeProduct.item.show === 'video'"
            class="swiper-slide-content video-container"
          >
            <video
              width="100%"
              height="100%"
              :style="`object-fit: ${activeProduct.item.video.videoShowType} !important;`"
              :poster="activeProduct.item.video.thumbnail"
              autoplay
              muted
              loop
            >
              <source :src="activeProduct.item.video.url" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>

          <div
            v-if="activeProduct.item.show === 'youtube'"
            class="swiper-slide-content video-container"
          >
            <iframe
              :src="extractedYoutubeEmbedUrl(activeProduct.item.links[0].url)"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
            <div class="video-overlay"></div>
          </div>

          <img
            v-if="activeProduct.item.show === 'image'"
            :style="`object-fit: ${activeProduct.item.imageShowType} !important;`"
            :src="activeProduct.item.images[0].url"
            style="width: 100%; height: 100%"
            class="swiper-slide-image"
          />
          <div class="wrapper-content">
            <div
              class="wrapper-content-body"
              v-if="hasSomeContent(activeProduct, true)"
              :class="{
                'bg-transparent': !hasSomeContent(activeProduct, false),
              }"
              :style="{ backgroundColor: theme.container.color }"
            >
              <div
                class="slide-content"
                v-if="hasSomeContent(activeProduct, false)"
              >
                <p
                  class="custom-banner ma-0"
                  v-if="
                    activeProduct.item.banner && activeProduct.item.banner.text
                  "
                  :style="{ backgroundColor: theme.banner.container.color }"
                  id="banner-container"
                >
                  <span
                    class="banner-font-size"
                    :data-product-id="activeProduct.item._id"
                  >
                    <span
                      :style="{ color: theme.banner.text.color }"
                      id="banner-text"
                      class="banner-text"
                      >{{ activeProduct.item.banner.text }}</span
                    >
                  </span>
                </p>
                <!-- :style="{ backgroundColor: theme.content.container.color }" -->
                <div class="card-info theme-content">
                  <div
                    class="swiper-slide-title theme-title"
                    :data-product-id="activeProduct.item._id"
                    v-if="activeProduct.item.title"
                    :style="{ color: theme.content.title.color }"
                    id="theme-title"
                    v-html="wrapString(activeProduct.item.title, 30)"
                  ></div>
                  <div
                    class="swiper-slide-text theme-description"
                    :data-product-id="activeProduct.item._id"
                    v-if="activeProduct.item.description"
                    :style="{ color: theme.content.description.color }"
                    id="theme-description"
                    v-html="wrapString(activeProduct.item.description, 50)"
                  ></div>
                </div>
                <div
                  class="prices black--text d-flex flex-column"
                  v-if="activeProduct.item.price || activeProduct.item.oldPrice"
                  :style="{
                    backgroundColor: theme.prices.container.color,
                  }"
                >
                  <span
                    :data-product-id="activeProduct.item._id"
                    class="price theme-price"
                    v-if="activeProduct.item.price"
                    :style="{
                      color: theme.prices.price.color,
                    }"
                  >
                    {{ activeProduct.item.price }}
                    <span class="currency">
                      {{ currency }}
                    </span>
                  </span>
                  <span
                    class="discount ma-0 theme-old-price"
                    v-if="activeProduct.item.oldPrice"
                    :data-product-id="activeProduct.item._id"
                    :style="{ color: theme.prices.oldPrice.color }"
                    id="old-price"
                  >
                    {{ activeProduct.item.oldPrice }}
                    <span class="currency">
                      {{ currency }}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SideCircleSlide",
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    currency: {
      type: String,
      default: "",
      required: false,
    },
    logo: {
      type: String,
      default: "",
      required: false,
    },
    currentGroup: {
      type: Object,
      default: Object,
      required: false,
    },
    themeConfiguration: {
      type: Object,
      default: () => ({}),
    },
    theme: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    isLiveChangesPreview: {
      type: Boolean,
      default: false,
    },
    animationEffects: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    groupBgColor() {
      return this.currentGroup.groupId?.bg_color;
    },
    productsTimes() {
      return this.products.map((product) => Number(product.item.counter ?? 1));
    },
    activeProduct() {
      return this.products[0] || {};
    },
  },
};
</script>
<style scoped lang="scss">
.swiper-container {
  user-select: none;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;

  height: 100%;

  padding-top: 0px;
  padding-bottom: 0px;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #fff;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;

  border-radius: 0px;
}

.swiper-slide-content {
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: space-between;
  text-align: start;
  color: #000;
}
.wrapper-content {
  position: absolute;
  right: 0;
  height: auto;
  height: 100%;
  width: 34%;
  box-shadow: none !important;
}

.wrapper-content-body {
  background: #f2f2f2;
  height: 100%;
  width: 100%;
  border-radius: 50% 0 0 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;
}
.slide-content {
  display: flex;
  flex-direction: column;
  height: 70%;
}

.card-info {
  height: 40%;
  margin-bottom: 0;
}
.prices {
  height: 40%;
  background: #fdd039;
  align-items: center;
  margin-top: 0;
}
.custom-banner {
  padding: 10px 12px;
  background: #fdd039;
  color: #000;
  width: 100%;
  text-align: start;
  height: 10%;
  line-height: 1.3;
  margin-bottom: 3%;
  word-break: break-word;
  display: flex;
  justify-content: start;
  align-items: center;
  span {
    line-height: 1.2 !important;
  }
}

.custom-banner,
.card-info,
.prices {
  padding-left: 20%;
}

.swiper-logo {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 100;
}
.bg-transparent {
  background-color: transparent;
}
.swiper-slide-title {
  display: flex;
  align-items: flex-end;
}
.discount {
  // width: max-content;
  // height: 30%;
  // width: 100%;
  text-align: center;
  // display: flex;
  // justify-content: center;
  // align-items: center;
}
</style>
