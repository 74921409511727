<template>
  <div
    v-if="Object.keys(theme).length"
    class="theme h-100 d-flex justify-center"
    :style="{
      backgroundColor: theme.container.color || '#FFFFFF',
    }"
  >
    <div v-if="false" class="swiper-logo">
      <img
        :src="logo"
        width="50px"
        height="50px"
        class="object-cover rounded-circle"
        alt="logo"
      />
    </div>
    <div ref="swiper" class="swiper-container">
      <v-row class="flex-wrap h-100 my-auto">
        <v-col
          class="slider-col"
          cols="4"
          v-for="product in products"
          :key="product._id"
        >
          <div class="swiper-slide my-4 h-100 relative">
            <p
              class="custom-banner banner"
              v-if="product.item.banner && product.item.banner.text"
              :style="{ backgroundColor: theme.banner.container.color }"
              id="banner-container"
            >
              <span
                class="banner-font-size"
                :data-product-id="product.item._id"
              >
                <span
                  id="banner-text"
                  class="banner-text"
                  :style="{ color: theme.banner.text.color }"
                  >{{ product.item.banner.text }}</span
                >
              </span>
            </p>
            <div class="swiper-slide-content preview-item">
              <div
                v-if="product.item.show === 'video'"
                class="swiper-slide-content video-container"
              >
                <video
                  width="100%"
                  height="100%"
                  :style="`object-fit: ${product.item.video.videoShowType} !important;`"
                  :poster="product.item.video.thumbnail"
                  autoplay
                  muted
                  loop
                >
                  <source :src="product.item.video.url" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>

              <div
                v-if="product.item.show === 'youtube'"
                class="swiper-slide-content video-container"
              >
                <iframe
                  :src="extractedYoutubeEmbedUrl(product.item.links[0].url)"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <div class="video-overlay"></div>
              </div>

              <div
                v-else-if="product.item.show === 'image'"
                class="wrapper-image"
                :style="{
                  height:
                    !product.item.title &&
                    !product.item.description &&
                    !product.item.price &&
                    !product.item.oldPrice
                      ? '100%'
                      : '',
                }"
              >
                <img
                  :src="product.item.images[0].url"
                  :style="`objectFit: ${product.item.imageShowType} !important`"
                  class="swiper-slide-image"
                />
              </div>

              <div
                class="wrapper-content"
                v-if="
                  product.item.title ||
                  product.item.description ||
                  product.item.price ||
                  product.item.oldPrice
                "
              >
                <div
                  :style="{ backgroundColor: theme.content.container.color }"
                  class="card-info theme-content text-start d-flex flex-column"
                >
                  <div
                    class="swiper-slide-title theme-title"
                    v-if="product.item.title"
                    :data-product-id="product.item._id"
                    :style="{ color: theme.content.title.color }"
                    id="theme-title"
                  >
                    {{ product.item.title }}
                  </div>
                  <div
                    v-if="product.item.description"
                    :data-product-id="product.item._id"
                    id="theme-description"
                    class="swiper-slide-text theme-description"
                    :style="{ color: theme.content.description.color }"
                    v-html="wrapString(product.item.description, 50)"
                  ></div>
                </div>
                <div
                  class="prices black--text"
                  v-if="product.item.price || product.item.oldPrice"
                  :style="{
                    backgroundColor: theme.prices.container.color,
                  }"
                >
                  <span
                    v-if="product.item.price"
                    :style="{
                      color: theme.prices.price.color,
                    }"
                    id="price"
                    :data-product-id="product.item._id"
                    class="price d-inline-block mx-0 mr-3 theme-price"
                  >
                    {{ product.item.price }}
                    <span class="currency">
                      {{ currency }}
                    </span>
                  </span>
                  <span
                    v-if="product.item.oldPrice"
                    :data-product-id="product.item._id"
                    :style="{ color: theme.prices.oldPrice.color }"
                    id="old-price"
                    class="ma-0 discount theme-old-price"
                  >
                    {{ product.item.oldPrice
                    }}<span class="currency">{{ currency }}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
export default {
  name: "MenuSlide2",
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    currency: {
      type: String,
      default: "",
      required: false,
    },
    logo: {
      type: String,
      default: "",
      required: false,
    },
    currentGroup: {
      type: Object,
      default: Object,
      required: false,
    },
    themeConfiguration: {
      type: Object,
      default: () => ({}),
    },
    theme: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    isLiveChangesPreview: {
      type: Boolean,
      default: false,
    },
    animationEffects: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      restartAnimationKey: 0,
      endCalculateFont: false,
    };
  },
  computed: {
    groupBgColor() {
      return this.currentGroup.groupId?.bg_color;
    },
    productsTimes() {
      return this.products.map((product) => Number(product.item.counter ?? 1));
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.swiper-container {
  user-select: none;
  box-sizing: border-box;
  overflow: visible;
  width: 95%;
  height: 100%;
  padding-top: 0px;
  padding-bottom: 0px;
}

.slider-col {
  height: 29.2%;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #fff;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  border-radius: 14px;
  overflow: hidden;
  box-shadow: 3px 4px 10px 2px #00000040;

  // background-color: rgba(51, 51, 51, 1);
}
.swiper-slide-content {
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  color: #000;

  &.video-container {
    position: relative;
    border-radius: 0;
  }
}

.wrapper-content {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.94);
  width: 46.5%;
  height: 100%;
  text-align: left;
  justify-content: space-between;
  margin-inline-start: auto;

  .card-info,
  .prices {
    color: #000;
    text-align: center;
  }

  .card-info {
    height: 42% !important;
    flex-grow: 1;
  }
  .prices {
    height: calc(100% - 42%) !important;
    background-color: #fdd039;
    padding: 5px 10px;
  }
}

.swiper-logo {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
}

.wrapper-image {
  // position: relative;
  // left: 0;
  // top: 0;
  width: 53.5%;
  flex-grow: 1;
  height: 100%;
  z-index: 0;
}
.swiper-slide-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.prices {
  display: flex;
  // flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.custom-banner {
  background-color: #fdd039;
  color: #000;
  padding: 10px 12px;
  word-break: break-word;
  font-weight: 500;
  top: 0;
  left: 0;
  max-width: 50%;
  height: 25%;
  margin-left: 0;
  display: flex;
  border-radius: 0;
  border-bottom-right-radius: 14px;
  transform: translateX(0) translateY(0);
  span {
    line-height: 1.2 !important;
    width: 100%;
  }
}
</style>
