<template>
  <swiper-container
    init="false"
    ref="swiper"
    class="swiper-container"
    :style="{ backgroundColor: theme.container.color || '#FFFFFF' }"
  >
    <swiper-slide v-for="(product, i) in products" :key="i">
      <div class="menu-slide-card preview-item">
        <img
          :src="product.item.images[0].url"
          class="menu-slide-card__img"
          :style="{
            height:
              !product.item.title &&
              !product.item.description &&
              !product.item.price &&
              !product.item.oldPrice
                ? '100%'
                : '',
          }"
        />
        <p
          class="banner"
          v-if="product.item.banner && product.item.banner.text"
          :style="{ backgroundColor: theme.banner.container.color }"
          id="banner-container"
          :key="restartAnimationKey + Math.random()"
          :class="[
            getAnimationClasses(
              null,
              theme.banner.container.delay,
              'banner-container'
            ),
          ]"
        >
          <span
            :style="{ color: theme.banner.text.color }"
            id="banner-text"
            :key="restartAnimationKey + Math.random()"
            :class="[
              getAnimationClasses(
                theme.banner.text.animation,
                theme.banner.text.delay,
                'banner-text'
              ),
            ]"
            >{{ product.item.banner.text }}</span
          >
        </p>
        <div
          class="menu-slide-card__content"
          v-if="
            product.item.title ||
            product.item.description ||
            product.item.price ||
            product.item.oldPrice
          "
        >
          <div class="menu-slide-card__left">
            <div
              class="menu-slide-card__title"
              v-if="product.item.title"
              :style="{ color: theme.content.title.color }"
              id="theme-title"
              :key="restartAnimationKey + Math.random()"
              :class="[
                getAnimationClasses(
                  theme.content.title.animation,
                  theme.content.title.delay,
                  'theme-title'
                ),
              ]"
              ref="titles"
              v-html="truncateString(wrapString(product.item.title, 20), 30)"
            ></div>
            <div
              class="menu-slide-card__description"
              v-if="product.item.description"
              :style="{ color: theme.content.description.color }"
              id="theme-description"
              :key="restartAnimationKey + Math.random()"
              :class="[
                getAnimationClasses(
                  theme.content.description.animation,
                  theme.content.description.delay,
                  'theme-description'
                ),
              ]"
              ref="descriptions"
              v-html="
                truncateString(wrapString(product.item.description, 50), 10)
              "
            ></div>
          </div>
          <div
            class="menu-slide-card__right"
            v-if="product.item.price || product.item.oldPrice"
            :style="{
              backgroundColor: theme.prices.container.color,
            }"
          >
            <span
              class="menu-slide-card__price"
              ref="prices"
              v-if="product.item.price"
              :style="{
                color: theme.prices.price.color,
              }"
              id="price"
              :key="restartAnimationKey + Math.random()"
              :class="[
                getAnimationClasses(
                  theme.prices.price.animation,
                  theme.prices.price.delay,
                  'price'
                ),
              ]"
            >
              {{ product.item.price }}
              <span class="currency" ref="currency">
                {{ currency }}
              </span>
            </span>
            <span
              class="discount"
              v-if="product.item.oldPrice"
              :style="{ color: theme.prices.oldPrice.color }"
              id="old-price"
              :key="restartAnimationKey + Math.random()"
              :class="[
                getAnimationClasses(
                  theme.prices.oldPrice.animation,
                  theme.prices.oldPrice.delay,
                  'old-price'
                ),
              ]"
              ref="discounds"
            >
              {{ product.item.oldPrice }}
              <span class="currency" ref="discount_currency">
                {{ currency }}
              </span>
            </span>
          </div>
        </div>
      </div>
    </swiper-slide>
  </swiper-container>
</template>

<script>
export default {
  name: "menuSlides",
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    currency: {
      type: String,
      default: "",
      required: false,
    },
    currentGroup: {
      type: Object,
      default: Object,
      required: false,
    },
    themeConfiguration: {
      type: Object,
      default: () => ({}),
    },
    theme: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    isLiveChangesPreview: {
      type: Boolean,
      default: false,
    },
    animationEffects: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      restartAnimationKey: 0,
      endCalculateFont: false,
      slidesPerView: 3,
    };
  },
  computed: {
    groupBgColor() {
      return this.currentGroup.groupId?.bg_color;
    },
    productsTimes() {
      return this.products.map((product) => Number(product.item.counter ?? 1));
    },
  },
  mounted() {
    this.$emit("initializeSwiper", this.$refs.swiper, this.swiperParams);
  },
  methods: {
    truncateString(str, num) {
      if (str.length <= num) {
        return str;
      }
      return str.slice(0, num) + "...";
    },

    calculateFont() {
      this.endCalculateFont = false;
      this.$store.commit("showLogoLayer", true);

      setTimeout(() => {
        if (this.$refs.titles.length > 1) {
          this.reSizeFont({
            content: this.$refs.titles,
            checkIfHasSub: true,
            minFont: 5,
            maxFont: 110,
            maxHeightByPercent: 70,
          });
        }

        if (this.$refs.descriptions.length > 1) {
          this.reSizeFont({
            content: this.$refs.descriptions,
            checkIfHasSub: true,
            minFont: 5,
            maxFont: 100,
            maxHeightByPercent: 35,
          });
        }

        if (this.$refs.prices.length > 1) {
          this.reSizeFont({
            content: this.$refs.prices,
            checkIfHasSub: true,
            minFont: 15,
            maxFont: 100,
            maxHeightByPercent: 70,
          });
        }

        if (this.$refs.discounds.length > 1) {
          this.reSizeFont({
            content: this.$refs.discounds,
            checkIfHasSub: true,
            minFont: 13,
            maxFont: 185,
            maxHeightByPercent: 30,
          });
        }

        if (this.$refs.currency.length > 1) {
          this.reSizeFont({
            content: this.$refs.currency,
            checkIfHasSub: false,
            minFont: 15,
            maxFont: 100,
            maxHeightByPercent: 30,
          });
        }

        if (this.$refs.discount_currency.length > 1) {
          this.reSizeFont({
            content: this.$refs.discount_currency,
            checkIfHasSub: false,
            minFont: 13,
            maxFont: 185,
            maxHeightByPercent: 20,
          });
        }
        if (this.$refs.banner.length > 1) {
          this.reSizeFont({
            content: this.$refs.banner,
            minFont: 5,
            maxFont: 185,
            maxHeightByPercent: 30,
          });
        }
        this.endCalculateFont = true;
        this.$store.commit("showLogoLayer", false);
      }, 100);
    },
    getAnimationClasses(animationName, animationDelay, id) {
      if (!animationName) {
        return "";
      }

      const animationDelayClass = animationDelay
        ? `animate__delay-${animationDelay}s`
        : "";

      const animationClass = this.animationEffects.find(
        (effect) => effect.name === animationName
      )?.class;

      this.$nextTick(() => {
        if (id) {
          let el = document.querySelector(`#${id}`);

          setTimeout(() => {
            el.classList.remove("opacity-0");
          }, Number(animationDelay) * 1000 - 100);
        }
      });

      return `animate__animated ${animationClass} ${animationDelayClass} opacity-0`;
    },
    initSwiper() {
      const swiperEl = this.$refs.swiper;
      // const swiperParams = {
      //   spaceBetween: 24,
      //   slidesPerView: 6,
      //   loopedSlides: 6,
      //   speed: this.totalTime / this.products.length,
      //   pagination: true,
      //   grid: {
      //     rows: 4,
      //   },
      //   initialSlide: 0,
      //   autoplay: {
      //     delay: this.totalTime,
      //     enable: true,
      //   },
      // };

      const swiperParams = this.themeConfiguration.swiperParams({
        speed: this.totalTime / this.products.length,
        delay: this.calcTime(this.activeIndex, this.productsTimes, 1),
        handleNextGroup: this.handleNextGroup,
      });

      Object.assign(swiperEl, swiperParams);
      // and now initialize it
      this.$nextTick(() => {
        swiperEl.initialize();

        // stop slider autoplay when user switch to another tab or window
        document.addEventListener("visibilitychange", () => {
          if (document.visibilityState === "hidden") {
            this.$refs.swiper && this.$refs.swiper.swiper.autoplay.stop();
          } else {
            this.$refs.swiper && this.$refs.swiper.swiper.autoplay.start();
          }
        });
      });
    },
    handleNextGroup() {
      this.activeIndex = 1;
      setInterval(() => {
        if (this.activeIndex == this.products.length + 1) {
          this.activeIndex = 0;
          this.$emit("goNext");
        } else {
          this.activeIndex += 1;
        }
      }, this.totalTime);
    },
  },
};
</script>
<style scoped lang="scss">
:root {
  --swiper-pagination-color: #000;
  --swiper-pagination-bullet-inactive-color: #000;
}

swiper-container {
  user-select: none;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-top: 24px;
  padding-bottom: 48px;
}
swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc((100% - 72px) / 4);
  color: #fff;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 30px;
  border: 1px solid transparent;
}
.menu-slide-card {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  justify-content: space-between;
  text-align: center;
  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60%;
    object-fit: cover;
  }
  &__content {
    position: absolute;
    display: flex;
    background-color: #fff;
    width: 100%;
    height: 40%;
    justify-content: space-between;
    bottom: 0;
  }
  &__left {
    width: 65%;
    padding: 5px 10px;
    text-align: left;
    word-break: break-word;
  }
  &__right {
    background-color: #fdd039;
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #000;
  }
  &__title {
    font-weight: bold;
  }
  &__title,
  &__description {
    color: rgba(0, 0, 0, 1);
  }
  &__price {
    font-weight: bold;
  }
}
.banner {
  background-color: #fdd039;
  color: #000;
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
