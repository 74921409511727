<template>
  <div
    class="h-100 d-flex align-center"
    :style="{ backgroundColor: theme.container.color || '#FFFFFF' }"
  >
    <swiper-container init="false" ref="swiper" class="swiper-container">
      <swiper-slide
        v-for="product in products"
        :key="product._id"
        class="h-100"
      >
        <p
          class="banner"
          v-if="product.item.banner && product.item.banner.text"
              :style="{ backgroundColor: theme.banner.container.color }"
              id="banner-container"
              :key="restartAnimationKey + Math.random()"
              :class="[
                getAnimationClasses(
                  null,
                  theme.banner.container.delay,
                  'banner-container'
                ),
              ]"        
>
          <span :style="{ color: theme.banner.text.color }"
                    id="banner-text"
                  :key="restartAnimationKey + Math.random()" :class="[
                    getAnimationClasses(
                      theme.banner.text.animation,
                      theme.banner.text.delay,
                      'banner-text'
                    ),
                  ]">{{product.item.banner.text }}</span>
        </p>
        <div
          class="swiper-slide-content preview-item"
          data-swiper-parallax="10%"
        >
          <img
            :src="product.item.images[0].url"
            style="width: 100%; height: 100%; object-fit: cover"
          />
          <div class="wrapper-content">
            <div>
              <div
                class="swiper-slide-title"
                v-if="product.item.title"
                :style="{ color: theme.content.title.color }"
                  id="theme-title"
                  :key="restartAnimationKey + Math.random()"
                  :class="[
                    getAnimationClasses(
                      theme.content.title.animation,
                      theme.content.title.delay,
                      'theme-title'
                    ),
                  ]"              >
                {{ product.item.title }}
              </div>
              <div
                class="swiper-slide-text"
                v-if="product.item.description"
                :style="{ color: theme.content.description.color }"
                  id="theme-description"
                  :key="restartAnimationKey + Math.random()"

                  :class="[
                    getAnimationClasses(
                      theme.content.description.animation,
                      theme.content.description.delay,
                      'theme-description'
                    ),
                  ]"

                v-html="wrapString(product.item.description, 50)"
              ></div>
            </div>
            <div class="prices black--text d-flex flex-column align-center">
              <span class="price" v-if="product.item.price"
                :style="{
                  color: theme.prices.price.color,
                }"
                id="price"
                :key="restartAnimationKey + Math.random()"
                :class="[
                  getAnimationClasses(
                    theme.prices.price.animation,
                    theme.prices.price.delay,
                    'price'
                  ),
                ]"
>
                {{ product.item.price }}
                <span class="currency">
                  {{ currency }}
                </span>
              </span>
              <span class="discount" v-if="product.item.oldPrice"
                :style="{ color: theme.prices.oldPrice.color }"
                id="old-price"
                :key="restartAnimationKey + Math.random()"
                :class="[
                  getAnimationClasses(
                    theme.prices.oldPrice.animation,
                    theme.prices.oldPrice.delay,
                    'old-price'
                  ),
                ]"
>
                {{ product.item.oldPrice }}
                <span class="currency">
                  {{ currency }}
                </span>
              </span>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper-container>

    <div class="swiper-pagination"></div>
  </div>
</template>
<script>
export default {
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    currency: {
      type: String,
      default: "",
      required: false,
    },
  },
  computed: {
    groupBgColor() {
      return this.currentGroup.groupId?.bg_color;
    },
    productsTimes() {
      return this.products.map((product) => Number(product.item.counter ?? 1));
    },
  },
  mounted() {
    // responsible for switching to the next group of slides
    this.handleNextGroup();

    // initialize swiper
    this.initSwiper();
  },
  methods: {
    calculateFont() {
      this.endCalculateFont = false;
      this.$store.commit("showLogoLayer", true);

      setTimeout(() => {
        if (this.$refs.titles.length > 1) {
          this.reSizeFont({
            content: this.$refs.titles,
            minFont: 10,
            maxFont: 512,
            maxHeightByPercent: 60,
          });
        }

        if (this.$refs.descriptions.length > 1) {
          this.reSizeFont({
            content: this.$refs.descriptions,
            minFont: 10,
            maxFont: 512,
            maxHeightByPercent: 40,
          });
        }

        if (this.$refs.prices.length > 1) {
          this.reSizeFont({
            content: this.$refs.prices,
            minFont: 15,
            maxFont: 512,
            maxHeightByPercent: 60,
          });
        }

        if (this.$refs.discounds.length > 1) {
          this.reSizeFont({
            content: this.$refs.discounds,
            minFont: 10,
            maxFont: 512,
            maxHeightByPercent: 30,
          });
        }

        if (this.$refs.currency.length > 1) {
          this.reSizeFont({
            content: this.$refs.currency,
            minFont: 15,
            maxFont: 512,
            maxHeightByPercent: 30,
          });
        }

        if (this.$refs.discount_currency.length > 1) {
          this.reSizeFont({
            content: this.$refs.discount_currency,
            minFont: 15,
            maxFont: 512,
            maxHeightByPercent: 20,
          });
        }
        if (this.$refs.banner.length > 1) {
          this.reSizeFont({
            content: this.$refs.banner,
            minFont: 5,
            maxFont: 512,
            maxHeightByPercent: 100,
          });
        }
        this.endCalculateFont = true;
        this.$store.commit("showLogoLayer", false);
      }, 100);
    },
    getAnimationClasses(animationName, animationDelay, id) {
      if (!animationName) {
        return "";
      }

      const animationDelayClass = animationDelay
        ? `animate__delay-${animationDelay}s`
        : "";

      const animationClass = this.animationEffects.find(
        (effect) => effect.name === animationName
      )?.class;

      this.$nextTick(() => {
        if (id) {
          let el = document.querySelector(`#${id}`);

          setTimeout(() => {
            el.classList.remove("opacity-0");
          }, Number(animationDelay) * 1000 - 100);
        }
      });

      return `animate__animated ${animationClass} ${animationDelayClass} opacity-0`;
    },
    initSwiper() {
      const swiperEl = this.$refs.swiper;
      // const swiperParams = {
      //   threshold: 5,
      //   grabCursor: true,
      //   autoplay: { disableOnInteraction: false, enabled: true },
      //   watchSlidesProgress: true,
      //   pagination: { type: "progressbar", el: ".swiper-pagination" },
      //   observeParents: true,
      //   observer: true,
      //   parallax: { enabled: true },
      //   speed: this.$store.getters["getSliderGroupTotalTime"] / this.products.length,
      //   initialSlide: 0,
      // };

      const swiperParams = this.themeConfiguration.swiperParams({
        speed: this.totalTime / this.products.length,
        delay: this.calcTime(this.activeIndex, this.productsTimes, 1),
        handleNextGroup: this.handleNextGroup,
      });

      // now we need to assign all parameters to Swiper element
      Object.assign(swiperEl, swiperParams);

      // and now initialize it
      this.$nextTick(() => {
        swiperEl.initialize();

        // stop slider autoplay when user switch to another tab or window
        document.addEventListener("visibilitychange", () => {
          if (document.visibilityState === "hidden") {
            this.$refs.swiper && this.$refs.swiper.swiper.autoplay.stop();
          } else {
            this.$refs.swiper && this.$refs.swiper.swiper.autoplay.start();
          }
        });
      });
    },
    handleNextGroup() {
      this.activeIndex = 1;
      setInterval(() => {
        if (this.activeIndex == this.products.length + 1) {
          this.activeIndex = 0;
          this.$emit("goNext");
        } else {
          this.activeIndex += 1;
        }
      }, this.totalTime);
    },
  },
  data() {
    return {
      restartAnimationKey: 0,
      activeIndex: 0,
      activeProduct: this.products[0],
      productChanged: true,
      endCalculateFont: false,
      slidesPerView: 3,
    };
  },
};
</script>
<style scoped>
swiper-container {
  user-select: none;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;

  height: 480px;

  padding-top: 0px;
  padding-bottom: 0px;
}

swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #fff;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;

  border-radius: 0px;
}

.swiper-slide-content {
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1;
  justify-content: space-between;
  text-align: center;
  color: #000;
}

.swiper-slide-title {
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 0.03em;
}

.swiper-slide-image {
  position: absolute;
  object-fit: cover;

  left: 0%;
  top: 0%;
  width: 100%;
  height: 100%;

  z-index: 0;
}
.wrapper-content {
  position: absolute;
  display: flex;
  background-color: rgba(255, 255, 255, 0.94);
  width: 100%;
  align-self: flex-end;
  padding: 30px 15px;
  text-align: left;
  justify-content: space-between;
}
.price {
  font-size: 1.3rem;
  font-weight: bold;
}
.discount {
  font-size: 1rem;
}
</style>
