var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-100"},[(false)?_c('div',{staticClass:"swiper-logo"},[_c('img',{staticClass:"object-cover rounded-circle",attrs:{"src":_vm.logo,"width":"50px","height":"50px","alt":"logo"}})]):_vm._e(),_c('swiper-container',{ref:"swiper",staticClass:"swiper-container",attrs:{"init":"false"}},_vm._l((_vm.products),function(product){return _c('swiper-slide',{key:product._id,staticClass:"h-100"},[(product.item.images.length)?_c('div',{staticClass:"swiper-slide-content preview-item"},[_c('img',{staticStyle:{"width":"100%","height":"100%","object-fit":"cover"},attrs:{"src":product.item.images[0].url}}),_c('div',{staticClass:"wrapper-content"},[(_vm.hasSomeContent(product, true))?_c('div',{staticClass:"wrapper-content-info pa-15"},[(product.item.banner && product.item.banner.text)?_c('p',{key:_vm.restartAnimationKey + Math.random(),staticClass:"custom-banner",class:[
                _vm.getAnimationClasses(
                  null,
                  _vm.theme.banner.container.delay,
                  'banner-container'
                ),
              ],style:({ backgroundColor: _vm.theme.banner.container.color }),attrs:{"id":"banner-container"}},[_c('span',{ref:"banner",refInFor:true},[_c('span',{key:_vm.restartAnimationKey + Math.random(),class:[
                    _vm.getAnimationClasses(
                      _vm.theme.banner.text.animation,
                      _vm.theme.banner.text.delay,
                      'banner-text'
                    ),
                  ],style:({ color: _vm.theme.banner.text.color }),attrs:{"id":"banner-text"}},[_vm._v(_vm._s(product.item.banner.text))])])]):_vm._e(),_c('div',{staticClass:"slide-content"},[_c('div',{staticClass:"w-100 h-100"},[(product.item.title)?_c('div',{key:_vm.restartAnimationKey + Math.random(),ref:"titles",refInFor:true,staticClass:"swiper-slide-title",class:[
                    _vm.getAnimationClasses(
                      _vm.theme.content.title.animation,
                      _vm.theme.content.title.delay,
                      'theme-title'
                    ),
                  ],style:({ color: _vm.theme.content.title.color }),attrs:{"id":"theme-title"},domProps:{"innerHTML":_vm._s(_vm.wrapString(product.item.title, 30))}}):_vm._e(),(product.item.description)?_c('div',{key:_vm.restartAnimationKey + Math.random(),ref:"descriptions",refInFor:true,staticClass:"swiper-slide-text my-2",class:[
                    _vm.getAnimationClasses(
                      _vm.theme.content.description.animation,
                      _vm.theme.content.description.delay,
                      'theme-description'
                    ),
                  ],style:({ color: _vm.theme.content.description.color }),attrs:{"id":"theme-description"},domProps:{"innerHTML":_vm._s(_vm.wrapString(product.item.description, 60))}}):_vm._e()])])]):_vm._e(),(product.item.price)?_c('div',{staticClass:"wrapper-content-price mr-10 pa-5"},[_c('div',{staticClass:"prices black--text d-flex flex-column"},[_c('span',{ref:"prices",refInFor:true,staticClass:"price ma-0",style:({
                  color: _vm.theme.prices.price.color,
                })},[_vm._v(" "+_vm._s(product.item.price)+" "),_c('span',{ref:"currency",refInFor:true,staticClass:"currency"},[_vm._v(" "+_vm._s(_vm.currency)+" ")])]),(product.item.oldPrice)?_c('span',{key:_vm.restartAnimationKey + Math.random(),ref:"discounds",refInFor:true,staticClass:"discount text-center",class:[
                  _vm.getAnimationClasses(
                    _vm.theme.prices.oldPrice.animation,
                    _vm.theme.prices.oldPrice.delay,
                    'old-price'
                  ),
                ],style:({ color: _vm.theme.prices.oldPrice.color }),attrs:{"id":"old-price"}},[_vm._v(" "+_vm._s(product.item.oldPrice)+" "),_c('span',{ref:"discount_currency",refInFor:true,staticClass:"currency"},[_vm._v(" "+_vm._s(_vm.currency)+" ")])]):_vm._e()])]):_vm._e()])]):_vm._e()])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }