var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-100 d-flex align-center",style:({ backgroundColor: _vm.theme.container.color || '#FFFFFF' })},[_c('swiper-container',{ref:"swiper",staticClass:"swiper-container",attrs:{"init":"false"}},_vm._l((_vm.products),function(product){return _c('swiper-slide',{key:product._id,staticClass:"h-100"},[(product.item.banner && product.item.banner.text)?_c('p',{key:_vm.restartAnimationKey + Math.random(),staticClass:"banner",class:[
                _vm.getAnimationClasses(
                  null,
                  _vm.theme.banner.container.delay,
                  'banner-container'
                ),
              ],style:({ backgroundColor: _vm.theme.banner.container.color }),attrs:{"id":"banner-container"}},[_c('span',{key:_vm.restartAnimationKey + Math.random(),class:[
                    _vm.getAnimationClasses(
                      _vm.theme.banner.text.animation,
                      _vm.theme.banner.text.delay,
                      'banner-text'
                    ),
                  ],style:({ color: _vm.theme.banner.text.color }),attrs:{"id":"banner-text"}},[_vm._v(_vm._s(product.item.banner.text))])]):_vm._e(),_c('div',{staticClass:"swiper-slide-content preview-item",attrs:{"data-swiper-parallax":"10%"}},[_c('img',{staticStyle:{"width":"100%","height":"100%","object-fit":"cover"},attrs:{"src":product.item.images[0].url}}),_c('div',{staticClass:"wrapper-content"},[_c('div',[(product.item.title)?_c('div',{key:_vm.restartAnimationKey + Math.random(),staticClass:"swiper-slide-title",class:[
                    _vm.getAnimationClasses(
                      _vm.theme.content.title.animation,
                      _vm.theme.content.title.delay,
                      'theme-title'
                    ),
                  ],style:({ color: _vm.theme.content.title.color }),attrs:{"id":"theme-title"}},[_vm._v(" "+_vm._s(product.item.title)+" ")]):_vm._e(),(product.item.description)?_c('div',{key:_vm.restartAnimationKey + Math.random(),staticClass:"swiper-slide-text",class:[
                    _vm.getAnimationClasses(
                      _vm.theme.content.description.animation,
                      _vm.theme.content.description.delay,
                      'theme-description'
                    ),
                  ],style:({ color: _vm.theme.content.description.color }),attrs:{"id":"theme-description"},domProps:{"innerHTML":_vm._s(_vm.wrapString(product.item.description, 50))}}):_vm._e()]),_c('div',{staticClass:"prices black--text d-flex flex-column align-center"},[(product.item.price)?_c('span',{key:_vm.restartAnimationKey + Math.random(),staticClass:"price",class:[
                  _vm.getAnimationClasses(
                    _vm.theme.prices.price.animation,
                    _vm.theme.prices.price.delay,
                    'price'
                  ),
                ],style:({
                  color: _vm.theme.prices.price.color,
                }),attrs:{"id":"price"}},[_vm._v(" "+_vm._s(product.item.price)+" "),_c('span',{staticClass:"currency"},[_vm._v(" "+_vm._s(_vm.currency)+" ")])]):_vm._e(),(product.item.oldPrice)?_c('span',{key:_vm.restartAnimationKey + Math.random(),staticClass:"discount",class:[
                  _vm.getAnimationClasses(
                    _vm.theme.prices.oldPrice.animation,
                    _vm.theme.prices.oldPrice.delay,
                    'old-price'
                  ),
                ],style:({ color: _vm.theme.prices.oldPrice.color }),attrs:{"id":"old-price"}},[_vm._v(" "+_vm._s(product.item.oldPrice)+" "),_c('span',{staticClass:"currency"},[_vm._v(" "+_vm._s(_vm.currency)+" ")])]):_vm._e()])])])])}),1),_c('div',{staticClass:"swiper-pagination"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }