var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.activeProduct.item && Object.keys(_vm.theme).length)?_c('div',{staticClass:"creative-theme h-100",style:({ backgroundColor: _vm.theme.container.color || '#FFFFFF' })},[(false)?_c('div',{staticClass:"swiper-logo"},[_c('img',{staticClass:"object-cover rounded-circle",attrs:{"src":_vm.logo,"width":"50px","height":"50px","alt":"logo"}})]):_vm._e(),_c('div',{staticClass:"swiper-container",attrs:{"init":"false"}},[_c('div',{staticClass:"swiper-slide h-100 mx-0 preview-item d-flex"},[(
          _vm.activeProduct.item.title ||
          _vm.activeProduct.item.description ||
          _vm.activeProduct.item.price ||
          _vm.activeProduct.item.oldPrice
        )?_c('div',{staticClass:"wrapper-content"},[_c('div',{staticClass:"slide-content"},[(_vm.activeProduct.item.banner && _vm.activeProduct.item.banner.text)?_c('p',{staticClass:"custom-banner",style:({ backgroundColor: _vm.theme.banner.container.color }),attrs:{"id":"banner-container"}},[_c('span',{staticClass:"banner-font-size",attrs:{"data-product-id":_vm.activeProduct.item._id}},[_c('span',{staticClass:"banner-text",attrs:{"id":"banner-text"}},[_c('span',{style:({ color: _vm.theme.banner.text.color })},[_vm._v(_vm._s(_vm.activeProduct.item.banner.text))])])])]):_vm._e(),_c('div',{staticClass:"card-info theme-content",style:({ backgroundColor: _vm.theme.content.container.color })},[(_vm.activeProduct.item.title)?_c('div',{staticClass:"swiper-slide-title theme-title",attrs:{"id":"theme-title","data-product-id":_vm.activeProduct.item._id}},[_c('span',{style:({ color: _vm.theme.content.title.color })},[_vm._v(" "+_vm._s(_vm.activeProduct.item.title)+" ")])]):_vm._e(),(_vm.activeProduct.item.description)?_c('div',{staticClass:"swiper-slide-text theme-description",attrs:{"data-product-id":_vm.activeProduct.item._id}},[_c('span',{staticClass:"theme-content_description",style:({ color: _vm.theme.content.description.color }),attrs:{"id":"theme-description"}},[_vm._v(_vm._s(_vm.activeProduct.item.description))])]):_vm._e()]),(_vm.activeProduct.item.price || _vm.activeProduct.item.oldPrice)?_c('div',{staticClass:"prices black--text d-flex flex-wrap",style:({
              backgroundColor: _vm.theme.prices.container.color,
            })},[(_vm.activeProduct.item.price)?_c('span',{staticClass:"price theme-price",attrs:{"data-product-id":_vm.activeProduct.item._id}},[_c('span',{style:({
                  color: _vm.theme.prices.price.color,
                }),attrs:{"id":"price"}},[_vm._v(_vm._s(_vm.activeProduct.item.price)),_c('span',{staticClass:"currency ml-1"},[_vm._v(_vm._s(_vm.currency))])])]):_vm._e(),(_vm.activeProduct.item.oldPrice)?_c('span',{staticClass:"ma-0 ml-3 theme-old-price",attrs:{"data-product-id":_vm.activeProduct.item._id}},[_c('span',{staticClass:"discount",style:({ color: _vm.theme.prices.oldPrice.color }),attrs:{"id":"old-price"}},[_vm._v(_vm._s(_vm.activeProduct.item.oldPrice)),_c('span',{staticClass:"currency ml-1"},[_vm._v(_vm._s(_vm.currency))])])]):_vm._e()]):_vm._e()])]):_vm._e(),(_vm.activeProduct.item.show === 'video')?_c('div',{staticClass:"swiper-slide-content video-container",class:!_vm.activeProduct.item.title &&
          !_vm.activeProduct.item.description &&
          !_vm.activeProduct.item.price &&
          !_vm.activeProduct.item.oldPrice
            ? 'no-mask'
            : ''},[_c('video',{style:(`object-fit: ${_vm.activeProduct.item.video.videoShowType} !important;`),attrs:{"width":"100%","height":"100%","poster":_vm.activeProduct.item.video.thumbnail,"autoplay":"","muted":"","loop":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":_vm.activeProduct.item.video.url,"type":"video/mp4"}}),_vm._v(" Your browser does not support the video tag. ")])]):_vm._e(),(_vm.activeProduct.item.show === 'youtube')?_c('div',{staticClass:"swiper-slide-content video-container",class:!_vm.activeProduct.item.title &&
          !_vm.activeProduct.item.description &&
          !_vm.activeProduct.item.price &&
          !_vm.activeProduct.item.oldPrice
            ? 'no-mask'
            : ''},[(_vm.activeProduct.item.show === 'youtube')?_c('iframe',{attrs:{"src":_vm.extractedYoutubeEmbedUrl(_vm.activeProduct.item.links[0].url),"title":"YouTube video player","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share","allowfullscreen":""}}):_vm._e(),_c('div',{staticClass:"video-overlay"})]):(_vm.activeProduct.item.show === 'image')?_c('div',{staticClass:"swiper-slide-content",class:!_vm.activeProduct.item.title &&
          !_vm.activeProduct.item.description &&
          !_vm.activeProduct.item.price &&
          !_vm.activeProduct.item.oldPrice
            ? 'no-mask'
            : ''},[_c('img',{staticClass:"swiper-slide-image",style:(`object-fit: ${_vm.activeProduct.item.imageShowType} !important;`),attrs:{"src":_vm.activeProduct.item.images[0].url}})]):_vm._e()])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }