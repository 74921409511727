<template>
  <div
    v-if="activeProduct.item && Object.keys(theme).length"
    class="creative-theme h-100"
    :style="{ backgroundColor: theme.container.color || '#FFFFFF' }"
  >
    <div v-if="false" class="swiper-logo">
      <img
        :src="logo"
        width="50px"
        height="50px"
        class="object-cover rounded-circle"
        alt="logo"
      />
    </div>
    <div init="false" class="swiper-container">
      <div class="swiper-slide h-100 mx-0 preview-item d-flex">
        <div
          class="wrapper-content"
          v-if="
            activeProduct.item.title ||
            activeProduct.item.description ||
            activeProduct.item.price ||
            activeProduct.item.oldPrice
          "
        >
          <div class="slide-content">
            <p
              v-if="activeProduct.item.banner && activeProduct.item.banner.text"
              id="banner-container"
              :style="{ backgroundColor: theme.banner.container.color }"
              class="custom-banner"
            >
              <span
                class="banner-font-size"
                :data-product-id="activeProduct.item._id"
              >
                <span id="banner-text" class="banner-text">
                  <span :style="{ color: theme.banner.text.color }">{{
                    activeProduct.item.banner.text
                  }}</span>
                </span>
              </span>
            </p>
            <div
              :style="{ backgroundColor: theme.content.container.color }"
              class="card-info theme-content"
            >
              <div
                id="theme-title"
                class="swiper-slide-title theme-title"
                v-if="activeProduct.item.title"
                :data-product-id="activeProduct.item._id"
              >
                <span :style="{ color: theme.content.title.color }">
                  {{ activeProduct.item.title }}
                </span>
              </div>
              <div
                class="swiper-slide-text theme-description"
                :data-product-id="activeProduct.item._id"
                v-if="activeProduct.item.description"
              >
                <span
                  id="theme-description"
                  class="theme-content_description"
                  :style="{ color: theme.content.description.color }"
                  >{{ activeProduct.item.description }}</span
                >
              </div>
            </div>
            <div
              class="prices black--text d-flex flex-wrap"
              v-if="activeProduct.item.price || activeProduct.item.oldPrice"
              :style="{
                backgroundColor: theme.prices.container.color,
              }"
            >
              <span
                v-if="activeProduct.item.price"
                :data-product-id="activeProduct.item._id"
                class="price theme-price"
              >
                <span
                  id="price"
                  class=""
                  :style="{
                    color: theme.prices.price.color,
                  }"
                  >{{ activeProduct.item.price
                  }}<span class="currency ml-1">{{ currency }}</span>
                </span>
              </span>
              <span
                v-if="activeProduct.item.oldPrice"
                :data-product-id="activeProduct.item._id"
                class="ma-0 ml-3 theme-old-price"
              >
                <span
                  id="old-price"
                  class="discount"
                  :style="{ color: theme.prices.oldPrice.color }"
                  >{{ activeProduct.item.oldPrice
                  }}<span class="currency ml-1">{{ currency }}</span>
                </span>
              </span>
            </div>
          </div>
        </div>
        <div
          v-if="activeProduct.item.show === 'video'"
          class="swiper-slide-content video-container"
          :class="
            !activeProduct.item.title &&
            !activeProduct.item.description &&
            !activeProduct.item.price &&
            !activeProduct.item.oldPrice
              ? 'no-mask'
              : ''
          "
        >
          <video
            width="100%"
            height="100%"
            :style="`object-fit: ${activeProduct.item.video.videoShowType} !important;`"
            :poster="activeProduct.item.video.thumbnail"
            autoplay
            muted
            loop
          >
            <source :src="activeProduct.item.video.url" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        <div
          v-if="activeProduct.item.show === 'youtube'"
          class="swiper-slide-content video-container"
          :class="
            !activeProduct.item.title &&
            !activeProduct.item.description &&
            !activeProduct.item.price &&
            !activeProduct.item.oldPrice
              ? 'no-mask'
              : ''
          "
        >
          <iframe
            v-if="activeProduct.item.show === 'youtube'"
            :src="extractedYoutubeEmbedUrl(activeProduct.item.links[0].url)"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          <div class="video-overlay"></div>
        </div>

        <div
          v-else-if="activeProduct.item.show === 'image'"
          class="swiper-slide-content"
          :class="
            !activeProduct.item.title &&
            !activeProduct.item.description &&
            !activeProduct.item.price &&
            !activeProduct.item.oldPrice
              ? 'no-mask'
              : ''
          "
        >
          <img
            :src="activeProduct.item.images[0].url"
            :style="`object-fit: ${activeProduct.item.imageShowType} !important;`"
            class="swiper-slide-image"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "animate.css";
import { mapGetters } from "vuex";

export default {
  name: "CreativeTheme",
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    currency: {
      type: String,
      default: "",
      required: false,
    },
    logo: {
      type: String,
      default: "",
      required: false,
    },
    currentGroup: {
      type: Object,
      default: Object,
      required: false,
    },
    groups: {
      type: Array,
      default: () => [],
      required: false,
    },
    itemId: {
      type: String,
      default: "",
    },
    themeConfiguration: {
      type: Object,
      default: () => ({}),
    },
    theme: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    isLiveChangesPreview: {
      type: Boolean,
      default: false,
    },
    animationEffects: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      activeIndex: 0,
    };
  },
  computed: {
    ...mapGetters(["fetchedThemes"]),
    groupBgColor() {
      return this.currentGroup.groupId?.bg_color;
    },
    productsTimes() {
      return this.products.map((product) => Number(product.item.counter ?? 1));
    },
    activeProduct() {
      return this.products[0] || {};
    },
  },
};
</script>

<style scoped lang="scss">
.creative-theme {
  .swiper-container {
    // background-color: #d9d9d9;
    user-select: none;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    border-radius: 0px;
  }

  .swiper-slide-content {
    width: 60%;
    height: 100%;
    flex-grow: 1;
    display: flex;
    z-index: 2;
    align-items: center;
    text-align: start;
    color: #000;

    &:not(.no-mask) {
      mask-image: url("../../assets/images/themes/creative_theme_mask2.svg");
      mask-type: alpha;
      mask-mode: alpha;
      mask-position: 0%;
      mask-size: cover;
      mask-repeat: no-repeat;
    }

    .swiper-slide-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .wrapper-content {
    width: 40%;
    height: 100%;
    // position: absolute;
    // left: 10%;
    // top: 50%;
    // transform: translateY(-50%);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    // z-index: 100;
    box-shadow: none;
    flex-grow: 1;
    z-index: 1;

    .slide-content {
      // background: #d9d9d9;
      width: 150%;
      height: 75%;
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .custom-banner,
      .card-info,
      .prices {
        padding: 5px 50px;
        padding-right: 35%;
      }
    }
  }

  .price {
    // display: inline-block;
    font-size: 1.5rem;
    font-weight: bold;
    margin-right: 10px;
  }

  .custom-banner {
    padding: 10px 60px;
    background: #fdd039;
    color: #000;
    text-align: center;
    width: 100%;
    height: 15%;
    font-weight: 500;
    line-height: 1.3;
    word-break: break-word;
    justify-content: start;
  }
  .swiper-logo {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 100;
  }

  .prices {
    height: 35%;
    width: 100%;
    display: flex;
    justify-content: flex-start !important;
    align-items: center;
    flex-grow: 1;
    margin-top: auto;
  }
  .discount {
    overflow: hidden;
  }
  .card-info {
    width: 100%;
    height: 50%;
    flex-grow: 1;
    text-align: left;
    // margin-bottom: 3%;
  }

  .video-container {
    position: relative;
    // top: 0;
    // left: 0;
    // width: 100vw;
    // height: 100vh;
    z-index: 1;
    transform: none;

    iframe {
      width: 100%;
      height: 100%;
    }

    .video-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: transparent;
    }
  }
}
</style>
