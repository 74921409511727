<template>
  <div>
    <div v-if="false" class="swiper-logo">
      <img
        :src="logo"
        width="50px"
        height="50px"
        class="object-cover rounded-circle"
        alt="logo"
      />
    </div>
    <swiper-container
      init="false"
      ref="swiper"
      class="relative swiper-container"
      :style="{ backgroundColor: theme.container.color || '#FFFFFF' }"
    >
      <swiper-slide v-for="product in products" :key="product._id">
        <div class="swiper-slide-content preview-item">
          <div
            class="swiper-slide-image"
            :style="{
              height:
                !product.item.title &&
                !product.item.description &&
                !product.item.price &&
                !product.item.oldPrice
                  ? '100%'
                  : '',
            }"
          >
            <img
              :src="product.item.images[0].url"
              style="height: 100%; width: 100%; object-fit: fill"
            />
          </div>

          <span
            class="banner custom-banner"
            v-if="product.item.banner && product.item.banner.text"
              :style="{ backgroundColor: theme.banner.container.color }"
              id="banner-container"
              :key="restartAnimationKey + Math.random()"
              :class="[
                getAnimationClasses(
                  null,
                  theme.banner.container.delay,
                  'banner-container'
                ),
              ]"          >
            <span
              v-text-fit="{
                minFont: 10,
                maxFont: 512,
                maxHeightByPercent: 100,
              }"
            >
              <span :style="{ color: theme.banner.text.color }"
                    id="banner-text"
                  :key="restartAnimationKey + Math.random()" :class="[
                    getAnimationClasses(
                      theme.banner.text.animation,
                      theme.banner.text.delay,
                      'banner-text'
                    ),
                  ]">{{product.item.banner.text }}</span>
            </span>
          </span>
          <div
            class="wrapper-content"
            v-if="
              product.item.title ||
              product.item.description ||
              product.item.price ||
              product.item.oldPrice
            "
          >
            <div :style="{ backgroundColor: theme.content.container.color }" class="card-info theme-content h-100">
              <div
                class="swiper-slide-title"
                v-if="product.item.title"
                :style="{ color: theme.content.title.color }"
                  id="theme-title"
                  :key="restartAnimationKey + Math.random()"
                  :class="[
                    getAnimationClasses(
                      theme.content.title.animation,
                      theme.content.title.delay,
                      'theme-title'
                    ),
                  ]"                v-html="wrapString(product.item.title, 30)"
                v-text-fit="{
                  minFont: 10,
                  maxFont: 512,
                  maxHeightByPercent: 70,
                  checkHasSibling: true,
                }"
              ></div>
              <div
                class="swiper-slide-text"
                v-if="product.item.description"
                :style="{ color: theme.content.description.color }"
                  id="theme-description"
                  :key="restartAnimationKey + Math.random()"

                  :class="[
                    getAnimationClasses(
                      theme.content.description.animation,
                      theme.content.description.delay,
                      'theme-description'
                    ),
                  ]"

                v-html="wrapString(product.item.description, 50)"
                v-text-fit="{
                  minFont: 10,
                  maxFont: 512,
                  maxHeightByPercent: 30,
                }"
              ></div>
            </div>
            <div
              class="prices black--text"
              v-if="product.item.price || product.item.oldPrice"
              :style="{
                backgroundColor: theme.prices.container.color,
              }"
            >
              <span
                v-if="product.item.price"
                :style="{
                  color: theme.prices.price.color,
                }"
                id="price"
                :key="restartAnimationKey + Math.random()"
                :class="[
                  getAnimationClasses(
                    theme.prices.price.animation,
                    theme.prices.price.delay,
                    'price'
                  ),
                ]"

                class="price px-0 mx-0"
                v-text-fit="{
                  minFont: 10,
                  maxFont: 512,
                  maxHeightByPercent: 70,
                  checkHasSibling: true,
                  quarterChild: true,
                }"
              >
                {{ product.item.price }}
                <span class="currency">
                  {{ currency }}
                </span>
              </span>
              <span
                v-if="product.item.oldPrice"
                :style="{ color: theme.prices.oldPrice.color }"
                id="old-price"
                :key="restartAnimationKey + Math.random()"
                :class="[
                  getAnimationClasses(
                    theme.prices.oldPrice.animation,
                    theme.prices.oldPrice.delay,
                    'old-price'
                  ),
                ]"

                class="discount ma-0"
                v-text-fit="{
                  minFont: 10,
                  maxFont: 512,
                  maxHeightByPercent: 30,
                  quarterChild: true,
                }"
              >
                {{ product.item.oldPrice }}
                <span class="currency">
                  {{ currency }}
                </span>
              </span>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper-container>
  </div>
</template>
<script>
export default {
  name: "FourSlides",
  props: {
    groupBgColor: {
      type: String,
      default: "",
      required: false,
    },
    products: {
      type: Array,
      default: () => [],
    },
    currency: {
      type: String,
      default: "",
      required: false,
    },
    logo: {
      type: String,
      default: "",
      required: false,
    },
    currentGroup: {
      type: Object,
      default: Object,
      required: false,
    },
        themeConfiguration: {
      type: Object,
      default: () => ({}),
    },
    theme: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    isLiveChangesPreview: {
      type: Boolean,
      default: false,
    },
    animationEffects: {
      type: Array,
      required: true,
    }
  },

  data() {
    return {
      restartAnimationKey: 0,
      endCalculateFont: false,
      slidesPerView: 2,
    };
  },
  computed: {
    productsTimes() {
      return this.products.map((product) => Number(product.item.counter ?? 1));
    },
  },
  mounted() {
    // initialize swiper
    this.initSwiper();
    // when a slide contains products with a length that fits within a single slide, wait for the calculated time then go the next group
    // when the user switch to another tab or window, don't trigger the next group and wait until the user come back
    if (this.products.length <= this.slidesPerView) {
      let handleNextGroupTimeout = setTimeout(() => {
        this.handleNextGroup();
      }, this.calcTime(this.activeIndex, this.productsTimes, 3));

      document.addEventListener("visibilitychange", () => {
        if (document.visibilityState === "hidden") {
          clearTimeout(handleNextGroupTimeout);
        } else if (document.visibilityState === "visible") {
          handleNextGroupTimeout = setTimeout(() => {
            this.handleNextGroup();
          }, this.calcTime(this.activeIndex, this.productsTimes, 2));
        }
      });
    }
  },
  methods: {
    getAnimationClasses(animationName, animationDelay, id) {
      if (!animationName) {
        return "";
      }

      const animationDelayClass = animationDelay
        ? `animate__delay-${animationDelay}s`
        : "";

      const animationClass = this.animationEffects.find(
        (effect) => effect.name === animationName
      )?.class;

      this.$nextTick(() => {
        if (id) {
          let el = document.querySelector(`#${id}`);

          setTimeout(() => {
            el.classList.remove("opacity-0");
          }, Number(animationDelay) * 1000 - 100);
        }
      });

      return `animate__animated ${animationClass} ${animationDelayClass} opacity-0`;
    },
    initSwiper() {
      //console.log("mounted", this.products);
      const swiperEl = this.$refs.swiper;
      // const swiperParams = {
      //   slidesPerView: 2,
      //   observeParents: true,
      //   grabCursor: true,
      //   observer: true,
      //   threshold: 5,
      //   spaceBetween: 20,
      //   initialSlide: 0,
      //   grid: { rows: 2 },
      //   watchSlidesProgress: true,
      //   speed: this.$store.getters["getSliderGroupTotalTime"] / this.products.length,
      //   keyboard: { enabled: true },
      //   slidesPerGroup: 2,
      //   virtual: false,
      //   autoplay: {
      //     delay: this.calcTime(this.activeIndex, this.productsTimes, 2),
      //     enable: true,
      //     disableOnInteraction: false,
      //   },
      //   on: {
      //     slideChange: () => {
      //       // check if we need to go to next group
      //       this.handleNextGroup();
      //     },
      //   },
      // };

      const swiperParams = this.themeConfiguration.swiperParams({
        speed: this.totalTime / this.products.length,
        delay: this.calcTime(this.activeIndex, this.productsTimes, 2),
        handleNextGroup: this.handleNextGroup,
      });

      Object.assign(swiperEl, swiperParams);
      // and now initialize it
      this.$nextTick(() => {
        swiperEl.initialize();

        // stop slider autoplay when user switch to another tab or window
        document.addEventListener("visibilitychange", () => {
          if (document.visibilityState === "hidden") {
            this.$refs.swiper && this.$refs.swiper.swiper.autoplay.stop();
          } else {
            this.$refs.swiper && this.$refs.swiper.swiper.autoplay.start();
          }
        });
      });
    },
    handleNextGroup() {
      if (this.activeIndex == Math.ceil(this.products.length / 2) - 1) {
        this.activeIndex = 0;
        this.$emit("goNext");
      } else {
        this.activeIndex += 1;
      }
      // set delay for next slide
      if (this.$refs.swiper) {
        this.$refs.swiper.swiper.params.autoplay.delay = this.calcTime(
          this.activeIndex,
          this.productsTimes,
          3
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
swiper-container {
  user-select: none;
  box-sizing: border-box;
  overflow-x: hidden;
  width: 100%;
  height: 100vh;
  padding-top: 32px;
  padding-bottom: 32px;
}
swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc((100% - 20px) / 2);
  color: #fff;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  background-color: rgba(51, 51, 51, 1);
  border-radius: 12px;
}

.swiper-slide-content {
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  color: #000;
}
.card-info {
  width: 65%;
  line-height: 1.4;
  word-break: break-word;
}
.prices {
  width: 30% !important;
}

.swiper-slide-image {
  position: absolute;
  object-fit: cover;
  left: 0%;
  top: 0%;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.wrapper-content {
  position: absolute;
  bottom: 0;
  display: flex;
  background-color: rgba(255, 255, 255, 0.94);
  width: 100%;
  height: calc(30% + 20px);
  padding: 10px;
  text-align: left;
  justify-content: space-between;
}
.card-info {
  display: flex;
  flex-direction: column;
}
.swiper-logo {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 100;
}
.swiper-slide-image {
  width: 100%;
  height: calc(100% - (30% + 20px));
}
.prices {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fdd039;
  overflow: hidden;
}
.custom-banner {
  background-color: #fdd039;
  color: #000;
  border-radius: 14px;
  padding: 4px 12px;
  word-break: break-word;
  font-weight: 500;
  height: 10%;
  display: flex;
  span {
    line-height: 1.2 !important;
  }
}
.prices {
  display: flex;
  flex-direction: column;
  align-items: end;
  text-align: end;
}
.price {
  width: 100%;
}
</style>
