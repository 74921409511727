<template>
  <div
    class="theme h-100 d-flex justify-center align-center"
    :style="{ backgroundColor: theme.container.color || '#FFFFFF' }"
  >
    <div v-if="false" class="swiper-logo">
      <img
        :src="logo"
        width="50px"
        height="50px"
        class="object-cover rounded-circle"
        alt="logo"
      />
    </div>
    <swiper-container init="false" ref="swiper" class="swiper-container">
      <swiper-slide
        v-for="product in products"
        :key="product._id"
        class="h-100"
      >
        <span
          class="banner custom-banner"
          v-if="product.item.banner && product.item.banner.text"
          :style="{ backgroundColor: theme.banner.container.color }"
          id="banner-container"
          :key="restartAnimationKey + Math.random()"
          :class="[
            getAnimationClasses(
              null,
              theme.banner.container.delay,
              'banner-container'
            ),
          ]"
        >
          <span
            ref="banner"
            v-text-fit="{
              minFont: 10,
              maxFont: 512,
              maxHeightByPercent: 100,
            }"
          >
            <span
              :style="{ color: theme.banner.text.color }"
              id="banner-text"
              :key="restartAnimationKey + Math.random()"
              :class="[
                getAnimationClasses(
                  theme.banner.text.animation,
                  theme.banner.text.delay,
                  'banner-text'
                ),
              ]"
              >{{ product.item.banner.text }}</span
            >
          </span>
        </span>
        <div class="swiper-slide-content preview-item">
          <div
            v-if="product.item.show === 'youtube'"
            class="swiper-slide-content video-container"
          >
            <iframe
              :src="extractedYoutubeEmbedUrl(product.item.links[0].url)"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
            <div class="video-overlay"></div>
          </div>

          <div
            v-else-if="product.item.show === 'image'"
            class="wrapper-image"
            :style="{
              height:
                !product.item.title &&
                !product.item.description &&
                !product.item.price &&
                !product.item.oldPrice
                  ? '100%'
                  : '',
            }"
          >
            <img :src="product.item.images[0].url" class="swiper-slide-image" />
          </div>

          <div
            class="wrapper-content"
            v-if="
              product.item.title ||
              product.item.description ||
              product.item.price ||
              product.item.oldPrice
            "
          >
            <div
              :style="{ backgroundColor: theme.content.container.color }"
              class="card-info theme-content"
            >
              <div
                class="swiper-slide-title w-100"
                v-html="wrapString(product.item.title, 30)"
                v-if="product.item.title"
                :style="{ color: theme.content.title.color }"
                id="theme-title"
                :key="restartAnimationKey + Math.random()"
                :class="[
                  getAnimationClasses(
                    theme.content.title.animation,
                    theme.content.title.delay,
                    'theme-title'
                  ),
                ]"
                v-textFit="{
                  minFont: 15,
                  maxFont: 512,
                  maxHeightByPercent: 70,
                  checkHasSibling: true,
                }"
              ></div>
              <div
                class="swiper-slide-text"
                v-html="wrapString(product.item.description, 50)"
                v-if="product.item.description"
                :style="{ color: theme.content.description.color }"
                id="theme-description"
                :key="restartAnimationKey + Math.random()"
                :class="[
                  getAnimationClasses(
                    theme.content.description.animation,
                    theme.content.description.delay,
                    'theme-description'
                  ),
                ]"
                v-textFit="{
                  minFont: 15,
                  maxFont: 512,
                  maxHeightByPercent: 25,
                }"
              ></div>
            </div>
            <div
              class="prices black--text pl-6"
              v-if="product.item.price || product.item.oldPrice"
              :style="{
                backgroundColor: theme.prices.container.color,
              }"
            >
              <span
                v-if="product.item.price"
                :style="{
                  color: theme.prices.price.color,
                }"
                id="price"
                :key="restartAnimationKey + Math.random()"
                :class="[
                  getAnimationClasses(
                    theme.prices.price.animation,
                    theme.prices.price.delay,
                    'price'
                  ),
                ]"
                class="price mx-0"
                v-textFit="{
                  minFont: 15,
                  maxFont: 512,
                  maxHeightByPercent: 70,
                  checkHasSibling: true,
                  quarterChild: true,
                }"
              >
                {{ product.item.price }}
                <span class="currency pr-1">
                  {{ currency }}
                </span>
              </span>
              <span
                v-if="product.item.oldPrice"
                :style="{ color: theme.prices.oldPrice.color }"
                id="old-price"
                :key="restartAnimationKey + Math.random()"
                :class="[
                  getAnimationClasses(
                    theme.prices.oldPrice.animation,
                    theme.prices.oldPrice.delay,
                    'old-price'
                  ),
                ]"
                class="ma-0"
                v-textFit="{
                  minFont: 15,
                  maxFont: 512,
                  maxHeightByPercent: 30,
                  quarterChild: true,
                }"
              >
                {{ product.item.oldPrice }}
                <span class="currency">
                  {{ currency }}
                </span>
              </span>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper-container>

    <div class="swiper-pagination"></div>
  </div>
</template>
<script>
export default {
  name: "CoverFlow",
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    currency: {
      type: String,
      default: "",
      required: false,
    },
    logo: {
      type: String,
      default: "",
      required: false,
    },
    currentGroup: {
      type: Object,
      default: Object,
      required: false,
    },
    themeConfiguration: {
      type: Object,
      default: () => ({}),
    },
    theme: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    isLiveChangesPreview: {
      type: Boolean,
      default: false,
    },
    animationEffects: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      restartAnimationKey: 0,
      endCalculateFont: false,
      slidesPerView: 2,
      activeIndex: 0,
    };
  },
  computed: {
    groupBgColor() {
      return this.currentGroup.groupId?.bg_color || "#fff";
    },
    productsTimes() {
      return this.products.map((product) => Number(product.item.counter ?? 1));
    },
  },
  mounted() {
    // initialize swiper
    this.initSwiper();

    // when a slide contains products with a length that fits within a single slide, wait for the calculated time then go the next group
    // when the user switch to another tab or window, don't trigger the next group and wait until the user come back
    if (this.products.length <= this.slidesPerView) {
      let handleNextGroupTimeout = setTimeout(() => {
        this.handleNextGroup();
      }, this.calcTime(this.activeIndex, this.productsTimes, 3));

      document.addEventListener("visibilitychange", () => {
        if (document.visibilityState === "hidden") {
          clearTimeout(handleNextGroupTimeout);
        } else if (document.visibilityState === "visible") {
          handleNextGroupTimeout = setTimeout(() => {
            this.handleNextGroup();
          }, this.calcTime(this.activeIndex, this.productsTimes, 2));
        }
      });
    }
  },
  methods: {
    handleNextGroup() {
      if (this.activeIndex == this.products.length - 1) {
        this.activeIndex = 0;
        this.$emit("goNext");
      } else {
        this.activeIndex += 1;
      }
      // set delay for next slide
      if (this.$refs.swiper) {
        this.$refs.swiper.swiper.params.autoplay.delay = this.calcTime(
          this.activeIndex,
          this.productsTimes,
          1
        );
      }
    },

    getAnimationClasses(animationName, animationDelay, id) {
      if (!animationName) {
        return "";
      }

      const animationDelayClass = animationDelay
        ? `animate__delay-${animationDelay}s`
        : "";

      const animationClass = this.animationEffects.find(
        (effect) => effect.name === animationName
      )?.class;

      this.$nextTick(() => {
        if (id) {
          let el = document.querySelector(`#${id}`);

          setTimeout(() => {
            el.classList.remove("opacity-0");
          }, Number(animationDelay) * 1000 - 100);
        }
      });

      return `animate__animated ${animationClass} ${animationDelayClass} opacity-0`;
    },
    initSwiper() {
      this.totalTime = 4000;
      const swiperEl = this.$refs.swiper;
      // const swiperParams = {
      //   autoplay: {
      //     enabled: true,
      //     delay: this.calcTime(this.activeIndex, this.productsTimes, 1),
      //   },
      //   initialSlide: 0,
      //   slidesPerGroupAuto: false,
      //   parallax: { enabled: true },
      //   effect: "coverflow",
      //   slidesPerView: 2,
      //   watchSlidesProgress: true,
      //   centeredSlides: true,
      //   pagination: {
      //     dynamicMainBullets: 3,
      //     dynamicBullets: true,
      //     el: ".swiper-pagination",
      //   },
      //   speed: this.$store.getters["getSliderGroupTotalTime"] / this.products.length,
      //   virtual: false,
      //   on: {
      //     slideChange: () => {
      //       // check if we need to go to next group
      //       this.handleNextGroup();
      //     },
      //   },
      // };

      const swiperParams = this.themeConfiguration.swiperParams({
        speed: this.totalTime / this.products.length,
        delay: this.calcTime(this.activeIndex, this.productsTimes, 1),
        handleNextGroup: this.handleNextGroup,
      });

      // now we need to assign all parameters to Swiper element
      Object.assign(swiperEl, swiperParams);
      // and now initialize it
      this.$nextTick(() => {
        swiperEl.initialize();

        // stop slider autoplay when user switch to another tab or window
        document.addEventListener("visibilitychange", () => {
          if (document.visibilityState === "hidden") {
            this.$refs.swiper && this.$refs.swiper.swiper.autoplay.stop();
          } else {
            this.$refs.swiper && this.$refs.swiper.swiper.autoplay.start();
          }
        });
      });
    },
  },
  // mounted() {
  //   this.$emit("initializeSwiper", this.$refs.swiper, this.swiperParams);
  // },
};
</script>
<style lang="scss" scoped>
swiper-container {
  user-select: none;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 78% !important;
}

swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  // width: 60% !important;
  //height: 78% !important;
  color: #fff;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  background-color: rgba(51, 51, 51, 1);
  border-radius: 40px;
}

.swiper-slide-content {
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  color: #000;
}

.wrapper-content {
  position: absolute;
  display: flex;
  background-color: rgba(255, 255, 255, 0.94);
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  height: calc(100% - 75.8%);
  bottom: 0;
}
.card-info {
  width: calc(100% - 23.5%);
  height: 100%;
  text-align: left;
  padding: 15px;
}
.swiper-pagination {
  bottom: 20px;
}
.swiper-logo {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 100;
}
.wrapper-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 75.8%;
  z-index: 0;
}
.swiper-slide-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-slide-text {
  display: flex;
  align-items: center;
}
.price {
  width: 100%;
  display: block;
}
.discount {
  width: max-content;
}
.prices {
  width: 23.5% !important;
  height: 100%;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  text-align: center;
}
.custom-banner {
  border-radius: 14px;
  padding: 4px 12px;
  word-break: break-word;
  font-weight: 500;
  height: 10%;
  line-height: 1.3;
}
</style>
