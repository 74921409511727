<template>
  <div
    class="theme fullscreen-fade"
    v-if="activeProduct.item && Object.keys(theme).length"
  >
    <div v-if="false" class="swiper-logo">
      <img
        :src="logo"
        width="50px"
        height="50px"
        class="object-cover rounded-circle"
        alt="logo"
      />
    </div>
    <div ref="swiper" class="swiper-container">
      <div class="swiper-slide h-100 mx-0 preview-item">
        <div
          v-if="activeProduct.item.show === 'video'"
          class="swiper-slide-content video-container"
        >
          <video
            width="100%"
            height="100%"
            :style="`object-fit: ${activeProduct.item.video.videoShowType} !important;`"
            :poster="activeProduct.item.video.thumbnail"
            autoplay
            muted
            loop
          >
            <source :src="activeProduct.item.video.url" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        <div
          v-if="activeProduct.item.show === 'youtube'"
          class="swiper-slide-content video-container"
        >
          <iframe
            :src="extractedYoutubeEmbedUrl(activeProduct.item.links[0].url)"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          <div class="video-overlay"></div>
        </div>

        <div
          v-else-if="activeProduct.item.show === 'image'"
          class="swiper-slide-content"
        >
          <img
            :src="activeProduct.item.images[0].url"
            :style="`object-fit: ${activeProduct.item.imageShowType} !important;`"
            class="swiper-slide-image"
          />
        </div>

        <div
          class="wrapper-content"
          v-if="
            activeProduct.item.title ||
            activeProduct.item.description ||
            activeProduct.item.price ||
            activeProduct.item.oldPrice
          "
        >
          <!-- :style="{ backgroundColor: theme.container.color }" -->
          <div class="slide-content">
            <p
              v-if="activeProduct.item.banner && activeProduct.item.banner.text"
              :style="{ backgroundColor: theme.banner.container.color }"
              id="banner-container"
              class="custom-banner"
            >
              <span
                class="banner-font-size"
                :data-product-id="activeProduct.item._id"
              >
                <span
                  id="banner-text"
                  class="banner-text h-100"
                  :style="{ color: theme.banner.text.color }"
                  >{{ activeProduct.item.banner.text }}</span
                >
              </span>
            </p>
            <div
              :style="{ backgroundColor: theme.content.container.color }"
              class="card-info theme-content"
            >
              <div
                v-if="activeProduct.item.title"
                :data-product-id="activeProduct.item._id"
                class="swiper-slide-title theme-title"
                :style="`--textLength: ${activeProduct.item.title.length}`"
              >
                <span
                  :style="{ color: theme.content.title.color }"
                  id="theme-title"
                >
                  {{ activeProduct.item.title }}
                </span>
              </div>
              <div
                class="swiper-slide-text theme-description"
                v-if="activeProduct.item.description"
                :data-product-id="activeProduct.item._id"
                :style="`--textLength: ${activeProduct.item.description.length}`"
              >
                <span
                  :style="{ color: theme.content.description.color }"
                  id="theme-description"
                  >{{ activeProduct.item.description }}</span
                >
              </div>
            </div>
            <div
              class="prices black--text d-flex"
              v-if="activeProduct.item.price || activeProduct.item.oldPrice"
              :style="{
                backgroundColor: theme.prices.container.color,
              }"
            >
              <span
                v-if="activeProduct.item.price"
                :style="{
                  color: theme.prices.price.color,
                }"
                id="price"
                :data-product-id="activeProduct.item._id"
                class="price theme-price mx-0"
              >
                <span>
                  {{ activeProduct.item.price }}
                  <span class="currency h-100">
                    {{ currency }}
                  </span>
                </span>
              </span>
              <span
                v-if="activeProduct.item.oldPrice"
                :data-product-id="activeProduct.item._id"
                :style="{ color: theme.prices.oldPrice.color }"
                id="old-price"
                class="discount ma-0 theme-old-price"
              >
                <span>
                  {{ activeProduct.item.oldPrice }}
                  <span class="currency">
                    {{ currency }}
                  </span>
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Full_screen_fade",
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    currency: {
      type: String,
      default: "",
      required: false,
    },
    logo: {
      type: String,
      default: "",
      required: false,
    },
    currentGroup: {
      type: Object,
      default: Object,
      required: false,
    },
    groups: {
      type: Array,
      default: () => [],
      required: false,
    },
    itemId: {
      type: String,
      default: "",
    },
    themeConfiguration: {
      type: Object,
      default: () => ({}),
    },
    theme: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    isLiveChangesPreview: {
      type: Boolean,
      default: false,
    },
    animationEffects: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    groupBgColor() {
      return this.currentGroup.groupId?.bg_color;
    },
    productsTimes() {
      return this.products.map((product) => Number(product.item.counter ?? 1));
    },
    activeProduct() {
      return this.products[0] || {};
    },
  },
};
</script>

<style scoped lang="scss">
.swiper-container {
  height: 100vh;
  user-select: none;
  box-sizing: border-box;

  padding-top: 0px;
  padding-bottom: 0px;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  border-radius: 0px;
}

.swiper-slide-content {
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1;
  align-items: center;
  text-align: start;
  color: #000;
}

// .swiper-slide-title {
//   // formula to calculate minimum font size where x is the length of the text
//   // calc(-130/11 * (var(--textLength) / 5) + (2990/11) * 1px);

//   --minFontSize: 20px;
//   --maxFontSize: 500px;
//   --baseSize: 35vw;

//   font-size: clamp(
//     var(--minFontSize),
//     calc(var(--baseSize) / var(--textLength)),
//     var(--maxFontSize)
//   );

//   @media (min-width: 3000px) {
//     --minFontSize: 130px;
//     --maxFontSize: 260px;
//   }
// }

// .swiper-slide-text,
// .custom-banner {
//   --minFontSize: 16px;
//   --maxFontSize: 250px;
//   --baseSize: 18vw;

//   font-size: clamp(
//     var(--minFontSize),
//     calc(var(--baseSize) / var(--textLength)),
//     var(--maxFontSize)
//   );

//   @media (min-width: 3000px) {
//     --minFontSize: 40px;
//     --maxFontSize: 300px;
//   }

//   @media (min-width: 4500px) {
//     --minFontSize: 60px;
//     --maxFontSize: 300px;
//   }
// }

.wrapper-content {
  width: 100%;
  height: 60%;
  position: absolute;
  left: 10%;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 100;
  box-shadow: none;
}
.slide-content {
  background: #d9d9d9;
  // padding: 20px 30px;
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  // @media (max-width: 900px) {
  //   padding: 15px 15px;
  // }
}

// .price {
//   font-size: 1.5rem;
//   font-weight: bold;
//   margin-right: 10px;

//   --minFontSize: 16px;
//   --maxFontSize: 500px;
//   --baseSize: 5.5vw;
//   font-size: clamp(var(--minFontSize), var(--baseSize), var(--maxFontSize));

//   .currency {
//     display: inline-block;
//     font-size: clamp(
//       calc(var(--minFontSize) / 2),
//       calc(var(--baseSize) / 3),
//       calc(var(--maxFontSize) / 2)
//     );
//   }
// }

// .discount {
//   --minFontSize: 16px;
//   --maxFontSize: 250px;
//   --baseSize: 3vw;
//   font-size: clamp(var(--minFontSize), var(--baseSize), var(--maxFontSize));

//   .currency {
//     display: inline-block;
//     font-size: clamp(
//       calc(var(--minFontSize) / 2),
//       calc(var(--baseSize) / 3),
//       calc(var(--maxFontSize) / 2)
//     );
//   }
// }

.custom-banner {
  width: 100%;
  height: 15%;
  background: #fdd039;
  color: #000;
  padding: 10px 60px;
  margin-bottom: 0;
  text-align: center;
  font-weight: 500;
  line-height: 1.3;
  word-break: break-word;

  @media screen and (max-width: 950px) {
    padding: 10px 15px;
  }
}
.swiper-logo {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 100;
}

.swiper-slide-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.prices {
  height: 35%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fdd039;
  margin-top: auto;

  .price {
    text-align: center;
  }
}
.discount {
  width: max-content;
}
.card-info {
  width: 100%;
  height: 50%;
  flex-grow: 1;
  text-align: left;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
}

.card-info,
.prices,
.custom-banner {
  padding: 5px 30px;
}

.vertical {
  .swiper-slide {
    flex-direction: column-reverse;

    .wrapper-content {
      position: relative !important;
      left: 0 !important;
      top: 0 !important;
      transform: none !important;
      height: 40%;

      .slide-content {
        width: 100% !important;
        height: 100% !important;

        .prices {
          flex-direction: row;
        }
      }
    }

    .swiper-slide-content {
      height: 60%;
    }
  }
}

@media (max-width: $breakpoint-md) {
  .swiper-slide {
    flex-direction: column-reverse;

    .wrapper-content {
      position: relative !important;
      left: 0 !important;
      top: 0 !important;
      transform: none !important;
      height: 40%;

      .slide-content {
        width: 100% !important;
        height: 100% !important;

        .prices {
          flex-direction: row;
        }
      }
    }

    .swiper-slide-content {
      height: 60%;
      flex-grow: 1;
    }
  }
}
</style>
