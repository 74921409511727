<template>
  <div class="w-100 h-100" v-if="activeProduct.item">
    <div v-if="false" class="swiper-logo">
      <img
        :src="logo"
        width="50px"
        height="50px"
        class="object-cover rounded-circle"
        alt="logo"
      />
    </div>
    <div ref="swiper" init="false" class="swiper-container">
      <div class="swiper-slide h-100 preview-item">
        <div
          v-if="activeProduct.item.show === 'youtube'"
          class="swiper-slide-content video-container"
        >
          <iframe
            :src="extractedYoutubeEmbedUrl(activeProduct.item.links[0].url)"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          <div class="video-overlay"></div>
        </div>

        <div
          class="swiper-slide-content"
          v-else-if="activeProduct.item.show === 'image'"
            :style="`object-fit: ${activeProduct.item.imageShowType} !important;`"
        >
          <img
            :src="activeProduct.item.images[0].url"
            class="swiper-slide-image"
          />
        </div>

        <div
          class="wrapper-content"
          v-if="
            activeProduct.item.title ||
            activeProduct.item.description ||
            activeProduct.item.price ||
            activeProduct.item.oldPrice
          "
        >
          <div
            class="slide-content"
            :style="{ backgroundColor: theme.container.color }"
          >
            <p
              class="custom-banner"
              v-if="activeProduct.item.banner && activeProduct.item.banner.text"
              :style="{ backgroundColor: theme.banner.container.color }"
              id="banner-container"
              :key="restartAnimationKey + Math.random()"
              :class="[
                getAnimationClasses(
                  null,
                  theme.banner.container.delay,
                  'banner-container'
                ),
              ]"
            >
              <span
                v-text-fit="{
                  minFont: 10,
                  maxFont: 512,
                  maxHeightByPercent: 100,
                }"
              >
                <span
                  :style="{ color: theme.banner.text.color }"
                  id="banner-text"
                  :key="restartAnimationKey + Math.random()"
                  :class="[
                    getAnimationClasses(
                      theme.banner.text.animation,
                      theme.banner.text.delay,
                      'banner-text'
                    ),
                  ]"
                  >{{ activeProduct.item.banner.text }}</span
                >
              </span>
            </p>
            <div
              :style="{ backgroundColor: theme.content.container.color }"
              class="card-info theme-content"
            >
              <div
                class="swiper-slide-title"
                ref="titles"
                v-if="activeProduct.item.title"
                :style="{ color: theme.content.title.color }"
                id="theme-title"
                :key="restartAnimationKey + Math.random()"
                :class="[
                  getAnimationClasses(
                    theme.content.title.animation,
                    theme.content.title.delay,
                    'theme-title'
                  ),
                ]"
                v-textFit="{
                  minFont: 10,
                  maxFont: 512,
                  maxHeightByPercent: 70,
                  checkHasSibling: true,
                }"
              >
                {{ activeProduct.item.title }}
              </div>
              <div
                class="swiper-slide-text"
                ref="descriptions"
                v-if="activeProduct.item.description"
                :style="{ color: theme.content.description.color }"
                id="theme-description"
                :key="restartAnimationKey + Math.random()"
                :class="[
                  getAnimationClasses(
                    theme.content.description.animation,
                    theme.content.description.delay,
                    'theme-description'
                  ),
                ]"
                v-html="wrapString(activeProduct.item.description, 50)"
                v-textFit="{
                  minFont: 10,
                  maxFont: 512,
                  maxHeightByPercent: 30,
                }"
              ></div>
            </div>
            <div
              class="prices black--text d-flex"
              v-if="activeProduct.item.price || activeProduct.item.oldPrice"
              :style="{
                backgroundColor: theme.prices.container.color,
              }"
            >
              <span
                class="price"
                ref="prices"
                v-if="activeProduct.item.price"
                :style="{
                  color: theme.prices.price.color,
                }"
                id="price"
                :key="restartAnimationKey + Math.random()"
                :class="[
                  getAnimationClasses(
                    theme.prices.price.animation,
                    theme.prices.price.delay,
                    'price'
                  ),
                ]"
                v-textFit="{
                  minFont: 15,
                  maxFont: 512,
                  maxHeightByPercent: 70,
                  checkHasSibling: true,
                  quarterChild: true,
                }"
              >
                {{ activeProduct.item.price }}
                <span class="currency">
                  {{ currency }}
                </span>
              </span>
              <span
                class="discount ma-0"
                ref="discounds"
                v-if="activeProduct.item.oldPrice"

:data-product-id="product.item._id"
                :style="{ color: theme.prices.oldPrice.color }"
                id="old-price"
                :key="restartAnimationKey + Math.random()"
                :class="[
                  getAnimationClasses(
                    theme.prices.oldPrice.animation,
                    theme.prices.oldPrice.delay,
                    'old-price'
                  ),
                ]"
                v-textFit="{
                  minFont: 15,
                  maxFont: 512,
                  maxHeightByPercent: 30,
                  quarterChild: true,
                }"
              >
                {{ activeProduct.item.oldPrice }}
                <span class="currency" ref="discount_currency">
                  {{ currency }}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Full_screen_Slide",
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    currency: {
      type: String,
      default: "",
      required: false,
    },
    logo: {
      type: String,
      default: "",
      required: false,
    },
    currentGroup: {
      type: Object,
      default: Object,
      required: false,
    },
    themeConfiguration: {
      type: Object,
      default: () => ({}),
    },
    theme: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    isLiveChangesPreview: {
      type: Boolean,
      default: false,
    },
    animationEffects: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      restartAnimationKey: 0,
      activeIndex: 0,
      totalTime: 4000,
      slidesPerView: 1,
      numberOfSlides: 1,
      activeProduct: {},
    };
  },
  computed: {
    groupBgColor() {
      return this.currentGroup.groupId?.bg_color;
    },
    productsTimes() {
      return this.products.map((product) => Number(product.item.counter ?? 1));
    },
  },
  mounted() {
    // initialize swiper
    this.initSlider();

    // when a slide contains products with a length that fits within a single slide, wait for the calculated time then go the next group
    // when the user switch to another tab or window, don't trigger the next group and wait until the user come back
    // if (this.products.length <= this.slidesPerView) {
    //   let handleNextGroupTimeout = setTimeout(() => {
    //     this.handleNextGroup();
    //   }, this.calcTime(this.activeIndex, this.productsTimes, 3));

    //   document.addEventListener("visibilitychange", () => {
    //     if (document.visibilityState === "hidden") {
    //       clearTimeout(handleNextGroupTimeout);
    //     } else if (document.visibilityState === "visible") {
    //       handleNextGroupTimeout = setTimeout(() => {
    //         this.handleNextGroup();
    //       }, this.calcTime(this.activeIndex, this.productsTimes, 2));
    //     }
    //   });
    // }
  },
  methods: {
    nextSlide() {
      if (this.activeIndex >= this.products.length - 1) {
        this.activeIndex = 0;
        this.$emit("goNext");
      } else {
        this.activeIndex += 1;
      }

      if (this.numberOfSlides > 1) {
        this.activeProduct = this.products[this.activeIndex];
      }

      setTimeout(() => {
        this.nextSlide();
      }, (this.activeProduct.item.counter || 1) * 1000);
    },
    initSlider() {
      this.numberOfSlides = this.products.length;
      this.activeIndex = 0;
      this.activeProduct = this.products[this.activeIndex];

      setTimeout(
        () => this.nextSlide(),
        (this.activeProduct.item.counter || 1) * 1000
      );
    },
    getAnimationClasses(animationName, animationDelay, id) {
      if (!animationName) {
        return "";
      }

      const animationDelayClass = animationDelay
        ? `animate__delay-${animationDelay}s`
        : "";

      const animationClass = this.animationEffects.find(
        (effect) => effect.name === animationName
      )?.class;

      this.$nextTick(() => {
        if (id) {
          let el = document.querySelector(`#${id}`);

          setTimeout(() => {
            el.classList.remove("opacity-0");
          }, Number(animationDelay) * 1000 - 100);
        }
      });

      return `animate__animated ${animationClass} ${animationDelayClass} opacity-0`;
    },
    initSwiper() {
      const swiperEl = this.$refs.swiper;
      // const swiperParams = {
      //   speed: this.totalTime / this.products.length,
      //   pagination: true,
      //   parallax: { enabled: true },
      //   navigation: true,
      //   autoplay: {
      //     delay: this.calcTime(this.activeIndex, this.productsTimes, 1),
      //     disableOnInteraction: false,
      //     enabled: true,
      //   },
      //   spaceBetween: 10,
      //   virtual: false,
      //   on: {
      //     slideChange: () => {
      //       // check if we need to go to next group
      //       this.handleNextGroup();
      //     },
      //   },
      // };

      const swiperParams = this.themeConfiguration.swiperParams({
        speed: this.totalTime / this.products.length,
        delay: this.calcTime(this.activeIndex, this.productsTimes, 1),
        handleNextGroup: this.handleNextGroup,
      });

      // now we need to assign all parameters to Swiper element
      Object.assign(swiperEl, swiperParams);

      // and now initialize it
      // and now initialize it
      this.$nextTick(() => {
        swiperEl.initialize();

        // stop slider autoplay when user switch to another tab or window
        document.addEventListener("visibilitychange", () => {
          if (document.visibilityState === "hidden") {
            this.$refs.swiper && this.$refs.swiper.swiper.autoplay.stop();
          } else {
            this.$refs.swiper && this.$refs.swiper.swiper.autoplay.start();
          }
        });
      });
    },
    handleNextGroup() {
      if (this.activeIndex == this.products.length - 1) {
        this.activeIndex = 0;
        this.$emit("goNext");
      } else {
        this.activeIndex += 1;
      }
      // set delay for next slide
      if (this.$refs.swiper) {
        this.$refs.swiper.swiper.params.autoplay.delay = this.calcTime(
          this.activeIndex,
          this.productsTimes,
          1
        );
      }
    },
  },
};
</script>
<style scoped lang="scss">
.swiper-container {
  user-select: none;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-top: 0px;
  padding-bottom: 0px;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  border-radius: 0px;
}

.swiper-slide-content {
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1;
  align-items: center;
  text-align: start;
  color: #000;
}
.wrapper-content {
  width: 100%;
  height: 60%;
  position: absolute;
  left: 10%;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  z-index: 100;
  box-shadow: none;
}

.video-container {
  position: absolute;
  top: 0;
  left: 0;
  transform: none;
  width: 100%;
  height: 100%;
  z-index: 1;

  iframe {
    width: 100%;
    height: 100%;
  }

  .video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
  }
}

.slide-content {
  background: #d9d9d9;
  padding: 20px 30px;
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.price {
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 10px;
}

.custom-banner {
  background: #fdd039;
  color: #000;
  padding: 10px 60px;
  text-align: center;
  width: 100%;
  height: 15%;
  font-weight: 500;
  word-break: break-word;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    line-height: 1.2 !important;
  }
}
.swiper-logo {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 100;
}

.swiper-slide-image {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.prices {
  height: 35%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fdd039;
  margin-top: auto;
}
.card-info {
  width: 100%;
  height: 35%;
  text-align: left;
  margin-bottom: 3%;
}
.discount {
  overflow: hidden;
}
</style>
