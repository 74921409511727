var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.themeComponents[
      Number(
        _vm.isLiveChangesPreview
          ? Number(_vm.liveThemeChanges.numericId) - 1
          : Number(_vm.currentGroup.theme.numericId) - 1
      )
    ],{tag:"component",class:[_vm.isLiveChangesPreview ? 'live-changes-preview' : ''],attrs:{"theme-configuration":_vm.activeThemeConfiguration,"theme":_vm.isLiveChangesPreview ? _vm.liveThemeChanges : _vm.currentGroup.theme,"is-live-changes-preview":_vm.isLiveChangesPreview,"animation-effects":_vm.animationEffects,"groupBgColor":_vm.groupBgColor,"products":_vm.activeProducts,"currency":_vm.currency,"logo":_vm.logo},on:{"goNext":function($event){return _vm.$emit('goNext')}}})
}
var staticRenderFns = []

export { render, staticRenderFns }