var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.activeProduct.item)?_c('div',{staticClass:"w-100 h-100"},[(false)?_c('div',{staticClass:"swiper-logo"},[_c('img',{staticClass:"object-cover rounded-circle",attrs:{"src":_vm.logo,"width":"50px","height":"50px","alt":"logo"}})]):_vm._e(),_c('div',{ref:"swiper",staticClass:"swiper-container",attrs:{"init":"false"}},[_c('div',{staticClass:"swiper-slide h-100 preview-item"},[(_vm.activeProduct.item.show === 'youtube')?_c('div',{staticClass:"swiper-slide-content video-container"},[_c('iframe',{attrs:{"src":_vm.extractedYoutubeEmbedUrl(_vm.activeProduct.item.links[0].url),"title":"YouTube video player","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share","allowfullscreen":""}}),_c('div',{staticClass:"video-overlay"})]):(_vm.activeProduct.item.show === 'image')?_c('div',{staticClass:"swiper-slide-content",style:(`object-fit: ${_vm.activeProduct.item.imageShowType} !important;`)},[_c('img',{staticClass:"swiper-slide-image",attrs:{"src":_vm.activeProduct.item.images[0].url}})]):_vm._e(),(
            _vm.activeProduct.item.title ||
            _vm.activeProduct.item.description ||
            _vm.activeProduct.item.price ||
            _vm.activeProduct.item.oldPrice
          )?_c('div',{staticClass:"wrapper-content"},[_c('div',{staticClass:"slide-content",style:({ backgroundColor: _vm.theme.container.color })},[(_vm.activeProduct.item.banner && _vm.activeProduct.item.banner.text)?_c('p',{key:_vm.restartAnimationKey + Math.random(),staticClass:"custom-banner",class:[
                _vm.getAnimationClasses(
                  null,
                  _vm.theme.banner.container.delay,
                  'banner-container'
                ),
              ],style:({ backgroundColor: _vm.theme.banner.container.color }),attrs:{"id":"banner-container"}},[_c('span',{directives:[{name:"text-fit",rawName:"v-text-fit",value:({
                  minFont: 10,
                  maxFont: 512,
                  maxHeightByPercent: 100,
                }),expression:"{\n                  minFont: 10,\n                  maxFont: 512,\n                  maxHeightByPercent: 100,\n                }"}]},[_c('span',{key:_vm.restartAnimationKey + Math.random(),class:[
                    _vm.getAnimationClasses(
                      _vm.theme.banner.text.animation,
                      _vm.theme.banner.text.delay,
                      'banner-text'
                    ),
                  ],style:({ color: _vm.theme.banner.text.color }),attrs:{"id":"banner-text"}},[_vm._v(_vm._s(_vm.activeProduct.item.banner.text))])])]):_vm._e(),_c('div',{staticClass:"card-info theme-content",style:({ backgroundColor: _vm.theme.content.container.color })},[(_vm.activeProduct.item.title)?_c('div',{directives:[{name:"textFit",rawName:"v-textFit",value:({
                  minFont: 10,
                  maxFont: 512,
                  maxHeightByPercent: 70,
                  checkHasSibling: true,
                }),expression:"{\n                  minFont: 10,\n                  maxFont: 512,\n                  maxHeightByPercent: 70,\n                  checkHasSibling: true,\n                }"}],key:_vm.restartAnimationKey + Math.random(),ref:"titles",staticClass:"swiper-slide-title",class:[
                  _vm.getAnimationClasses(
                    _vm.theme.content.title.animation,
                    _vm.theme.content.title.delay,
                    'theme-title'
                  ),
                ],style:({ color: _vm.theme.content.title.color }),attrs:{"id":"theme-title"}},[_vm._v(" "+_vm._s(_vm.activeProduct.item.title)+" ")]):_vm._e(),(_vm.activeProduct.item.description)?_c('div',{directives:[{name:"textFit",rawName:"v-textFit",value:({
                  minFont: 10,
                  maxFont: 512,
                  maxHeightByPercent: 30,
                }),expression:"{\n                  minFont: 10,\n                  maxFont: 512,\n                  maxHeightByPercent: 30,\n                }"}],key:_vm.restartAnimationKey + Math.random(),ref:"descriptions",staticClass:"swiper-slide-text",class:[
                  _vm.getAnimationClasses(
                    _vm.theme.content.description.animation,
                    _vm.theme.content.description.delay,
                    'theme-description'
                  ),
                ],style:({ color: _vm.theme.content.description.color }),attrs:{"id":"theme-description"},domProps:{"innerHTML":_vm._s(_vm.wrapString(_vm.activeProduct.item.description, 50))}}):_vm._e()]),(_vm.activeProduct.item.price || _vm.activeProduct.item.oldPrice)?_c('div',{staticClass:"prices black--text d-flex",style:({
                backgroundColor: _vm.theme.prices.container.color,
              })},[(_vm.activeProduct.item.price)?_c('span',{directives:[{name:"textFit",rawName:"v-textFit",value:({
                  minFont: 15,
                  maxFont: 512,
                  maxHeightByPercent: 70,
                  checkHasSibling: true,
                  quarterChild: true,
                }),expression:"{\n                  minFont: 15,\n                  maxFont: 512,\n                  maxHeightByPercent: 70,\n                  checkHasSibling: true,\n                  quarterChild: true,\n                }"}],key:_vm.restartAnimationKey + Math.random(),ref:"prices",staticClass:"price",class:[
                  _vm.getAnimationClasses(
                    _vm.theme.prices.price.animation,
                    _vm.theme.prices.price.delay,
                    'price'
                  ),
                ],style:({
                  color: _vm.theme.prices.price.color,
                }),attrs:{"id":"price"}},[_vm._v(" "+_vm._s(_vm.activeProduct.item.price)+" "),_c('span',{staticClass:"currency"},[_vm._v(" "+_vm._s(_vm.currency)+" ")])]):_vm._e(),(_vm.activeProduct.item.oldPrice)?_c('span',{directives:[{name:"textFit",rawName:"v-textFit",value:({
                  minFont: 15,
                  maxFont: 512,
                  maxHeightByPercent: 30,
                  quarterChild: true,
                }),expression:"{\n                  minFont: 15,\n                  maxFont: 512,\n                  maxHeightByPercent: 30,\n                  quarterChild: true,\n                }"}],key:_vm.restartAnimationKey + Math.random(),ref:"discounds",staticClass:"discount ma-0",class:[
                  _vm.getAnimationClasses(
                    _vm.theme.prices.oldPrice.animation,
                    _vm.theme.prices.oldPrice.delay,
                    'old-price'
                  ),
                ],style:({ color: _vm.theme.prices.oldPrice.color }),attrs:{"data-product-id":_vm.product.item._id,"id":"old-price"}},[_vm._v(" "+_vm._s(_vm.activeProduct.item.oldPrice)+" "),_c('span',{ref:"discount_currency",staticClass:"currency"},[_vm._v(" "+_vm._s(_vm.currency)+" ")])]):_vm._e()]):_vm._e()])]):_vm._e()])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }