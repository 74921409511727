var render = function render(){var _vm=this,_c=_vm._self._c;return (Object.keys(_vm.theme).length)?_c('div',{staticClass:"theme h-100 d-flex justify-center",style:({
    backgroundColor: _vm.theme.container.color || '#FFFFFF',
  })},[(false)?_c('div',{staticClass:"swiper-logo"},[_c('img',{staticClass:"object-cover rounded-circle",attrs:{"src":_vm.logo,"width":"50px","height":"50px","alt":"logo"}})]):_vm._e(),_c('div',{ref:"swiper",staticClass:"swiper-container"},[_c('v-row',{staticClass:"flex-wrap h-100 my-auto"},_vm._l((_vm.products),function(product){return _c('v-col',{key:product._id,staticClass:"slider-col",attrs:{"cols":"4"}},[_c('div',{staticClass:"swiper-slide my-4 h-100 relative"},[(product.item.banner && product.item.banner.text)?_c('p',{staticClass:"custom-banner banner",style:({ backgroundColor: _vm.theme.banner.container.color }),attrs:{"id":"banner-container"}},[_c('span',{staticClass:"banner-font-size",attrs:{"data-product-id":product.item._id}},[_c('span',{staticClass:"banner-text",style:({ color: _vm.theme.banner.text.color }),attrs:{"id":"banner-text"}},[_vm._v(_vm._s(product.item.banner.text))])])]):_vm._e(),_c('div',{staticClass:"swiper-slide-content preview-item"},[(product.item.show === 'video')?_c('div',{staticClass:"swiper-slide-content video-container"},[_c('video',{style:(`object-fit: ${product.item.video.videoShowType} !important;`),attrs:{"width":"100%","height":"100%","poster":product.item.video.thumbnail,"autoplay":"","muted":"","loop":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":product.item.video.url,"type":"video/mp4"}}),_vm._v(" Your browser does not support the video tag. ")])]):_vm._e(),(product.item.show === 'youtube')?_c('div',{staticClass:"swiper-slide-content video-container"},[_c('iframe',{attrs:{"src":_vm.extractedYoutubeEmbedUrl(product.item.links[0].url),"title":"YouTube video player","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share","allowfullscreen":""}}),_c('div',{staticClass:"video-overlay"})]):(product.item.show === 'image')?_c('div',{staticClass:"wrapper-image",style:({
                height:
                  !product.item.title &&
                  !product.item.description &&
                  !product.item.price &&
                  !product.item.oldPrice
                    ? '100%'
                    : '',
              })},[_c('img',{staticClass:"swiper-slide-image",style:(`objectFit: ${product.item.imageShowType} !important`),attrs:{"src":product.item.images[0].url}})]):_vm._e(),(
                product.item.title ||
                product.item.description ||
                product.item.price ||
                product.item.oldPrice
              )?_c('div',{staticClass:"wrapper-content"},[_c('div',{staticClass:"card-info theme-content text-start d-flex flex-column",style:({ backgroundColor: _vm.theme.content.container.color })},[(product.item.title)?_c('div',{staticClass:"swiper-slide-title theme-title",style:({ color: _vm.theme.content.title.color }),attrs:{"data-product-id":product.item._id,"id":"theme-title"}},[_vm._v(" "+_vm._s(product.item.title)+" ")]):_vm._e(),(product.item.description)?_c('div',{staticClass:"swiper-slide-text theme-description",style:({ color: _vm.theme.content.description.color }),attrs:{"data-product-id":product.item._id,"id":"theme-description"},domProps:{"innerHTML":_vm._s(_vm.wrapString(product.item.description, 50))}}):_vm._e()]),(product.item.price || product.item.oldPrice)?_c('div',{staticClass:"prices black--text",style:({
                  backgroundColor: _vm.theme.prices.container.color,
                })},[(product.item.price)?_c('span',{staticClass:"price d-inline-block mx-0 mr-3 theme-price",style:({
                    color: _vm.theme.prices.price.color,
                  }),attrs:{"id":"price","data-product-id":product.item._id}},[_vm._v(" "+_vm._s(product.item.price)+" "),_c('span',{staticClass:"currency"},[_vm._v(" "+_vm._s(_vm.currency)+" ")])]):_vm._e(),(product.item.oldPrice)?_c('span',{staticClass:"ma-0 discount theme-old-price",style:({ color: _vm.theme.prices.oldPrice.color }),attrs:{"data-product-id":product.item._id,"id":"old-price"}},[_vm._v(" "+_vm._s(product.item.oldPrice)),_c('span',{staticClass:"currency"},[_vm._v(_vm._s(_vm.currency))])]):_vm._e()]):_vm._e()]):_vm._e()])])])}),1)],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }