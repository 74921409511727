export default [
  {
    _id: "1",
    img: require("@/assets/images/themes/Group3872slide.svg"),
    selected: false,
    name: "2 Slides",
    productsPerSlide: 2,
    themeParts(theme) {
      return [
        {
          className: "banner-font-size",
          animationElementClass: "banner-text",
          props: {
            minFont: 10,
            maxFont: 512,
            maxHeightByPercent: 100,
            animationName: theme.banner.text.animation,
            animationDelay: theme.banner.text.delay,
            relativeExist: true,
          },
          relative: null,
        },
        {
          className: "theme-title",
          props: {
            minFont: 10,
            maxFont: 512,
            maxHeightByPercent: 60,
            checkHasSibling: true,
            animationName: theme.content.title.animation,
            animationDelay: theme.content.title.delay,
            relativeExist: true,
          },
          relative: "description",
        },
        {
          className: "theme-description",
          props: {
            minFont: 10,
            maxFont: 512,
            maxHeightByPercent: 30,
            animationName: theme.content.description.animation,
            animationDelay: theme.content.description.delay,
            relativeExist: true,
          },
          relative: "title",
        },
        {
          className: "theme-price",
          props: {
            minFont: 10,
            maxFont: 512,
            maxHeightByPercent: 70,
            checkHasSibling: true,
            quarterChild: true,
            animationName: theme.prices.price.animation,
            animationDelay: theme.prices.price.delay,
            relativeExist: true,
          },
          relative: "oldPrice",
        },
        {
          className: "theme-old-price",
          props: {
            minFont: 15,
            maxFont: 512,
            maxHeightByPercent: 15,
            quarterChild: true,
            animationName: theme.prices.oldPrice.animation,
            animationDelay: theme.prices.oldPrice.delay,
            relativeExist: true,
          },
          relative: "price",
        },
      ];
    },
  },
  {
    _id: "2",
    img: require("@/assets/images/themes/Group 514.svg"),
    selected: false,
    name: "3 Slide",
    productsPerSlide: 3,
    themeParts(theme) {
      return [
        {
          className: "banner-font-size",
          animationElementClass: "banner-text",
          props: {
            minFont: 10,
            maxFont: 512,
            maxHeightByPercent: 100,
            animationName: theme.banner.text.animation,
            relativeExist: true,
            animationDelay: theme.banner.text.delay,
          },
          relative: null,
        },
        {
          className: "theme-title",
          props: {
            minFont: 15,
            maxFont: 512,
            maxHeightByPercent: 70,
            checkHasSibling: true,
            animationName: theme.content.title.animation,
            relativeExist: true,
            animationDelay: theme.content.title.delay,
          },
          relative: "description",
          log: true,
        },
        {
          className: "theme-description",
          props: {
            minFont: 15,
            maxFont: 512,
            maxHeightByPercent: 30,
            animationName: theme.content.description.animation,
            relativeExist: true,
            animationDelay: theme.content.description.delay,
          },
          relative: "title",
        },
        {
          className: "theme-price",
          props: {
            minFont: 15,
            maxFont: 512,
            maxHeightByPercent: 70,
            quarterChild: true,
            animationName: theme.prices.price.animation,
            relativeExist: true,
            animationDelay: theme.prices.price.delay,
          },
          relative: "oldPrice",
        },
        {
          className: "theme-old-price",
          props: {
            minFont: 15,
            maxFont: 512,
            maxHeightByPercent: 20,
            quarterChild: true,
            animationName: theme.prices.oldPrice.animation,
            relativeExist: true,
            animationDelay: theme.prices.oldPrice.delay,
          },
          relative: "price",
        },
      ];
    },
  },
  {
    _id: "3",
    img: require("@/assets/images/themes/13.svg"),
    selected: false,
    name: "Half Screen",
    productsPerSlide: 1,
    themeParts(theme) {
      return [
        {
          className: "banner-font-size",
          animationElementClass: "banner-text",
          props: {
            minFont: 10,
            maxFont: 512,
            maxHeightByPercent: 100,
            animationName: theme.banner.text.animation,
            relativeExist: true,
            animationDelay: theme.banner.text.delay,
          },
          relative: null,
        },
        {
          className: "theme-title",
          props: {
            minFont: 15,
            maxFont: 512,
            maxHeightByPercent: 60,
            checkHasSibling: true,
            animationName: theme.content.title.animation,
            relativeExist: true,
            animationDelay: theme.content.title.delay,
          },
          relative: "description",
        },
        {
          className: "theme-description",
          props: {
            minFont: 15,
            maxFont: 512,
            maxHeightByPercent: 40,
            animationName: theme.content.description.animation,
            relativeExist: true,
            animationDelay: theme.content.description.delay,
          },
          relative: "title",
        },
        {
          className: "theme-price",
          props: {
            minFont: 15,
            maxFont: 512,
            maxHeightByPercent: 70,
            quarterChild: true,
            checkHasSibling: true,
            animationName: theme.prices.price.animation,
            relativeExist: true,
            animationDelay: theme.prices.price.delay,
          },
          relative: "oldPrice",
        },
        {
          className: "theme-old-price",
          props: {
            minFont: 15,
            maxFont: 512,
            maxHeightByPercent: 30,
            quarterChild: true,
            animationName: theme.prices.oldPrice.animation,
            relativeExist: true,
            animationDelay: theme.prices.oldPrice.delay,
          },
          relative: "price",
        },
      ];
    },
  },
  {
    _id: "4",
    img: require("@/assets/images/themes/Full_screen_fade.svg"),
    selected: false,
    name: "Full screen",
    productsPerSlide: 1,
    themeParts(theme) {
      return [
        {
          className: "banner-font-size",
          animationElementClass: "banner-text",
          props: {
            minFont: 10,
            maxFont: 512,
            maxHeightByPercent: 100,
            animationName: theme.banner.text.animation,
            relativeExist: true,
            animationDelay: theme.banner.text.delay,
          },
          relative: null,
        },
        {
          className: "theme-title",
          props: {
            minFont: 15,
            maxFont: 512,
            maxHeightByPercent: 70,
            checkHasSibling: true,
            animationName: theme.content.title.animation,
            relativeExist: true,
            animationDelay: theme.content.title.delay,
          },
          relative: "description",
          log: true,
        },
        {
          className: "theme-description",
          props: {
            minFont: 15,
            maxFont: 512,
            maxHeightByPercent: 30,
            animationName: theme.content.description.animation,
            relativeExist: true,
            animationDelay: theme.content.description.delay,
          },
          relative: "title",
        },
        {
          className: "theme-price",
          props: {
            minFont: 15,
            maxFont: 512,
            maxHeightByPercent: 70,
            checkHasSibling: true,
            quarterChild: true,
            animationName: theme.prices.price.animation,
            relativeExist: true,
            animationDelay: theme.prices.price.delay,
          },
          relative: "oldPrice",
        },
        {
          className: "theme-old-price",
          props: {
            minFont: 15,
            maxFont: 512,
            maxHeightByPercent: 30,
            quarterChild: true,
            animationName: theme.prices.oldPrice.animation,
            relativeExist: true,
            animationDelay: theme.prices.oldPrice.delay,
          },
          relative: "price",
        },
      ];
    },
  },
  {
    _id: "5",
    img: require("@/assets/images/themes/Group 458.svg"),
    selected: false,
    name: "SideCircleSlide",
    productsPerSlide: 1,
    themeParts(theme) {
      return [
        {
          className: "banner-font-size",
          animationElementClass: "banner-text",
          props: {
            minFont: 10,
            maxFont: 512,
            maxHeightByPercent: 100,
            animationName: theme.banner.text.animation,
            relativeExist: true,
            animationDelay: theme.banner.text.delay,
          },
          relative: null,
        },
        {
          className: "theme-title",
          props: {
            minFont: 15,
            maxFont: 512,
            maxHeightByPercent: 70,
            checkHasSibling: true,
            animationName: theme.content.title.animation,
            relativeExist: true,
            animationDelay: theme.content.title.delay,
          },
          relative: "description",
        },
        {
          className: "theme-description",
          props: {
            minFont: 15,
            maxFont: 512,
            maxHeightByPercent: 30,
            animationName: theme.content.description.animation,
            relativeExist: true,
            animationDelay: theme.content.description.delay,
          },
          relative: "title",
        },
        {
          className: "theme-price",
          props: {
            minFont: 15,
            maxFont: 512,
            maxHeightByPercent: 70,
            quarterChild: true,
            checkHasSibling: true,
            animationName: theme.prices.price.animation,
            relativeExist: true,
            animationDelay: theme.prices.price.delay,
          },
          relative: "oldPrice",
        },
        {
          className: "theme-old-price",
          props: {
            minFont: 15,
            maxFont: 512,
            maxHeightByPercent: 30,
            quarterChild: true,
            animationName: theme.prices.oldPrice.animation,
            relativeExist: true,
            animationDelay: theme.prices.oldPrice.delay,
          },
          relative: "price",
        },
      ];
    },
  },
  {
    _id: "6",
    img: require("@/assets/images/themes/Group 555.svg"),
    selected: false,
    name: "Menu Theme One",
    productsPerSlide: 9,
    themeParts(theme) {
      return [
        {
          className: "banner-font-size",
          animationElementClass: "banner-text",
          props: {
            minFont: 10,
            maxFont: 512,
            maxHeightByPercent: 100,
            animationName: theme.banner.text.animation,
            relativeExist: true,
            animationDelay: theme.banner.text.delay,
          },
          relative: null,
        },
        {
          className: "theme-title",
          props: {
            minFont: 15,
            maxFont: 512,
            maxHeightByPercent: 70,
            checkHasSibling: true,
            animationName: theme.content.title.animation,
            relativeExist: true,
            animationDelay: theme.content.title.delay,
          },
          relative: "description",
        },
        {
          className: "theme-description",
          props: {
            minFont: 15,
            maxFont: 512,
            maxHeightByPercent: 30,
            animationName: theme.content.description.animation,
            relativeExist: true,
            animationDelay: theme.content.description.delay,
          },
          relative: "title",
        },
        {
          className: "theme-price",
          props: {
            minFont: 15,
            maxFont: 512,
            maxHeightByPercent: 70,
            quarterChild: true,
            checkHasSibling: true,
            animationName: theme.prices.price.animation,
            relativeExist: true,
            animationDelay: theme.prices.price.delay,
          },
          relative: "oldPrice",
        },
        {
          className: "theme-old-price",
          props: {
            minFont: 15,
            maxFont: 512,
            maxHeightByPercent: 30,
            quarterChild: true,
            animationName: theme.prices.oldPrice.animation,
            relativeExist: true,
            animationDelay: theme.prices.oldPrice.delay,
          },
          relative: "price",
        },
      ];
    },
  },
  {
    _id: "7",
    img: require("@/assets/images/themes/Group 566.svg"),
    selected: false,
    name: "Menu Theme Two",
    productsPerSlide: 9,
    themeParts(theme) {
      return [
        {
          className: "banner-font-size",
          animationElementClass: "banner-text",
          props: {
            minFont: 10,
            maxFont: 512,
            maxHeightByPercent: 100,
            animationName: theme.banner.text.animation,
            relativeExist: true,
            animationDelay: theme.banner.text.delay,
          },
          relative: null,
        },
        {
          className: "theme-title",
          props: {
            minFont: 15,
            maxFont: 512,
            maxHeightByPercent: 70,
            checkHasSibling: true,
            animationName: theme.content.title.animation,
            relativeExist: true,
            animationDelay: theme.content.title.delay,
          },
          relative: "description",
          log: true,
        },
        {
          className: "theme-description",
          props: {
            minFont: 15,
            maxFont: 512,
            maxHeightByPercent: 30,
            animationName: theme.content.description.animation,
            relativeExist: true,
            animationDelay: theme.content.description.delay,
          },
          relative: "title",
        },
        {
          className: "theme-price",
          props: {
            minFont: 15,
            maxFont: 512,
            maxHeightByPercent: 70,
            quarterChild: true,
            checkHasSibling: true,
            animationName: theme.prices.price.animation,
            relativeExist: true,
            animationDelay: theme.prices.price.delay,
          },
          relative: "oldPrice",
        },
        {
          className: "theme-old-price",
          props: {
            minFont: 15,
            maxFont: 512,
            maxHeightByPercent: 30,
            quarterChild: true,
            animationName: theme.prices.oldPrice.animation,
            relativeExist: true,
            animationDelay: theme.prices.oldPrice.delay,
          },
          relative: "price",
        },
      ];
    },
  },
  {
    _id: "8",
    img: require("@/assets/images/themes/creative-theme.svg"),
    selected: false,
    name: "Creative Theme",
    productsPerSlide: 1,
    themeParts(theme) {
      return [
        {
          className: "banner-font-size",
          animationElementClass: "banner-text",
          props: {
            minFont: 10,
            maxFont: 512,
            maxHeightByPercent: 100,
            animationName: theme.banner.text.animation,
            relativeExist: true,
            animationDelay: theme.banner.text.delay,
          },
          relative: null,
        },
        {
          className: "theme-title",
          props: {
            minFont: 10,
            maxFont: 512,
            maxHeightByPercent: 70,
            checkHasSibling: true,
            animationName: theme.content.title.animation,
            relativeExist: true,
            animationDelay: theme.content.title.delay,
          },
          relative: "description",
          // log: true,
        },
        {
          className: "theme-description",
          props: {
            minFont: 10,
            maxFont: 512,
            maxHeightByPercent: 30,
            animationName: theme.content.description.animation,
            relativeExist: true,
            animationDelay: theme.content.description.delay,
          },
          relative: "title",
        },
        {
          className: "theme-price",
          props: {
            minFont: 15,
            maxFont: 512,
            maxHeightByPercent: 70,
            checkHasSibling: true,
            quarterChild: true,
            animationName: theme.prices.price.animation,
            relativeExist: true,
            animationDelay: theme.prices.price.delay,
          },
          relative: "oldPrice",
        },
        {
          className: "theme-old-price",
          props: {
            minFont: 15,
            maxFont: 512,
            maxHeightByPercent: 30,
            quarterChild: true,
            animationName: theme.prices.oldPrice.animation,
            relativeExist: true,
            animationDelay: theme.prices.oldPrice.delay,
          },
          relative: "price",
        },
      ];
    },
  },
  {
    _id: "9",
    img: require("@/assets/images/themes/card-design.svg"),
    selected: false,
    name: "Card Design Theme",
    productsPerSlide: 1,
    themeParts(theme) {
      return [
        {
          className: "banner-font-size",
          animationElementClass: "banner-text",
          props: {
            minFont: 10,
            maxFont: 512,
            maxHeightByPercent: 100,
            animationName: theme.banner.text.animation,
            relativeExist: true,
            animationDelay: theme.banner.text.delay,
          },
          relative: null,
        },
        {
          className: "theme-title",
          props: {
            minFont: 10,
            maxFont: 512,
            maxHeightByPercent: 70,
            checkHasSibling: true,
            animationName: theme.content.title.animation,
            relativeExist: true,
            animationDelay: theme.content.title.delay,
          },
          relative: "description",
          // log: true,
        },
        {
          className: "theme-description",
          props: {
            minFont: 10,
            maxFont: 512,
            maxHeightByPercent: 30,
            animationName: theme.content.description.animation,
            relativeExist: true,
            animationDelay: theme.content.description.delay,
          },
          relative: "title",
        },
        {
          className: "theme-price",
          props: {
            minFont: 15,
            maxFont: 512,
            maxHeightByPercent: 70,
            checkHasSibling: true,
            quarterChild: true,
            animationName: theme.prices.price.animation,
            relativeExist: true,
            animationDelay: theme.prices.price.delay,
          },
          relative: "oldPrice",
        },
        {
          className: "theme-old-price",
          props: {
            minFont: 15,
            maxFont: 512,
            maxHeightByPercent: 30,
            quarterChild: true,
            animationName: theme.prices.oldPrice.animation,
            relativeExist: true,
            animationDelay: theme.prices.oldPrice.delay,
          },
          relative: "price",
        },
      ];
    },
  },
];
