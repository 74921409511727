var render = function render(){var _vm=this,_c=_vm._self._c;return _c('swiper-container',{ref:"swiper",staticClass:"swiper-container",style:({ backgroundColor: _vm.theme.container.color || '#FFFFFF' }),attrs:{"init":"false"}},_vm._l((_vm.products),function(product,i){return _c('swiper-slide',{key:i},[_c('div',{staticClass:"menu-slide-card preview-item"},[_c('img',{staticClass:"menu-slide-card__img",style:({
          height:
            !product.item.title &&
            !product.item.description &&
            !product.item.price &&
            !product.item.oldPrice
              ? '100%'
              : '',
        }),attrs:{"src":product.item.images[0].url}}),(product.item.banner && product.item.banner.text)?_c('p',{key:_vm.restartAnimationKey + Math.random(),staticClass:"banner",class:[
          _vm.getAnimationClasses(
            null,
            _vm.theme.banner.container.delay,
            'banner-container'
          ),
        ],style:({ backgroundColor: _vm.theme.banner.container.color }),attrs:{"id":"banner-container"}},[_c('span',{key:_vm.restartAnimationKey + Math.random(),class:[
            _vm.getAnimationClasses(
              _vm.theme.banner.text.animation,
              _vm.theme.banner.text.delay,
              'banner-text'
            ),
          ],style:({ color: _vm.theme.banner.text.color }),attrs:{"id":"banner-text"}},[_vm._v(_vm._s(product.item.banner.text))])]):_vm._e(),(
          product.item.title ||
          product.item.description ||
          product.item.price ||
          product.item.oldPrice
        )?_c('div',{staticClass:"menu-slide-card__content"},[_c('div',{staticClass:"menu-slide-card__left"},[(product.item.title)?_c('div',{key:_vm.restartAnimationKey + Math.random(),ref:"titles",refInFor:true,staticClass:"menu-slide-card__title",class:[
              _vm.getAnimationClasses(
                _vm.theme.content.title.animation,
                _vm.theme.content.title.delay,
                'theme-title'
              ),
            ],style:({ color: _vm.theme.content.title.color }),attrs:{"id":"theme-title"},domProps:{"innerHTML":_vm._s(_vm.truncateString(_vm.wrapString(product.item.title, 20), 30))}}):_vm._e(),(product.item.description)?_c('div',{key:_vm.restartAnimationKey + Math.random(),ref:"descriptions",refInFor:true,staticClass:"menu-slide-card__description",class:[
              _vm.getAnimationClasses(
                _vm.theme.content.description.animation,
                _vm.theme.content.description.delay,
                'theme-description'
              ),
            ],style:({ color: _vm.theme.content.description.color }),attrs:{"id":"theme-description"},domProps:{"innerHTML":_vm._s(
              _vm.truncateString(_vm.wrapString(product.item.description, 50), 10)
            )}}):_vm._e()]),(product.item.price || product.item.oldPrice)?_c('div',{staticClass:"menu-slide-card__right",style:({
            backgroundColor: _vm.theme.prices.container.color,
          })},[(product.item.price)?_c('span',{key:_vm.restartAnimationKey + Math.random(),ref:"prices",refInFor:true,staticClass:"menu-slide-card__price",class:[
              _vm.getAnimationClasses(
                _vm.theme.prices.price.animation,
                _vm.theme.prices.price.delay,
                'price'
              ),
            ],style:({
              color: _vm.theme.prices.price.color,
            }),attrs:{"id":"price"}},[_vm._v(" "+_vm._s(product.item.price)+" "),_c('span',{ref:"currency",refInFor:true,staticClass:"currency"},[_vm._v(" "+_vm._s(_vm.currency)+" ")])]):_vm._e(),(product.item.oldPrice)?_c('span',{key:_vm.restartAnimationKey + Math.random(),ref:"discounds",refInFor:true,staticClass:"discount",class:[
              _vm.getAnimationClasses(
                _vm.theme.prices.oldPrice.animation,
                _vm.theme.prices.oldPrice.delay,
                'old-price'
              ),
            ],style:({ color: _vm.theme.prices.oldPrice.color }),attrs:{"id":"old-price"}},[_vm._v(" "+_vm._s(product.item.oldPrice)+" "),_c('span',{ref:"discount_currency",refInFor:true,staticClass:"currency"},[_vm._v(" "+_vm._s(_vm.currency)+" ")])]):_vm._e()]):_vm._e()]):_vm._e()])])}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }