<template>
  <div
    v-if="Object.keys(theme).length"
    class="theme h-100 d-flex justify-center"
    :style="{ backgroundColor: theme.container.color || '#FFFFFF' }"
  >
    <div v-if="false" class="swiper-logo">
      <img
        :src="logo"
        width="50px"
        height="50px"
        class="object-cover rounded-circle"
        alt="logo"
      />
    </div>
    <div ref="swiper" class="swiper-container">
      <v-row class="flex-wrap h-100 my-auto">
        <v-col
          cols="3"
          v-for="product in products"
          :key="product._id"
          class="swiper-slide my-4 h-100"
        >
          <span
            v-if="product.item.banner && product.item.banner.text"
            class="banner custom-banner"
            ref="banner"
            :style="`backgroundColor: ${theme.banner.container.color}`"
            id="banner-container"
          >
            <span class="banner-font-size" :data-product-id="product.item._id">
              <span
                id="banner-text"
                class="banner-text"
                :style="{ color: theme.banner.text.color }"
                >{{ product.item.banner.text }}</span
              >
            </span>
          </span>
          <div class="swiper-slide-content position-relative preview-item">
            <div
              v-if="product.item.show === 'video'"
              class="swiper-slide-content video-container"
            >
              <video
                width="100%"
                height="100%"
                :style="`object-fit: ${product.item.video.videoShowType} !important;`"
                :poster="product.item.video.thumbnail"
                autoplay
                muted
                loop
              >
                <source :src="product.item.video.url" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>

            <div
              v-if="product.item.show === 'youtube'"
              class="swiper-slide-content video-container position-relative border-radius-0"
            >
              <iframe
                :src="extractedYoutubeEmbedUrl(product.item.links[0].url)"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
              <div class="video-overlay"></div>
            </div>

            <div
              v-else-if="product.item.show === 'image'"
              class="wrapper-image"
              :style="{
                height:
                  !product.item.title &&
                  !product.item.description &&
                  !product.item.price &&
                  !product.item.oldPrice
                    ? '100%'
                    : '',
              }"
            >
              <img
                :src="product.item.images[0].url"
                :style="`objectFit: ${product.item.imageShowType} !important`"
                class="swiper-slide-image"
              />
            </div>

            <div
              class="wrapper-content"
              v-if="
                product.item.title ||
                product.item.description ||
                product.item.price ||
                product.item.oldPrice
              "
            >
              <div
                :style="{ backgroundColor: theme.content.container.color }"
                class="card-info theme-content d-flex flex-column"
              >
                <div
                  class="swiper-slide-title theme-title"
                  v-if="product.item.title"
                  :style="{ color: theme.content.title.color }"
                  :data-product-id="product.item._id"
                  id="theme-title"
                >
                  {{ product.item.title }}
                </div>
                <div
                  class="swiper-slide-text theme-description"
                  v-if="product.item.description"
                  :style="{ color: theme.content.description.color }"
                  :data-product-id="product.item._id"
                  id="theme-description"
                >
                  {{ product.item.description }}
                </div>
              </div>
              <div
                class="prices black--text pa-1"
                v-if="product.item.price || product.item.oldPrice"
                :style="{
                  backgroundColor: theme.prices.container.color,
                }"
              >
                <span
                  v-if="product.item.price"
                  :style="{
                    color: theme.prices.price.color,
                  }"
                  id="price"
                  :data-product-id="product.item._id"
                  class="price mx-0 theme-price"
                >
                  {{ product.item.price }}
                  <span class="currency">
                    {{ currency }}
                  </span>
                </span>
                <span
                  v-if="product.item.oldPrice"
                  :data-product-id="product.item._id"
                  :style="{ color: theme.prices.oldPrice.color }"
                  id="old-price"
                  class="ma-0 discount theme-old-price"
                >
                  {{ product.item.oldPrice }}
                  <span class="currency">
                    {{ currency }}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
export default {
  name: "MenuSlide1",
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    currency: {
      type: String,
      default: "",
      required: false,
    },
    logo: {
      type: String,
      default: "",
      required: false,
    },
    currentGroup: {
      type: Object,
      default: Object,
      required: false,
    },
    themeConfiguration: {
      type: Object,
      default: () => ({}),
    },
    theme: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    isLiveChangesPreview: {
      type: Boolean,
      default: false,
    },
    animationEffects: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      restartAnimationKey: 0,
    };
  },
  computed: {
    groupBgColor() {
      return this.currentGroup.groupId?.bg_color;
    },
    productsTimes() {
      return this.products.map((product) => Number(product.item.counter ?? 1));
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.swiper-container {
  user-select: none;
  box-sizing: border-box;
  overflow: visible;
  width: 95%;
  height: 100%;
  padding-top: 0px;
  padding-bottom: 0px;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 45.8%;
  color: #fff;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;

  // background-color: rgba(51, 51, 51, 1);

  border-radius: 14px;
}
.swiper-slide-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  color: #000;
  border-radius: 14px;
  overflow: hidden;
  box-shadow: 3px 4px 10px 2px #00000040;

  &.video-container {
    position: relative;
    border-radius: 0;
  }
}

// .swiper-slide-title {
//   // formula to calculate minimum font size where x is the length of the text
//   // calc(-130/11 * (var(--textLength) / 5) + (2990/11) * 1px);

//   --minFontSize: 12px;
//   --maxFontSize: 500px;
//   --baseSize: 25vw;

//   font-size: clamp(
//     var(--minFontSize),
//     calc(var(--baseSize) / var(--textLength)),
//     var(--maxFontSize)
//   );

//   @media (min-width: 2500px) {
//     --minFontSize: 40px;
//     --maxFontSize: 260px;
//   }

//   @media (min-width: 4500px) {
//     --minFontSize: 50px;
//     --maxFontSize: 260px;
//   }
// }

// .swiper-slide-text,
// .custom-banner {
//   --minFontSize: 9px;
//   --maxFontSize: 250px;
//   --baseSize: 15vw;

//   font-size: clamp(
//     var(--minFontSize),
//     calc(var(--baseSize) / var(--textLength)),
//     var(--maxFontSize)
//   );

//   @media (min-width: 2500px) {
//     --minFontSize: 30px;
//     --maxFontSize: 300px;
//   }

//   @media (min-width: 4500px) {
//     --minFontSize: 40px;
//     --maxFontSize: 300px;
//   }
// }

// .custom-banner {
//   --minFontSize: 16px;
// }

// .price {
//   font-size: 1.5rem;
//   // font-weight: bold;
//   // margin-right: 10px;

//   --minFontSize: 16px;
//   --maxFontSize: 500px;
//   --baseSize: 1.5vw;
//   font-size: clamp(var(--minFontSize), var(--baseSize), var(--maxFontSize));

//   .currency {
//     display: inline-block;
//     font-size: clamp(
//       calc(var(--minFontSize) / 2),
//       calc(var(--baseSize) / 3),
//       calc(var(--maxFontSize) / 2)
//     );
//   }
// }

// .discount {
//   --minFontSize: 16px;
//   --maxFontSize: 250px;
//   --baseSize: 0.5vw;
//   font-size: clamp(var(--minFontSize), var(--baseSize), var(--maxFontSize));

//   .currency {
//     display: inline-block;
//     // transform: scale(0.5);
//     font-size: clamp(
//       calc(var(--minFontSize) / 2),
//       calc(var(--baseSize) / 3),
//       calc(var(--maxFontSize) / 2)
//     );
//   }
// }

.wrapper-image {
  // position: absolute;
  // left: 0;
  // top: 0;
  height: 75%;
  flex-grow: 1;
  width: 100%;
  z-index: 0;
}
.swiper-slide-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.wrapper-content {
  // position: absolute;
  display: flex;
  background-color: rgba(255, 255, 255, 0.94);
  width: 100%;
  height: 25%;
  align-self: flex-end;
  text-align: left;
  justify-content: space-between;
}

.swiper-logo {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
}

.card-info {
  width: calc(100% - 23.5%);
  flex-grow: 1;
  padding: 10px 15px;
  line-height: 1.4;
}
.prices {
  width: 23.5% !important;
  background-color: #fdd039;
  color: #000;
  justify-content: center;
  align-items: center;
  padding: 8px;
}
.prices {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.custom-banner {
  background-color: #fdd039;
  color: #000;
  border-radius: 14px;
  padding: 4px 12px;
  word-break: break-word;
  font-weight: 500;
  height: 10%;
  display: flex;
  span {
    line-height: 1.2 !important;
  }

  &.banner {
    top: 16px;
  }
}
</style>
