<template>
  <div
    v-if="Object.keys(theme).length"
    class="theme h-100 d-flex align-center justify-center"
    :style="{ backgroundColor: theme.container.color || '#FFFFFF' }"
  >
    <div v-if="false" class="swiper-logo">
      <img
        :src="logo"
        width="50px"
        height="50px"
        class="object-cover rounded-circle"
        alt="logo"
      />
    </div>
    <div class="slider-container">
      <v-row class="h-100">
        <v-col
          cols="12"
          :md="isVertical ? '12' : '4'"
          v-for="(product, i) in products"
          :key="product._id"
          class="my-4 swiper-slide position-relative"
        >
          <span
            v-if="product.item.banner && product.item.banner.text"
            class="banner custom-banner"
            :style="{ backgroundColor: theme.banner.container.color }"
            :id="`banner-container${i}`"
          >
            <span class="banner-font-size" :data-product-id="product.item._id">
              <span
                :id="`banner-text${i}`"
                class="banner-text"
                :key="restartAnimationKey + Math.random()"
                :style="{ color: theme.banner.text.color }"
                >{{ product.item.banner.text }}</span
              >
            </span>
          </span>
          <div class="swiper-slide-content position-relative preview-item">
            <div
              v-if="product.item.show === 'video'"
              class="swiper-slide-content video-container"
            >
              <video
                width="100%"
                height="100%"
                :style="`object-fit: ${product.item.video.videoShowType} !important;`"
                :poster="product.item.video.thumbnail"
                autoplay
                muted
                loop
              >
                <source :src="product.item.video.url" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>

            <div
              v-if="product.item.show === 'youtube'"
              class="swiper-slide-content video-container"
            >
              <iframe
                :src="extractedYoutubeEmbedUrl(product.item.links[0].url)"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
              <div class="video-overlay"></div>
            </div>

            <div
              v-else-if="product.item.show === 'image'"
              class="wrapper-image"
              :style="{
                height:
                  !product.item.title &&
                  !product.item.description &&
                  !product.item.price &&
                  !product.item.oldPrice
                    ? '100%'
                    : '',
              }"
            >
              <img
                :src="product.item.images[0].url"
                :style="`objectFit: ${product.item.imageShowType} !important`"
                class="swiper-slide-image"
              />
            </div>

            <div
              class="wrapper-content"
              v-if="
                product.item.title ||
                product.item.description ||
                product.item.price ||
                product.item.oldPrice
              "
            >
              <div
                :style="{ backgroundColor: theme.content.container.color }"
                class="card-info theme-content h-100"
              >
                <div
                  class="swiper-slide-title theme-title"
                  :data-product-id="product.item._id"
                  v-if="product.item.title"
                  :style="{ color: theme.content.title.color }"
                  :id="`theme-title${i}`"
                  :key="restartAnimationKey + Math.random()"
                  v-html="wrapString(product.item.title, 30)"
                ></div>
                <div
                  class="swiper-slide-text theme-description"
                  :data-product-id="product.item._id"
                  v-html="wrapString(product.item.description, 50)"
                  v-if="product.item.description"
                  :style="{ color: theme.content.description.color }"
                  :id="`theme-description${i}`"
                  :key="restartAnimationKey + Math.random()"
                ></div>
              </div>
              <div
                class="prices black--text"
                v-if="product.item.price || product.item.oldPrice"
                :style="{
                  backgroundColor: theme.prices.container.color,
                }"
              >
                <span
                  v-if="product.item.price"
                  :style="{
                    color: theme.prices.price.color,
                  }"
                  :id="`price${i}`"
                  :key="restartAnimationKey + Math.random()"
                  :data-product-id="product.item._id"
                  class="price mx-0 theme-price"
                >
                  {{ product.item.price }}
                  <span class="currency">
                    {{ currency }}
                  </span>
                </span>
                <span
                  v-if="product.item.oldPrice"
                  :style="{ color: theme.prices.oldPrice.color }"
                  :id="`old-price${i}`"
                  :key="restartAnimationKey + Math.random()"
                  class="ma-0 discount theme-old-price"
                  :data-product-id="product.item._id"
                >
                  {{ product.item.oldPrice }}
                  <span class="currency">
                    {{ currency }}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="swiper-pagination"></div>
  </div>
</template>
<script>
export default {
  name: "ThreeSlides",
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    currency: {
      type: String,
      default: "",
      required: false,
    },
    logo: {
      type: String,
      default: "",
      required: false,
    },
    currentGroup: {
      type: Object,
      default: Object,
      required: false,
    },
    themeConfiguration: {
      type: Object,
      default: () => ({}),
    },
    theme: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    isLiveChangesPreview: {
      type: Boolean,
      default: false,
    },
    animationEffects: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      restartAnimationKey: 0,
      isVertical: false,
    };
  },
  computed: {
    groupBgColor() {
      return this.currentGroup.groupId?.bg_color;
    },
    productsTimes() {
      return this.products.map((product) => Number(product.item.counter ?? 1));
    },
  },
  mounted() {},
  methods: {},
  beforeDestroy() {},
};
</script>

<style lang="scss" scoped>
.slider-container {
  user-select: none;
  box-sizing: border-box;
  overflow: visible;
  width: 95%;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 72%;
}
.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  color: #fff;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;

  // background-color: rgba(51, 51, 51, 1);

  border-radius: 14px;
}

.banner {
  top: 16px;
}

.swiper-slide-content {
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  color: #000;
  border-radius: 14px;
  overflow: hidden;
  box-shadow: 3px 4px 10px 2px #00000040;
}

.wrapper-content {
  position: absolute;
  display: flex;
  background-color: rgba(255, 255, 255, 0.94);
  width: 100%;
  height: calc(100% - 75.8%);
  align-self: flex-end;
  text-align: left;
  justify-content: space-between;
  box-shadow: none;
}

.swiper-logo {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
}

.card-info {
  width: calc(100% - 23.5%);
  flex-grow: 1;
  padding: 10px;
  line-height: 1.4;
}
.prices {
  width: 23.5% !important;
  background-color: #fdd039;
  color: #000;
  justify-content: center;
  align-items: center;
  // border-radius: 15px;
  padding: 10px;
}
.wrapper-image {
  position: absolute;
  left: 0;
  top: 0;
  height: 75.8%;
  width: 100%;
  z-index: 0;
}
.swiper-slide-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.prices {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.custom-banner {
  background-color: #fdd039;
  color: #000;
  border-radius: 14px;
  padding: 4px 12px;
  word-break: break-word;
  font-weight: 500;
  height: 10%;
  display: flex;
  z-index: 2;
  span {
    line-height: 1.2 !important;
  }
}

.theme.vertical {
  .slider-container {
    height: 100%;

    .row {
      padding-block: 16px !important;
      padding-top: 16px !important;
      padding-bottom: 16px !important;
    }

    .swiper-slide {
      height: calc(100% / 3);
      margin-block: 0 !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;

      .swiper-slide-content {
        .wrapper-image {
          position: relative;
          width: 60%;
          height: 100%;
          flex-grow: 1;
        }

        .wrapper-content {
          position: relative;
          width: 40%;
          height: 100%;
          flex-direction: column;

          .card-info {
            width: 100% !important;
            height: 60% !important;
            padding: 5px;
            // padding-top: 30px;
          }

          .prices {
            width: 100% !important;
            height: 40% !important;
            flex-direction: row !important;
            flex-wrap: wrap;
          }
        }
      }
    }
  }
}

@media (max-width: $breakpoint-md) {
  .theme {
    .slider-container {
      height: 100%;

      .row {
        padding-block: 16px !important;
        padding-top: 16px !important;
        padding-bottom: 16px !important;
      }

      .swiper-slide {
        height: calc(100% / 3);
        margin-block: 0 !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;

        .swiper-slide-content {
          .wrapper-image {
            position: relative;
            width: 60%;
            height: 100%;
            flex-grow: 1;
          }

          .wrapper-content {
            position: relative;
            width: 40%;
            height: 100%;
            flex-direction: column;

            .card-info {
              width: 100% !important;
              height: 60% !important;
              padding: 5px;
              padding-top: 30px;
            }

            .prices {
              width: 100% !important;
              height: 40% !important;
              flex-direction: row !important;
              flex-wrap: wrap;
            }
          }
        }
      }
    }
  }
}
</style>
