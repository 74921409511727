<template>
  <div
    class="theme h-100 w-100"
    v-if="activeProduct.item && Object.keys(theme).length"
  >
    <div v-if="false" class="swiper-logo">
      <img
        :src="logo"
        width="50px"
        height="50px"
        class="object-cover rounded-circle"
        alt="logo"
      />
    </div>
    <div ref="swiper" init="false" class="swiper-container">
      <div class="h-100 swiper-slide">
        <div class="swiper-slide-content preview-item">
          <div
            v-if="activeProduct.item.show === 'video'"
            class="swiper-slide-content video-container"
          >
            <video
              width="100%"
              height="100%"
              :style="`object-fit: ${activeProduct.item.video.videoShowType} !important;`"
              :poster="activeProduct.item.video.thumbnail"
              autoplay
              muted
              loop
            >
              <source :src="activeProduct.item.video.url" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>

          <div
            v-if="activeProduct.item.show === 'youtube'"
            class="swiper-slide-content video-container"
          >
            <iframe
              :src="extractedYoutubeEmbedUrl(activeProduct.item.links[0].url)"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
            <div class="video-overlay"></div>
          </div>

          <div
            v-if="activeProduct.item.show === 'image'"
            class="wrapper-image"
            :style="{
              objectFit: `${activeProduct.item.imageShowType} !important`,
              width:
                !activeProduct.item.title &&
                !activeProduct.item.description &&
                !activeProduct.item.price &&
                !activeProduct.item.oldPrice
                  ? '100%'
                  : '',
            }"
          >
            <img
              :src="activeProduct.item.images[0].url"
              :style="`objectFit: ${activeProduct.item.imageShowType} !important`"
              style="width: 100%; height: 100%"
            />
          </div>
          <div
            class="wrapper-content"
            :style="{ backgroundColor: theme.container.color }"
            v-if="
              activeProduct.item.title ||
              activeProduct.item.description ||
              activeProduct.item.price ||
              activeProduct.item.oldPrice ||
              (activeProduct.item.banner && activeProduct.item.banner.text)
            "
          >
            <div class="slide-content">
              <p
                class="custom-banner"
                v-if="
                  activeProduct.item.banner && activeProduct.item.banner.text
                "
                :style="{ backgroundColor: theme.banner.container.color }"
                id="banner-container"
              >
                <span
                  class="banner-font-size"
                  :data-product-id="activeProduct.item._id"
                >
                  <span
                    id="banner-text"
                    class="banner-text"
                    :style="{ color: theme.banner.text.color }"
                    >{{ activeProduct.item.banner.text }}</span
                  >
                </span>
              </p>
              <div
                :style="{ backgroundColor: theme.content.container.color }"
                class="card-info theme-content"
              >
                <div
                  class="swiper-slide-title theme-title"
                  :data-product-id="activeProduct.item._id"
                  v-if="activeProduct.item.title"
                  :style="{ color: theme.content.title.color }"
                  id="theme-title"
                >
                  {{ activeProduct.item.title }}
                </div>
                <div
                  class="swiper-slide-text theme-description"
                  :data-product-id="activeProduct.item._id"
                  v-if="activeProduct.item.description"
                  :style="{ color: theme.content.description.color }"
                  id="theme-description"
                  v-html="wrapString(activeProduct.item.description, 50)"
                ></div>
              </div>
              <div
                class="prices black--text d-flex align-center"
                v-if="activeProduct.item.price || activeProduct.item.oldPrice"
                :style="{
                  backgroundColor: theme.prices.container.color,
                }"
              >
                <span
                  :data-product-id="activeProduct.item._id"
                  class="price theme-price"
                  v-if="activeProduct.item.price"
                  :style="{
                    color: theme.prices.price.color,
                  }"
                  id="price"
                >
                  {{ activeProduct.item.price }}
                  <span class="currency">
                    {{ currency }}
                  </span>
                </span>
                <span
                  class="discount ma-0 theme-old-price"
                  v-if="activeProduct.item.oldPrice"
                  :data-product-id="activeProduct.item._id"
                  :style="{ color: theme.prices.oldPrice.color }"
                  id="old-price"
                >
                  {{ activeProduct.item.oldPrice }}
                  <span class="currency">
                    {{ currency }}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Half_Screen_Slide",
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    currency: {
      type: String,
      default: "",
      required: false,
    },
    logo: {
      type: String,
      default: "",
      required: false,
    },
    currentGroup: {
      type: Object,
      default: Object,
      required: false,
    },
    themeConfiguration: {
      type: Object,
      default: () => ({}),
    },
    theme: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    isLiveChangesPreview: {
      type: Boolean,
      default: false,
    },
    animationEffects: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    groupBgColor() {
      return this.currentGroup.groupId?.bg_color;
    },
    productsTimes() {
      return this.products.map((product) => Number(product.item.counter ?? 1));
    },
    activeProduct() {
      return this.products[0] || {};
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper-image {
  width: 70%;
  height: 100%;
  flex-grow: 1;
}

.swiper-container {
  user-select: none;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-top: 0px;
  padding-bottom: 0px;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  color: #fff;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  border-radius: 0px;
}

.swiper-slide-content {
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1;
  text-align: start;
  color: #000;
}
.wrapper-content {
  width: 30% !important;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 16px;
  flex-direction: column;
  box-shadow: none !important;
  background: #f2f2f2;
}
.slide-content {
  color: #000 !important;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 64%;
  justify-content: center;
}

.price {
  margin-right: 10px;
  margin-top: auto;
}
.card-info {
  height: 42%;
  margin-bottom: 3%;
  flex-grow: 1;
}

.custom-banner {
  padding: 10px 12px;
  background: #fdd039;
  color: #000;
  text-align: center;
  width: 100%;
  height: 16%;
  font-weight: 500;
  line-height: 1.3;
  word-break: break-word;
  margin-bottom: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    line-height: 1.2 !important;
  }
}
.prices {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  height: 42%;
}
.currency {
  font-size: 1.5rem !important;
  font-weight: bold;
}
.swiper-logo {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 100;
}
</style>
