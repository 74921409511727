const animationEffects = [
  {
    name: "Bounce",
    class: "animate__bounce",
  },
  {
    name: "Flash",
    class: "animate__flash",
  },
  {
    name: "Pulse",
    class: "animate__pulse",
  },
  {
    name: "Rubber Band",
    class: "animate__rubberBand",
  },
  {
    name: "ShakeX",
    class: "animate__shakeX",
  },
  {
    name: "ShakeY",
    class: "animate__shakeY",
  },
  {
    name: "Head Shake",
    class: "animate__headShake",
  },
  {
    name: "Swing",
    class: "animate__swing",
  },
  {
    name: "Tada",
    class: "animate__tada",
  },
  {
    name: "Wobble",
    class: "animate__wobble",
  },
  {
    name: "Jello",
    class: "animate__jello",
  },
  {
    name: "HeartBeat",
    class: "animate__heartBeat",
  },
  {
    name: "Back In Down",
    class: "animate__backInDown",
  },
  {
    name: "Back In Left",
    class: "animate__backInLeft",
  },
  {
    name: "Back In Right",
    class: "animate__backInRight",
  },
  {
    name: "Back In Up",
    class: "animate__backInUp",
  },
  {
    name: "Bounce In",
    class: "animate__bounceIn",
  },
  {
    name: "Bounce In Down",
    class: "animate__bounceInDown",
  },
  {
    name: "Bounce In Left",
    class: "animate__bounceInLeft",
  },
  {
    name: "Bounce In Right",
    class: "animate__bounceInRight",
  },
  {
    name: "Bounce In Up",
    class: "animate__bounceInUp",
  },
  {
    name: "Fade In",
    class: "animate__fadeIn",
  },
  {
    name: "Fade In Down",
    class: "animate__fadeInDown",
  },
  {
    name: "Fade In Down Big",
    class: "animate__fadeInDownBig",
  },
  {
    name: "Fade In Left",
    class: "animate__fadeInLeft",
  },
  {
    name: "Fade In Left Big",
    class: "animate__fadeInLeftBig",
  },
  {
    name: "Fade In Right",
    class: "animate__fadeInRight",
  },
  {
    name: "Fade In Right Big",
    class: "animate__fadeInRightBig",
  },
  {
    name: "Fade In Up",
    class: "animate__fadeInUp",
  },
  {
    name: "Fade In Up Big",
    class: "animate__fadeInUpBig",
  },
  {
    name: "Fade In Top Left",
    class: "animate__fadeInTopLeft",
  },
  {
    name: "Fade In Top Right",
    class: "animate__fadeInTopRight",
  },
  {
    name: "Fade In Bottom Left",
    class: "animate__fadeInBottomLeft",
  },
  {
    name: "Fade In Bottom Right",
    class: "animate__fadeInBottomRight",
  },
  {
    name: "Flip",
    class: "animate__flip",
  },
  {
    name: "Flip In X",
    class: "animate__flipInX",
  },
  {
    name: "Flip In Y",
    class: "animate__flipInY",
  },
  {
    name: "Light Speed In Right",
    class: "animate__lightSpeedInRight",
  },
  {
    name: "Light Speed In Left",
    class: "animate__lightSpeedInLeft",
  },
  {
    name: "Rotate In",
    class: "animate__rotateIn",
  },
  {
    name: "Rotate In Down Left",
    class: "animate__rotateInDownLeft",
  },
  {
    name: "Rotate In Down Right",
    class: "animate__rotateInDownRight",
  },
  {
    name: "Rotate In Up Left",
    class: "animate__rotateInUpLeft",
  },
  {
    name: "Rotate In Up Right",
    class: "animate__rotateInUpRight",
  },
  {
    name: "Hinge",
    class: "animate__hinge",
  },
  {
    name: "Jack In The Box",
    class: "animate__jackInTheBox",
  },
  {
    name: "Roll In",
    class: "animate__rollIn",
  },
  {
    name: "Zoom In",
    class: "animate__zoomIn",
  },
  {
    name: "Zoom In Down",
    class: "animate__zoomInDown",
  },
  {
    name: "Zoom In Left",
    class: "animate__zoomInLeft",
  },
  {
    name: "Zoom In Right",
    class: "animate__zoomInRight",
  },
  {
    name: "Zoom In Up",
    class: "animate__zoomInUp",
  },
  {
    name: "Slide In Down",
    class: "animate__slideInDown",
  },
  {
    name: "Slide In Left",
    class: "animate__slideInLeft",
  },
  {
    name: "Slide In Right",
    class: "animate__slideInRight",
  },
  {
    name: "Slide In Up",
    class: "animate__slideInUp",
  },
];

export default animationEffects;
