var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-100",style:({ backgroundColor: _vm.theme.container.color || '#FFFFFF' })},[(false)?_c('div',{staticClass:"swiper-logo"},[_c('img',{staticClass:"object-cover rounded-circle",attrs:{"src":_vm.logo,"width":"50px","height":"50px","alt":"logo"}})]):_vm._e(),_c('swiper-container',{ref:"swiper",staticClass:"swiper-container",attrs:{"init":"false"}},_vm._l((_vm.products),function(product){return _c('swiper-slide',{key:product._id,staticClass:"h-100"},[(product.item.banner && product.item.banner.text)?_c('p',{key:_vm.restartAnimationKey + Math.random(),staticClass:"banner",class:[
                _vm.getAnimationClasses(
                  null,
                  _vm.theme.banner.container.delay,
                  'banner-container'
                ),
              ],style:({ backgroundColor: _vm.theme.banner.container.color }),attrs:{"id":"banner-container"}},[_c('span',{key:_vm.restartAnimationKey + Math.random(),class:[
                    _vm.getAnimationClasses(
                      _vm.theme.banner.text.animation,
                      _vm.theme.banner.text.delay,
                      'banner-text'
                    ),
                  ],style:({ color: _vm.theme.banner.text.color }),attrs:{"id":"banner-text"}},[_vm._v(_vm._s(product.item.banner.text))])]):_vm._e(),_c('div',{staticClass:"swiper-slide-content preview-item"},[_c('img',{staticClass:"swiper-slide-image",style:({
              width:
                !product.item.title &&
                !product.item.description &&
                !product.item.price &&
                !product.item.oldPrice
                  ? '100%'
                  : '',
            }),attrs:{"src":product.item.images[0].url}}),(
              product.item.title ||
              product.item.description ||
              product.item.price ||
              product.item.oldPrice
            )?_c('div',{staticClass:"wrapper-content"},[_c('div',{staticClass:"card-info theme-content",style:({ backgroundColor: _vm.theme.content.container.color })},[(product.item.title)?_c('div',{directives:[{name:"textFit",rawName:"v-textFit",value:({
                  minFont: 15,
                  maxFont: 512,
                  maxHeightByPercent: 70,
                  checkHasSibling: true,
                }),expression:"{\n                  minFont: 15,\n                  maxFont: 512,\n                  maxHeightByPercent: 70,\n                  checkHasSibling: true,\n                }"}],key:_vm.restartAnimationKey + Math.random(),staticClass:"swiper-slide-title",class:[
                    _vm.getAnimationClasses(
                      _vm.theme.content.title.animation,
                      _vm.theme.content.title.delay,
                      'theme-title'
                    ),
                  ],style:({ color: _vm.theme.content.title.color }),attrs:{"id":"theme-title"}},[_vm._v(" "+_vm._s(product.item.title)+" ")]):_vm._e(),(product.item.description)?_c('div',{directives:[{name:"textFit",rawName:"v-textFit",value:({
                  minFont: 15,
                  maxFont: 512,
                  maxHeightByPercent: 30,
                }),expression:"{\n                  minFont: 15,\n                  maxFont: 512,\n                  maxHeightByPercent: 30,\n                }"}],key:_vm.restartAnimationKey + Math.random(),staticClass:"swiper-slide-text",class:[
                    _vm.getAnimationClasses(
                      _vm.theme.content.description.animation,
                      _vm.theme.content.description.delay,
                      'theme-description'
                    ),
                  ],style:({ color: _vm.theme.content.description.color }),attrs:{"id":"theme-description"},domProps:{"innerHTML":_vm._s(_vm.wrapString(product.item.description, 50))}}):_vm._e()]),(product.item.price || product.item.oldPrice)?_c('div',{staticClass:"prices black--text d-flex flex-column w-100",style:({
                backgroundColor: _vm.theme.prices.container.color,
              })},[(product.item.price)?_c('span',{directives:[{name:"textFit",rawName:"v-textFit",value:({
                  minFont: 15,
                  maxFont: 512,
                  maxHeightByPercent: 70,
                  quarterChild: true,
                }),expression:"{\n                  minFont: 15,\n                  maxFont: 512,\n                  maxHeightByPercent: 70,\n                  quarterChild: true,\n                }"}],key:_vm.restartAnimationKey + Math.random(),staticClass:"price",class:[
                  _vm.getAnimationClasses(
                    _vm.theme.prices.price.animation,
                    _vm.theme.prices.price.delay,
                    'price'
                  ),
                ],style:({
                  color: _vm.theme.prices.price.color,
                }),attrs:{"id":"price"}},[_vm._v(" "+_vm._s(product.item.price)+" "),_c('span',{staticClass:"currency"},[_vm._v(" "+_vm._s(_vm.currency)+" ")])]):_vm._e(),(product.item.oldPrice)?_c('span',{directives:[{name:"textFit",rawName:"v-textFit",value:({
                  minFont: 15,
                  maxFont: 512,
                  maxHeightByPercent: 30,
                  quarterChild: true,
                }),expression:"{\n                  minFont: 15,\n                  maxFont: 512,\n                  maxHeightByPercent: 30,\n                  quarterChild: true,\n                }"}],key:_vm.restartAnimationKey + Math.random(),staticClass:"discount",class:[
                  _vm.getAnimationClasses(
                    _vm.theme.prices.oldPrice.animation,
                    _vm.theme.prices.oldPrice.delay,
                    'old-price'
                  ),
                ],style:({ color: _vm.theme.prices.oldPrice.color }),attrs:{"id":"old-price"}},[_vm._v(" "+_vm._s(product.item.oldPrice)+" "),_c('span',{staticClass:"currency"},[_vm._v(" "+_vm._s(_vm.currency)+" ")])]):_vm._e()]):_vm._e()]):_vm._e()])])}),1),_c('div',{staticClass:"swiper-pagination"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }