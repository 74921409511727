<template>
  <div
    class="h-100 d-flex align-center justify-center"
    :style="{ backgroundColor: theme.container.color || '#FFFFFF' }"
  >
    <div v-if="false" class="swiper-logo">
      <img
        :src="logo"
        width="50px"
        height="50px"
        class="object-cover rounded-circle"
        alt="logo"
      />
    </div>
    <swiper-container init="false" ref="swiper" class="swiper-container">
      <swiper-slide
        v-for="product in products"
        :key="product._id"
        class="h-100"
      >
        <p
          class="banner"
          v-if="product.item.banner && product.item.banner.text"
              :style="{ backgroundColor: theme.banner.container.color }"
              id="banner-container"
              :key="restartAnimationKey + Math.random()"
              :class="[
                getAnimationClasses(
                  null,
                  theme.banner.container.delay,
                  'banner-container'
                ),
              ]"        
>
          <span :style="{ color: theme.banner.text.color }"
                    id="banner-text"
                  :key="restartAnimationKey + Math.random()" :class="[
                    getAnimationClasses(
                      theme.banner.text.animation,
                      theme.banner.text.delay,
                      'banner-text'
                    ),
                  ]">{{
            product.item.banner.text
          }}</span>
        </p>
        <div class="swiper-slide-content preview-item">
          <img
            :src="product.item.images[0].url"
            class="swiper-slide-image"
            :style="{
              height:
                !product.item.title &&
                !product.item.description &&
                !product.item.price &&
                !product.item.oldPrice
                  ? '100%'
                  : '',
            }"
          />
          <div
            class="wrapper-content"
            v-if="
              product.item.title ||
              product.item.description ||
              product.item.price ||
              product.item.oldPrice
            "
          >
            <div :style="{ backgroundColor: theme.content.container.color }" class="card-info theme-content">
              <div
                class="swiper-slide-title"
                ref="titles"
                v-if="product.item.title"
                :style="{ color: theme.content.title.color }"
                  id="theme-title"
                  :key="restartAnimationKey + Math.random()"
                  :class="[
                    getAnimationClasses(
                      theme.content.title.animation,
                      theme.content.title.delay,
                      'theme-title'
                    ),
                  ]"
              >
                {{ product.item.title }}
              </div>
              <div
                class="swiper-slide-text"
                ref="descriptions"
                v-if="product.item.description"
                :style="{ color: theme.content.description.color }"
                  id="theme-description"
                  :key="restartAnimationKey + Math.random()"

                  :class="[
                    getAnimationClasses(
                      theme.content.description.animation,
                      theme.content.description.delay,
                      'theme-description'
                    ),
                  ]"

                v-html="wrapString(product.item.description, 50)"
              ></div>
            </div>
            <div
              class="prices black--text d-flex flex-column align-center h-100"
            >
              <span class="price" v-if="product.item.price"
                :style="{
                  color: theme.prices.price.color,
                }"
                id="price"
                :key="restartAnimationKey + Math.random()"
                :class="[
                  getAnimationClasses(
                    theme.prices.price.animation,
                    theme.prices.price.delay,
                    'price'
                  ),
                ]"
 ref="prices">
                {{ product.item.price }}
                <span class="currency" ref="currency">
                  {{ currency }}
                </span>
              </span>
              <span
                class="discount"
                v-if="product.item.oldPrice"
                :style="{ color: theme.prices.oldPrice.color }"
                id="old-price"
                :key="restartAnimationKey + Math.random()"
                :class="[
                  getAnimationClasses(
                    theme.prices.oldPrice.animation,
                    theme.prices.oldPrice.delay,
                    'old-price'
                  ),
                ]"

                ref="discounds"
              >
                {{ product.item.oldPrice }}
                <span class="currency" ref="discount_currency">
                  {{ currency }}
                </span>
              </span>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper-container>
    <div class="swiper-pagination"></div>
  </div>
</template>
<script>
export default {
  name: "cardSlides",
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    currency: {
      type: String,
      default: "",
      required: false,
    },
    logo: {
      type: String,
      default: "",
      required: false,
    },
    currentGroup: {
      type: Object,
      default: Object,
      required: false,
    },
    themeConfiguration: {
      type: Object,
      default: () => ({}),
    },
    theme: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    isLiveChangesPreview: {
      type: Boolean,
      default: false,
    },
    animationEffects: {
      type: Array,
      required: true,
    }  },
  computed: {
    groupBgColor() {
      return this.currentGroup.groupId?.bg_color;
    },
    productsTimes() {
      return this.products.map((product) => Number(product.item.counter ?? 1));
    },
  },
  mounted() {
    this.$emit("initializeSwiper", this.$refs.swiper, this.swiperParams);
  },
  methods: {
    calculateFont() {
      this.loading = true;
      this.endCalculateFont = false;
      this.$store.commit("showLogoLayer", true);

      setTimeout(() => {
        if (this.$refs.titles.length > 1) {
          this.reSizeFont({
            content: this.$refs.titles,
            checkIfHasSub: true,
            minFont: 10,
            maxFont: 512,
            maxHeightByPercent: 65,
          });
        }
        if (this.$refs.descriptions.length > 1) {
          this.reSizeFont({
            content: this.$refs.descriptions,
            minFont: 10,
            maxFont: 512,
            maxHeightByPercent: 30,
            checkIfHasSub: true,
          });
        }
        if (this.$refs.prices.length > 1) {
          this.reSizeFont({
            content: this.$refs.prices,
            checkIfHasSub: true,
            minFont: 10,
            maxFont: 512,
            maxHeightByPercent: 70,
          });
        }
        if (this.$refs.discounds.length > 1) {
          this.reSizeFont({
            content: this.$refs.discounds,
            checkIfHasSub: true,
            minFont: 10,
            maxFont: 60,
            maxHeightByPercent: 30,
          });
        }
        if (this.$refs.currency.length > 1) {
          this.reSizeFont({
            content: this.$refs.currency,
            checkIfHasSub: false,
            minFont: 10,
            maxFont: 512,
            maxHeightByPercent: 30,
          });
        }
        if (this.$refs.discount_currency.length > 1) {
          this.reSizeFont({
            content: this.$refs.discount_currency,
            checkIfHasSub: false,
            minFont: 10,
            maxFont: 512,
            maxHeightByPercent: 20,
          });
        }
        if (this.$refs.banner.length > 1) {
          this.reSizeFont({
            content: this.$refs.banner,
            minFont: 5,
            maxFont: 512,
            maxHeightByPercent: 100,
          });
        }
        this.loading = false;
        this.endCalculateFont = true;
        this.$store.commit("showLogoLayer", false);
      }, 100);
    },
    getAnimationClasses(animationName, animationDelay, id) {
      if (!animationName) {
        return "";
      }

      const animationDelayClass = animationDelay
        ? `animate__delay-${animationDelay}s`
        : "";

      const animationClass = this.animationEffects.find(
        (effect) => effect.name === animationName
      )?.class;

      this.$nextTick(() => {
        if (id) {
          let el = document.querySelector(`#${id}`);

          setTimeout(() => {
            el.classList.remove("opacity-0");
          }, Number(animationDelay) * 1000 - 100);
        }
      });

      return `animate__animated ${animationClass} ${animationDelayClass} opacity-0`;
    },
    initSwiper() {
      const swiperEl = this.$refs.swiper;
      // const swiperParams = {
      //   autoplay: {
      //     enabled: true,
      //     disableOnInteraction: false,
      //     delay: this.totalTime,
      //   },
      //   pagination: { dynamicBullets: true, el: ".swiper-pagination" },
      //   watchSlidesProgress: true,
      //   threshold: 6,
      //   touchRatio: 0.5,
      //   effect: "cards",
      //   spaceBetween: 42,
      //   initialSlide: 0,
      //   speed: this.totalTime / this.products.length,
      //   virtual: false,
      //   on: {
      //     afterInit: this.calculateFont,
      //   },
      // };

      const swiperParams = this.themeConfiguration.swiperParams({
        speed: this.totalTime / this.products.length,
        delay: this.calcTime(this.activeIndex, this.productsTimes, 1),
        handleNextGroup: this.handleNextGroup,
      });

      // now we need to assign all parameters to Swiper element
      Object.assign(swiperEl, swiperParams);

      // and now initialize it
      this.$nextTick(() => {
        swiperEl.initialize();

        // stop slider autoplay when user switch to another tab or window
        document.addEventListener("visibilitychange", () => {
          if (document.visibilityState === "hidden") {
            this.$refs.swiper && this.$refs.swiper.swiper.autoplay.stop();
          } else {
            this.$refs.swiper && this.$refs.swiper.swiper.autoplay.start();
          }
        });
      });
    },
    handleNextGroup() {
      this.activeIndex = 1;
      setInterval(() => {
        if (this.activeIndex == this.products.length + 1) {
          this.activeIndex = 0;
          this.$emit("goNext");
        } else {
          this.activeIndex += 1;
        }
      }, this.totalTime);
    },
  },
  data() {
    return {
      restartAnimationKey: 0,
      activeIndex: 0,
      totalTime: 4000,
      endCalculateFont: false,
      slidesPerView: 1,
    };
  },
};
</script>
<style scoped lang="scss">
swiper-container {
  user-select: none;
  box-sizing: border-box;
  overflow: visible;
  width: 40vw;
  height: 90vh;
  padding-top: 0px;
  padding-bottom: 0px;
}

swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #fff;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  background-color: rgba(51, 51, 51, 1);
  border-radius: 8px;
}
.swiper-slide-content {
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  color: #000;
}

.wrapper-content {
  position: absolute;
  display: flex;
  background-color: #fff;
  width: 100%;
  padding: 10px 15px;
  text-align: left;
  justify-content: space-between;
  height: calc(30% + 20px);
  bottom: 0;
  align-items: center;
}

.prices {
  width: 30%;
}
.swiper-logo {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 100;
}
.swiper-slide-image {
  object-fit: cover;
  width: 100%;
  position: absolute;
  top: 0;
  height: calc(70% - 20px);
}
.card-info {
  width: 65%;
  height: 100%;
}
.price {
  .currency {
    width: 20%;
    display: inline-block;
  }
}
.discount {
  .currency {
    width: 20%;
  }
}
</style>
