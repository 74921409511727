var render = function render(){var _vm=this,_c=_vm._self._c;return (Object.keys(_vm.theme).length && _vm.activeProduct.item)?_c('div',{staticClass:"theme h-100"},[(_vm.logo)?_c('div',{staticClass:"swiper-logo bg-danger"},[_c('img',{staticClass:"object-cover rounded-circle",attrs:{"src":_vm.logo,"width":"50px","height":"50px","alt":"logo"}})]):_vm._e(),_c('div',{staticClass:"swiper-container"},[_c('div',{staticClass:"h-100 swiper-slide"},[_c('div',{staticClass:"swiper-slide-content preview-item"},[(_vm.activeProduct.item.show === 'video')?_c('div',{staticClass:"swiper-slide-content video-container"},[_c('video',{style:(`object-fit: ${_vm.activeProduct.item.video.videoShowType} !important;`),attrs:{"width":"100%","height":"100%","poster":_vm.activeProduct.item.video.thumbnail,"autoplay":"","muted":"","loop":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":_vm.activeProduct.item.video.url,"type":"video/mp4"}}),_vm._v(" Your browser does not support the video tag. ")])]):_vm._e(),(_vm.activeProduct.item.show === 'youtube')?_c('div',{staticClass:"swiper-slide-content video-container"},[_c('iframe',{attrs:{"src":_vm.extractedYoutubeEmbedUrl(_vm.activeProduct.item.links[0].url),"title":"YouTube video player","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share","allowfullscreen":""}}),_c('div',{staticClass:"video-overlay"})]):_vm._e(),(_vm.activeProduct.item.show === 'image')?_c('img',{staticStyle:{"width":"100%","height":"100%"},style:(`object-fit: ${_vm.activeProduct.item.imageShowType} !important;`),attrs:{"src":_vm.activeProduct.item.images[0].url}}):_vm._e(),(_vm.activeProduct.item.price || _vm.activeProduct.item.oldPrice)?_c('div',{staticClass:"prices black--text d-flex flex-column justify-space-around cricle-30 pa-6",style:({
            backgroundColor: _vm.theme.prices.container.color,
          })},[_c('div',{staticClass:"wrapper-price"},[(_vm.activeProduct.item.price)?_c('span',{staticClass:"price ma-0 theme-price",style:({
                color: _vm.theme.prices.price.color,
              }),attrs:{"id":"price","data-product-id":_vm.activeProduct.item._id}},[_vm._v(" "+_vm._s(_vm.activeProduct.item.price)+" "),_c('span',{staticClass:"currency"},[_vm._v(" "+_vm._s(_vm.currency)+" ")])]):_vm._e(),(_vm.activeProduct.item.oldPrice)?_c('span',{staticClass:"discount ma-0 theme-old-price",style:({ color: _vm.theme.prices.oldPrice.color }),attrs:{"data-product-id":_vm.activeProduct.item._id,"id":"old-price"}},[_vm._v(" "+_vm._s(_vm.activeProduct.item.oldPrice)+" "),_c('span',{staticClass:"currency"},[_vm._v(" "+_vm._s(_vm.currency)+" ")])]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"wrapper-content",class:{ 'w-0': !_vm.hasSomeContent(_vm.activeProduct, true) }},[(_vm.hasSomeContent(_vm.activeProduct, true))?_c('div',{staticClass:"wrapper-content-body pa-3",class:{
              'bg-transparent': !_vm.hasSomeContent(_vm.activeProduct, false),
            },style:({ backgroundColor: _vm.theme.container.color })},[_c('div',{staticClass:"card-info theme-content"},[(
                  _vm.activeProduct.item.banner && _vm.activeProduct.item.banner.text
                )?_c('span',{staticClass:"custom-banner",style:({ backgroundColor: _vm.theme.banner.container.color }),attrs:{"id":"banner-container"}},[_c('span',{staticClass:"banner-font-size",attrs:{"data-product-id":_vm.activeProduct.item._id}},[_c('span',{staticClass:"banner-text",style:({ color: _vm.theme.banner.text.color }),attrs:{"id":"banner-text"}},[_vm._v(_vm._s(_vm.activeProduct.item.banner.text))])])]):_vm._e(),_c('div',{staticClass:"content-wrapper"},[(_vm.activeProduct.item.title)?_c('div',{staticClass:"swiper-slide-title theme-title",style:({
                    color: _vm.theme.content.title.color,
                  }),attrs:{"data-product-id":_vm.activeProduct.item._id}},[_vm._v(" "+_vm._s(_vm.activeProduct.item.title)+" ")]):_vm._e(),(_vm.activeProduct.item.description)?_c('div',{staticClass:"swiper-slide-text my-2 theme-description",style:({
                    color: _vm.theme.content.description.color,
                  }),attrs:{"data-product-id":_vm.activeProduct.item._id}},[_vm._v(" "+_vm._s(_vm.activeProduct.item.description)+" ")]):_vm._e()])])]):_vm._e()])])])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }